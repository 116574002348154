<template id="frontiers">
	<div class="container-fluid">
		<search-input placeholder="find amount" v-model="searchQuery" />

		<v-table :data="filteredList" :filters="filters" :currentPage.sync="currentPage" :pageSize="50" @totalPagesChanged="totalPages = $event" class="table table-striped border fleet-flight-table bg-light" selectedClass="table-info" selectionMode="multiple" @selectionChanged="selectedRows = $event">
			<thead slot="head" class="text-center bg-blue-5 text-light">
				<v-th v-for="field in details_fields" :key="field.key" :class="'clickable bg-blue-6' + field.col" :sortKey="field.key">
					{{ field.label }}
				</v-th>
			</thead>
			<tbody slot="body" slot-scope="{ displayData }">
				<tr v-for="row in displayData" :key="row.id" :row="row" class="text-center" :class="{ 'bg-gray-2': row.id === 'unique-count-row' }">
					<td v-for="field in details_fields" :key="field.key" :class="field.col">
						<template v-if="field.key == 'price_date'">{{ formatField(row, field) }}</template>
						<template v-else>{{ formatField(row, field) }}</template>
					</td>
				</tr>
			</tbody>
		</v-table>

		<smart-pagination :currentPage.sync="currentPage" :totalPages="totalPages" :maxPageLinks="16" />
	</div>
</template>

<script>
	import Vue2Filters from 'vue2-filters';

	export default {
		mixins: [Vue2Filters.mixin],
		props: ['results'],
		data() {
			return {
				section: 'cargo',
				currentSort: 'price_date',
				currentSortDir: 'desc',
				currentPage: 1,
				totalPages: 0,
				filteredList: this.results,
				url: '//' + window.location.hostname + '/',
				searchQuery: '',
				filters: {
					name: {
						value: '',
						keys: ['price_date', 'eu_us', 'cn_us', 'cn_eu'],
					},
				},
				details_fields: [
					{ label: 'Date', placeholder: 'Date', key: 'price_date', col: 'col', type: 'date' },
					{ label: 'CN EU Lane', placeholder: 'CN EU', key: 'cn_eu', col: 'col', type: 'number' },
					{ label: 'CN US Lane', placeholder: 'CN US', key: 'cn_us', col: 'col', type: 'number' },
					{ label: 'EU US Lane', placeholder: 'EU US', key: 'eu_us', col: 'col', type: 'number' },
				],
			};
		},
		computed: {
			formattedResults() {
				return this.results.map(row => ({
					...row,
					formatted_month: format(new Date(row.price_date * 1000), 'MMM'), // Converts timestamp to "MMM" format
				}));
			},
			cleanedFilter: {
				get() {
					return this.searchQuery;
				},
				set(value) {
					this.filters.name.value = value.replace(/[\$,]/g, '').trim();
					this.searchQuery = value;
				},
			},
		},
		methods: {
			applyFilter() {
				if (!this.cleanedFilterValue) {
					this.filteredList = this.results;
					return;
				}

				this.filteredList = this.results.filter(row => {
					return this.filters.name.keys.some(key => {
						if (row[key] != null) {
							let rowValue = row[key].toString();
							return rowValue.includes(this.cleanedFilterValue);
						}
						return false;
					});
				});
			},
		},
		created() {
			this.filteredList = this.results;
		},
		watch: {
			searchQuery(newValue) {
				this.cleanedFilter = newValue;
			},
		},
	};
</script>
