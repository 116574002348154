<template id="countries-componenet">
	<div class="general-container">
		<search-input placeholder="Find Airspace" v-model="filters.name.value" />

		<subcount>
			<slot></slot>
		</subcount>

		<v-table :data="countrieslist" :filters="filters" class="table bg-light border">
			<thead slot="head" class="text-center">
				<tr>
					<v-th scope="col" sortKey="domicile" class="clickable text-center">Domicile</v-th>
					<v-th scope="col" sortKey="kind" class="clickable">Kind</v-th>
					<v-th scope="col" sortKey="codes" class="clickable">Codes</v-th>
					<v-th scope="col" sortKey="aircraft_registration" class="clickable">Aircraft</v-th>
				</tr>
			</thead>
			<tbody slot="body" slot-scope="{ displayData }">
				<tr v-for="country in displayData" :key="country.id" class="text-center">
					<td v-if="country.sovereign" class="text-center">
						<img v-if="country.sovereign" v-bind:src="url + 'flags/blank.gif'" :class="'flag flag-' + getCountryFlagCode(country.flag)" :alt="country.sovereign" :title="country.sovereign" />
						&nbsp;
						<span v-if="country.domicile">
							<a :href="url + 'airspaces/' + country.id + ''">{{ country.domicile }}</a>
						</span>
					</td>
					<td v-else></td>
					<td class="text-center">{{ country.kind ?? '' }}</td>
					<td class="text-center">{{ country.codes ?? '' }}</td>
					<td class="text-center">{{ country.aircraft_registration ?? '' }}</td>
				</tr>
			</tbody>
		</v-table>
	</div>
</template>

<script>
	import Vue2Filters from 'vue2-filters';

	export default {
		mixins: [Vue2Filters.mixin],
		props: ['countrieslist'],
		data: function () {
			return {
				currentSort: 'country',
				currentSortDir: 'asc',
				searchQuery: '',
				filteredList: this.countrieslist,
				url: '//' + window.location.hostname + '/',
				filters: {
					name: {
						value: '',
						keys: ['domicile', 'sovereign', 'airspace', 'authority', 'codes', 'aircraft_registration', 'icao_locator'],
					},
				},
			};
		},
		created() {
			this.mylist = this.countrieslist;
		},
		methods: {
			morethan() {
				return;
			},

			matches(obj) {
				const term = this.searchQuery.toLowerCase();
				return filteredList.toLowerCase().includes(term);
			},
		},
		computed: {
			filteredcountry() {
				if (this.searchQuery == '') {
					// this.$emit('filterChange', this.filteredList)
					return this.filteredList;
				}
				return this.filteredList.filter(function (builder) {
					return country.includes(function (item) {
						return item.country.includes(this.searchQuery);
					});
					// val => String(val).toLowerCase().includes( this.searchQuery ) );  ///filters the word object
				});
			},
		},
	};
</script>
