<template id="engines">
	<div class="engineslist">
		<div class="card">
			<div class="card-header">
				<input type="text" placeholder="Search" v-model="searchQuery" class="form-control" />
			</div>
		</div>

		<div class="container-fluid" style="max-height: 500px; overflow-y: auto">
			<div class="row">
				<table class="table table-striped mt-5">
					<tbody>
						<tr v-for="glossary in filterBy(filteredList, searchQuery)" :key="glossary.index">
							<td v-if="glossary.term">{{ glossary.term }}</td>
							<td v-else></td>
							<td v-if="glossary.body">{{ glossary.body }}</td>
							<td v-else></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue2Filters from 'vue2-filters';

	export default {
		mixins: [Vue2Filters.mixin],
		props: ['glossary'],
		data: function () {
			return {
				currentSort: 'term',
				currentSortDir: 'asc',
				searchQuery: '',
				filteredList: [],
				url: '//' + window.location.hostname + '/',
			};
		},
		created() {},
		methods: {
			morethan() {
				return;
			},

			matches(obj) {
				const term = this.searchQuery.toLowerCase();
				return filteredList.toLowerCase().includes(term);
			},
		},
		computed: {
			filteredGlossary() {
				if (this.searchQuery == '') {
					// this.$emit('filterChange', this.filteredList)
					return this.filteredList;
				}
				return this.filteredList.filter(function (builder) {
					return glossary.includes(function (item) {
						return item.term.includes(this.searchQuery);
					});
					// val => String(val).toLowerCase().includes( this.searchQuery ) );  ///filters the word object
				});
			},
		},
		mounted() {
			if (!this.glossary) {
				self = this;

				var $url = '/glossary/get/none';

				axios
					.get($url)
					.then(function (response) {
						// self.glossary = response.data.glossary;
						self.filteredList = response.data.glossary;
						self.showModal = false;
					})
					.catch(function (error) {});
			} else {
				this.filteredList = this.glossary;
			}
		},
	};
</script>
