<template id="updates-manager">
	<div class="container-fluid p-0 m-0">
		<!-- <search-input placeholder="Find Developments" v-model="filters.name.value" /> -->

		<!-- <LoadingBar ref="loadingBarRef" /> -->

		<div class="container d-block d-md-none text-center text-gray-5 t-22"><i class="fa-solid fa-left-right"></i></div>

		<div style="overflow-x: auto">
		<v-table :data="developments" :filters="filters" :currentPage.sync="currentPage" :pageSize="50" @totalPagesChanged="totalPages = $event" class="table table-striped border">
			<thead slot="head" class="table-head">
				<tr>
					<v-th sortKey="date" class="clickable text-center">Date</v-th>
					<v-th sortKey="airlines.airline" class="clickable text-center">Airline</v-th>
					<!-- <v-th sortKey="country_id" class="clickable text-center">Domicile</v-th> -->
					<!-- <v-th sortKey="event" class="clickable text-center">Event</v-th> -->
					<v-th sortKey="action" class="clickable text-center">Action</v-th>
					<v-th sortKey="subject" class="clickable text-center">Subject</v-th>
					<v-th sortKey="occur" class="clickable date-field text-center">Change</v-th>
				</tr>
			</thead>
			<tbody slot="body" slot-scope="{ displayData }">
				<tr v-for="development in displayData" :key="development.id" class="text-center">
					<td>{{ customDisplayDateFormat(development.date) ?? '' }}</td>

					<td>{{ development.airlines.airline ?? '' }}</td>
					<!-- <td  v-if=" development.country_id "><img v-bind:src="url+'flags/blank.gif'" :class="'flag flag-'+getCountryFlagCode( toTitleCase(development.country.domicile) )" :alt="development.country.domicile"  :title="development.country.domicile" /> {{ toTitleCase(development.country.domicile) }} </td><td v-else></td> -->
					<!-- <td>{{ development.event ?? '' }}</td> -->
					<td>{{ development.action ?? '' }}</td>
					<td>{{ development.subject ?? '' }}</td>
					<td>{{ development.occur ?? '' }}</td>
				</tr>
			</tbody>
		</v-table>
		</div>

		<smart-pagination :currentPage.sync="currentPage" :totalPages="totalPages" :maxPageLinks="14" />
	</div>
</template>

<script>
	export default {
		components: {},
		props: ['developments'],
		data: function () {
			return {
				currentPage: 1,
				totalPages: 0,

				filters: {
					name: {
						value: '',
						keys: ['date', 'airline.airline', 'event', 'action', 'subject', 'occur', 'sources'],
					},
				},
				sortField: 'date',
				sortOrder: 'desc',

				url: '//' + window.location.hostname + '/',

				searchQuery: '',
			};
		},
		created() {},
		watch: {},
		methods: {},
		computed: {},
		mounted() {},
	};
</script>
