<template id="utilities-pop-up">
	<div class="container-fluid">
		<nav class="navbar fixed-bottom navbar-expand-sm navbar-dark text-white footer-bar text-left m-0 p-10 nav-utilities-popup">
			<button class="navbar-toggler text-center" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" style="padding: 10px; margin: auto">
				<small>Reference</small>
			</button>

			<div class="collapse navbar-collapse" id="navbarCollapse">
				<div class="container row-0 text-center">
					<ul class="navbar-nav justify-content-center mx-auto nav-fill w-60 text-center">
						<li class="nav-item menu-item pt-1">
							<!-- <a class="luso-nav-link" href="#" @click.prevent="openModal('glossary')" title="Open Glossary">
								<span class="text-gold-1" :title="getNavEntryDescription('Glossary')">Glossary</span>
							</a> -->

							<pop-up>
								<template v-slot:linktext>
									<span class="text-light">Glossary</span>
								</template>

								<template v-slot:header>Glossary</template>

								<template v-slot:body><glossary /></template>
							</pop-up>
						</li>
						<!-- 
						<li class="nav-item menu-item pt-1">
							<measures-link><span slot="text">Measures</span></measures-link>
						</li> -->

						<li class="nav-item menu-item pt-1">
							<notes>
								<span slot="header">Notes</span>
								<span slot="text">Notes</span>
							</notes>
						</li>
						<li class="nav-item menu-item pt-1">&centerdot;</li>

						<li class="nav-item menu-item pt-1">
							<div class="dropup">
								<a class="luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
									<span class="text-light" :title="getNavEntryDescription('Pallets')">Pallets</span>
								</a>
								<div class="dropdown-menu collapse text-center p-0 w-220px p-5">
									<slot name="pallets"></slot>
								</div>
							</div>
						</li>

						<li class="nav-item menu-item pt-1">
							<div class="dropup">
								<a class="luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
									<span class="text-light" :title="getNavEntryDescription('Seats')">Seats</span>
								</a>
								<div class="dropdown-menu collapse text-center p-0 w-220px p-5">
									<slot name="seats"></slot>
								</div>
							</div>
						</li>

						<li class="nav-item menu-item pt-1">
							<div class="dropup">
								<a class="luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
									<span class="text-light" :title="getNavEntryDescription('Times')">Times</span>
								</a>
								<div class="dropdown-menu collapse text-center p-0 w-220px p-5">
									<slot name="times"></slot>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
	export default {
		props: ['naventries'],
		data() {
			return {
				url: '//' + window.location.hostname + '/',
				phonetics: 'images/phonetic-alphabet.png',
				morse: 'images/morse.png',
				marshals: 'images/marshal.png',
				freedoms: 'images/ICAO-Freedoms.png',
				ICAOLocatorMap: 'images/ICAO-Locator-Map.jpg',

				isSectionActive: {
					glossary: false,
					publications: false,
					organizations: false,
					events: false,
					freedoms: false,
				},

				todayDate: '',
				modalOpen: false,
				showModal: false,
				option: false,
			};
		},
		created() {
			console.log();
		},

		watch: {},

		methods: {
			getNavEntryDescription(menuEntry) {
				var tooltip = this.naventries.find(item => item.label === menuEntry);
				return tooltip.description;
			},
			isActive(path) {
				// console.log(path.startsWith('/publications'));
				if (path.startsWith('/publications')) {
					this.isSectionActive['publications'] = true;
				}
				if (path.startsWith('/organizations')) {
					this.isSectionActive['organizations'] = true;
				}
				if (path.startsWith('/events')) {
					this.isSectionActive['events'] = true;
				}
				if (path.startsWith('/glossary')) {
					this.isSectionActive['glossary'] = true;
				}
			},
			openFreedoms: function () {
				// if( this.isSectionActive['freedoms'] == true ) {
				//     this.isSectionActive['freedoms'] = false;
				//     $(this).modal('close');
				// } else {
				//     this.isSectionActive['freedoms'] = true;
				// }
			},
			openModal: function (option) {
				const self = this;
				self.option = option;

				if (self.modalOpen == true) {
					self.modalOpen = false;
				} else {
					self.showModal = true;
					self.isActive('/' + option);
				}
			},
			close: function () {
				this.showModal = false;

				Object.keys(this.isSectionActive).forEach(key => {
					this.isSectionActive[key] = false;
				});
			},

			getTodayDate: function () {
				var today = new Date();
				var dd = String(today.getDate()).padStart(2, '0');
				var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
				var yyyy = today.getFullYear();

				let shortMonth = today.toLocaleString('en-us', {
					month: 'short',
				}); /* Jun */

				this.todayDate = yyyy + ' ' + shortMonth + ' ' + dd;
			},
		},

		mounted() {
			this.getTodayDate();

			var path = window.location.pathname;
			this.isActive(path);
		},
	};
</script>

<!--   

            OLD LINKS AND TOOLS TO RESEARCH
						<li class="nav-item menu-item pt-1">
							<a href="/bulletin" class="text-light">Bulletin</a>
						</li> -->
<!-- <li class="nav-item menu-item pt-1">
              <div class="dropup">
                <a
                  class="luso-nav-link"
                  data-toggle="dropdown"
                  aria-haspopup="false"
                  aria-expanded="false"
                  href="#"
                >
                  <span
                    class="text-light"
                    :title="getNavEntryDescription('Availabilities')"
                    >Availabilities</span
                  >
                </a>
                <div class="dropdown-menu collapse">
                  <a
                    class="dropdown-item"
                    href="http://www.aeroconnect.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    >AirConnect</a
                  >
                  <a
                    class="dropdown-item"
                    href="http://www.jetclassified.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    >Jet Classified</a
                  >
                  <a
                    class="dropdown-item"
                    href="http://www.myairtrade.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    >MyAirLease</a
                  >
                </div>
              </div>
            </li> -->

<!-- <li class="nav-item menu-item pt-1">
							<div class="dropup">
								<a class="luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
									<span class="text-light" :title="getNavEntryDescription('Cargo')">Cargo</span>
								</a>
								<div class="dropdown-menu collapse w-220px p-5">
									<slot name="cargo"></slot> -->

<!-- <a
                    class="dropdown-item"
                    href="https://cargofacts.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                    >CargoFacts</a
                  >
                  <a
                    class="dropdown-item"
                    href="https://www.freightos.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                    >Freightos</a
                  >
                  <a
                    class="dropdown-item"
                    href="https://www.xeneta.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                    >Xeneta</a
                  > -->
<!-- </div>
							</div>
						</li> -->

<!-- <li class="nav-item menu-item pt-1">
							<div class="dropup">
								<a class="luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
									<span class="text-light" :title="getNavEntryDescription('Currencies')">Currency</span>
								</a>
								<div class="dropdown-menu collapse text-center w-220px p-5">
									<slot name="currency"></slot> -->

<!-- <strong>Exchanges</strong> -->
<!-- <a
          class="dropdown-item"
          href="https://www.oanda.com/currency-converter"
          target="_blank"
          rel="noopener noreferrer"
          >Oanda</a
        >
        <a
          class="dropdown-item"
          href="https://tradingeconomics.com/country-list/interest-rate"
          target="_blank"
          rel="noopener noreferrer"
          >Trading Economics</a
        >
        <a
          class="dropdown-item"
          href="https://www.xe.com/currencyconverter"
          target="_blank"
          rel="noopener noreferrer"
          >Xe</a
        > -->
<!-- 
                  <strong>Rates</strong>
                  <a
                    class="dropdown-item"
                    href="https://www.cbrates.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Central Bank Rates</a
                  >
                  <a
                    class="dropdown-item"
                    href="https://www.global-rates.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Global-Rates</a
                  >
                  <a
                    class="dropdown-item"
                    href="https://tradingeconomics.com/country-list/interest-rate"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Trading Economics</a
                  > -->
<!-- </div>
							</div>
						</li> -->
<!-- 
						<li class="nav-item menu-item pt-1">
							<a href="/directories" class="text-light">Directories</a>
						</li> -->

<!-- <li class="nav-item menu-item pt-1">
              <div class="dropup">
                <a
                  class="luso-nav-link"
                  data-toggle="dropdown"
                  aria-haspopup="false"
                  aria-expanded="false"
                  href="#"
                >
                  <span
                    class="text-light"
                    :title="getNavEntryDescription('Flights')"
                  >
                    Flights
                  </span>
                </a>
                <div class="dropdown-menu collapse">
                  <a
                    class="dropdown-item"
                    href="https://flightaware.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    >FlightAware</a
                  >
                  <a
                    class="dropdown-item"
                    href="https://flightradar24.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    >FlightRadar24</a
                  >
                  <a
                    class="dropdown-item"
                    href="https://flightstats.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    >FlightStats</a
                  >
                </div>
              </div>
            </li> -->

<!-- <li class="nav-item menu-item pt-1">
              <a
                class="luso-nav-link"
                :class="{ sectionActive: this.isSectionActive['freedoms'] }"
                :href="url + freedoms"
                data-toggle="lightbox"
                title="Open Freedoms"
                @click.prevent="openFreedoms()"
              >
                <span
                  class="text-light"
                  :title="getNavEntryDescription('Freedoms')"
                >
                  Freedoms
                </span>
              </a>
            </li> -->
<!-- 
						<li class="nav-item menu-item pt-1">
							<a href="/forecasts" class="text-light">Forecasts</a>
						</li> -->

<!-- <li class="nav-item menu-item pt-1">
							<div class="dropup">
								<a class="luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
									<span class="text-light" :title="getNavEntryDescription('Fuel')">Fuel</span>
								</a>
								<div class="dropdown-menu collapse w-220px p-5">
									<slot name="fuel"></slot> -->
<!-- <a
                    class="dropdown-item"
                    href="https://www.iata.org/en/publications/economics/fuel-monitor/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >IATA Jet Fuel Monitor</a
                  >
                  <a
                  class="dropdown-item"
                  href="https://www.spglobal.com/commodityinsights/en/oil/refined-products/jetfuel"
                  target="_blank"
                  rel="noopener noreferrer"
                  >S&P Global Jet Fuel</a
                  >
                  
                  <a
                    class="dropdown-item"
                    href="https://jet-a1-fuel.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Jet-A1-Fuel</a
                  > -->

<!-- <a class="dropdown-item" href="http://www.indexmundi.com/commodities/?commodity=jet-fuel&months=60&currency=sgd" target="_blank" rel="noopener noreferrer">Mundi Jet Fuel Monthly</a> -->
<!-- <a class="dropdown-item" href="https://www.eia.gov/dnav/pet/hist/eer_epjk_pf4_rgc_dpgD.htm" target="_blank" rel="noopener noreferrer">US EIA Petroleum</a> -->
<!-- <a class="dropdown-item" href="https://www.opisnet.com/product/pricing/spot/asia-jet-fuel-gasoil-report/" target="_blank" rel="noopener noreferrer">OPIS Worldwide Jet Fuel</a> -->
<!-- </div>
							</div>
						</li> -->

<!-- <li class="nav-item menu-item pt-1">
              <div class="dropup">
                <a
                  class="luso-nav-link"
                  data-toggle="dropdown"
                  aria-haspopup="false"
                  aria-expanded="false"
                  href="#"
                >
                  <span
                    class="text-light"
                    :title="getNavEntryDescription('Maps')"
                  >
                    Maps
                  </span>
                </a>
                <div class="dropdown-menu collapse">
                  <a
                    class="dropdown-item"
                    href="http://www.gcmap.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Great Circle Mapper</a
                  >
                  <a
                    class="dropdown-item"
                    :href="url + ICAOLocatorMap"
                    data-toggle="lightbox"
                    >ICAO Locator Map</a
                  >
                  <a
                    class="dropdown-item"
                    href="https://skyvector.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    >SkyVector</a
                  >
                </div>
              </div>
            </li> -->

<!-- <li class="nav-item menu-item pt-1">
              <div class="dropup">
                <a
                  class="luso-nav-link"
                  data-toggle="dropdown"
                  aria-haspopup="false"
                  aria-expanded="false"
                  href="#"
                >
                  <span
                    class="text-light"
                    :title="getNavEntryDescription('Ocean')"
                  >
                    Ocean
                  </span>
                </a>
                <div class="dropdown-menu collapse text-center">
                  <slot name="ocean"></slot>

                  <a
                    class="dropdown-item"
                    href="http://www.drewry.co.uk"
                    rel="noopener noreferrer"
                    target="_blank"
                    >Dreary</a
                  >
                  <a
                    class="dropdown-item"
                    href="http://fbx.freightos.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    >Freightos</a
                  >
                  <a
                    class="dropdown-item"
                    href="https://www.globalmaritimehub.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    >Global Maratime Hub</a
                  >
                </div>
              </div>
            </li> -->

<!-- <a
                    class="dropdown-item"
                    href="http://incodocs.com/blog/unit-load-device-uld-air-container-specifications"
                    rel="noopener noreferrer"
                    target="_blank"
                    >IncoDocs</a
                  >
                  <a
                    class="dropdown-item"
                    href="http://uldcare.com/uld-tools-and-solutions/uld-types"
                    rel="noopener noreferrer"
                    target="_blank"
                    >ULDcare</a
                  >
                  <a
                    class="dropdown-item"
                    href="http://wikipedia.org/wiki/Unit_load_device"
                    rel="noopener noreferrer"
                    target="_blank"
                    >Wiki ULD</a
                  > -->

<!-- <li class="nav-item menu-item pt-1">
              <div class="dropup">
                <a
                  class="luso-nav-link"
                  data-toggle="dropdown"
                  aria-haspopup="false"
                  aria-expanded="false"
                  href="#"
                >
                  <span
                    class="text-light"
                    :title="getNavEntryDescription('Photos')"
                  >
                    Photos
                  </span>
                </a>
                <div class="dropdown-menu collapse">
                  <a
                    class="dropdown-item"
                    href="http://www.airliners.net"
                    rel="noopener noreferrer"
                    target="_blank"
                    >Airliners</a
                  >
                  <a
                    class="dropdown-item"
                    href="http://www.jetphotos.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    >JetPhotos</a
                  >
                  <a
                    class="dropdown-item"
                    href="http://www.justplanes.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                    >Just Planes</a
                  > -->
<!-- <a class="dropdown-item" href="http://www.planespotters.com/photos" rel="noopener noreferrer" target="_blank">Planespotters</a> -->
<!-- </div>
              </div>
            </li> -->

<!-- <li class="nav-item menu-item pt-1">
                    <div class="  dropup">
                        <a class=" luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
                            <span class=" text-light"  :title="getNavEntryDescription('Routes')"  >
                            Routes
                        </span>
                        </a>
                        <div class="dropdown-menu collapse">
                            <a class="dropdown-item" href="http://www.anna.aero" rel="noopener noreferrer" target="_blank">Anna.aero</a>
                            <a class="dropdown-item" href="http://www.flightsfrom.com" rel="noopener noreferrer" target="_blank">FlightsFrom</a>
                            <a class="dropdown-item" href="http://www.routesonline.com" rel="noopener noreferrer" target="_blank">Routes Online</a>
                        </div>
                    </div> 
                </li>   -->

<!-- <li class="nav-item menu-item pt-1">
              <div class="dropup">
                <a
                  class="luso-nav-link"
                  data-toggle="dropdown"
                  aria-haspopup="false"
                  aria-expanded="false"
                  href="#"
                >
                  <span
                    class="text-light"
                    :title="getNavEntryDescription('Safety')"
                  >
                    Safety
                  </span>
                </a>
                <div class="dropdown-menu collapse">
                  <a
                    class="dropdown-item"
                    href="http://aviation-safety.net"
                    rel="noopener noreferrer"
                    target="_blank"
                    >Air Safety Network</a
                  >
                  <a
                    class="dropdown-item"
                    href="http://avherald.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    >Aviation Herald</a
                  >
                  <a
                    class="dropdown-item"
                    href="http://www.baaa-acro.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    >Aircraft Accidents Archive</a
                  >
                </div>
              </div>
            </li> -->

<!-- <li class="nav-item menu-item pt-1">
							<a href="/research    " class="text-light">Research</a>
						</li> -->

<!-- <a
                    class="dropdown-item"
                    href="https://www.aerolopa.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                    >aeroLOPA</a
                  >
                  <a
                    class="dropdown-item"
                    href="http://www.seatguru.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    >Seat Guru</a
                  >
                  <a
                    class="dropdown-item"
                    href="http://seatmaps.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    >SeatMaps</a
                  > -->

<!-- <li class="nav-item menu-item pt-1">
                    <div class="  dropup">
                        <a class=" luso-nav-link " data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
                            <span class=" text-light"  :title="getNavEntryDescription('Signals')"  >
                            Signals
                            </span>
                        </a>
                        <div class="dropdown-menu collapse">
                            <a class="dropdown-item" :href="url+marshals" data-toggle="lightbox" >Marshals</a>
                            <a class="dropdown-item" :href="url+morse" data-toggle="lightbox" >Morse</a>
                            <a class="dropdown-item" :href="url+phonetics" data-toggle="lightbox" >Phonetics</a>
                        </div>
                    </div> 
                </li>   -->

<!-- <a
                    class="dropdown-item"
                    href="https://www.timeanddate.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    >TimeandDate</a
                  >
                  <a
                    class="dropdown-item"
                    href="https://www.24timezones.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    >24timezones</a
                  >
                  <a
                    class="dropdown-item"
                    href="https://gisgeography.com/world-time-zone-map/"
                    rel="noopener noreferrer"
                    target="_blank"
                    >World Time Zone Map</a
                  > -->

<!-- <li class="nav-item menu-item pt-1">
              <div class="dropup">
                <a
                  class="luso-nav-link"
                  data-toggle="dropdown"
                  aria-haspopup="false"
                  aria-expanded="false"
                  href="#"
                >
                  <span
                    class="text-light"
                    :title="getNavEntryDescription('Weather')"
                  >
                    Weather
                  </span>
                </a>
                <div class="dropdown-menu collapse">
                  <a
                    class="dropdown-item"
                    href="https://www.aviationweather.gov"
                    rel="noopener noreferrer"
                    target="_blank"
                    >AWC</a
                  >
                  <a
                    class="dropdown-item"
                    href="https://www.fltplan.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    >FltPlan</a
                  >
                  <a
                    class="dropdown-item"
                    href="https://skybrary.aero/operational-issues/weather"
                    rel="noopener noreferrer"
                    target="_blank"
                    >Skybrary</a
                  >
                </div>
              </div>
            </li> -->
