<template id="builders">
  <div class="container-fluid">
    <search-input placeholder="Find Product" v-model="search" />

    <subcount>
      <slot></slot>
    </subcount>

    <v-table :data="aircraft" :filters="filters" class="table bg-light border">
      <thead slot="head" class="text-center">
        <v-th sortKey="builder.builder" class="clickable">Builder</v-th>
        <v-th sortKey="type" class="clickable">Aircraft</v-th>
        <v-th sortKey="payload_cabin_weight" class="clickable">Payload</v-th>
        <v-th sortKey="flight_range_full" class="clickable">Range</v-th>
        <v-th sortKey="flight_speed_cruise_max" class="clickable">Speed</v-th>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr v-for="row in displayData" :key="row.id" class="text-center">
          <td>{{ row.builder.builder }}</td>
          <td>{{ row.model }}</td>
          <td>{{ row.development_model_design }}</td>
          <td>{{ row.flight_range_full }}</td>
          <td>{{ row.flight_speed_cruise_max }}</td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters';

import SearchInput from './SearchInput.vue';
Vue.component('search-input', SearchInput);

export default {
  mixins: [Vue2Filters.mixin, './numberformatmixin'],

  components: {
    SearchInput,
  },

  props: ['aircraft', 'builder_type'],
  data: function () {
    return {
      search: '',
      section: 'aircraft',
      currentSort: 'builder.builder',
      currentSortDir: 'asc',
      searchQuery: '',

      url: '//' + window.location.hostname + '/',
      filters: {
        name: {
          value: '',
          keys: [
            'builder.builder',
            'type',
            'payload_cabin_weight',
            'flight_range_full',
            'flight_speed_cruise_max',
          ],
        },
      },
    };
  },
  created() {},
  methods: {},
};
</script>
