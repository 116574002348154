<template>
	<div>
		<div class="loading-bar" v-show="isLoading" :style="{ width: loadingWidth + '%' }"></div>
	</div>
</template>

<script>
	export default {
		props: {},

		data() {
			return {
				isLoading: false,
				loadingWidth: 0,
			};
		},

		methods: {
			startLoading() {
				this.isLoading = true;
				this.loadingWidth = 10; // Initial width

				const interval = setInterval(() => {
					if (this.loadingWidth < 90) {
						this.loadingWidth += 5;
					}
				}, 100);

				this.loadingInterval = interval;
			},
			stopLoading() {
				clearInterval(this.loadingInterval);
				this.loadingWidth = 100;
				setTimeout(() => {
					this.isLoading = false;
					this.loadingWidth = 0;
				}, 300);
			},
		},
	};
</script>

<style scoped>
	.clickable {
		cursor: pointer;
	}

	.loading-bar {
		height: 4px;
		background-color: #3498db;
		transition: width 0.5s ease;
	}
</style>
