<template>
	<div class="changes-filter1 col-12 text-center mb-20">
				<a href="#" class=" p-5 filter-item t-20" :class="{ 'active-filter': activeFilter === 'Intent' }" @click.prevent="toggleFilter('Intent')">
					Intent ({{ intentCount }})
				</a>
				<a href="#" class="p-5 filter-item t-20"  :class="{ 'active-filter': activeFilter === 'Action' }" @click.prevent="toggleFilter('Action')">
					Action ({{ actionCount }})
				</a>
	</div>
</template>

<script>
	export default {
		props: {
			intentCount: {
				type: Number,
				required: true,
			},
			actionCount: {
				type: Number,
				required: true,
			},
		},
		data() {
			return {
				activeFilter: null,
			};
		},
		methods: {
			toggleFilter(filterType) {
				if (this.activeFilter === filterType) {
					this.activeFilter = null;
					this.$emit('filter-events', null); // Emit null to clear filter
				} else {
					this.activeFilter = filterType;
					this.$emit('filter-events', filterType); // Emit the selected filter
				}
			},
		},
	};
</script>

<style scoped>
	.changes-filter {
		display: flex;
		gap: 10px;
	}
	a {
		text-decoration: none;
		color: blue;
		cursor: pointer;
	}
	a.active {
		font-weight: bold;
		color: darkblue;
	}
 
	.filter-item {
		cursor: pointer;
		margin: 0.25rem;
		padding: 0.5rem;
		border-radius: 5px;
	}
	.filter-item:hover,
	.active-filter {
		background-color: #007bff;
		color: white;
	}
</style>

</style>
