<template>
	<div class="p-0 m-0">
		<div class="row p-0">
			<div class="col bg-light text-center text-blue-5 pl-25 pr-25">
				<!-- 
        <FlightsFiltersSimple
          @apply-filters="fetchData"
          :filters_fields="filters_fields"
          :results="flights"
          filtersheading="Enter one item"
        ></FlightsFiltersSimple> -->

				<FlightsFilters @apply-filters="applyFilters" :filters_fields="filters_fields" :results="results" :totalCount="totalCount" filtersheading="Enter one item" :date-limitations="dateLimitations" :page="groupBy" :showGroupBy="false" :filledfields="filled"></FlightsFilters>

				<!-- <div v-if="results.length >= 1" class="p-0 m-0"> -->
				<!-- <fleets-flights-flights :flights-list="flights" /> -->

				<AirTable :fields="tableFields" :data="results" :totalPages="totalPages" :totalCount="totalCount" :currentPage="currentPage" :sortField="sortField" :sortOrder="sortOrder" :enableCounts="true" :countRow="countData" @rowSelected="viewRecordDetails" @page-changed="fetchData(filters, $event)" @sort-changed="handleSortChange" ref="airTableRef" />
			</div>
		</div>
	</div>
</template>

<script>
	// import FlightsFiltersSimple from './FlightsFiltersSimple';
	import moment from 'moment';
	import FlightsFilters from './FlightsFilters';

	import AirTable from './AirTable';

	import FleetsFlightsFlights from './FleetsFlightsFlights';
	import FleetsFlightsFleets from './FleetsFlightsFleets';

	export default {
		components: {
			AirTable,
			FlightsFilters,
			// FlightsFiltersSimple,
			FleetsFlightsFlights,
			FleetsFlightsFleets,
		},
		props: {
			plan: String,
			accesslimit: String,
			filled: {
				type: Object,
				default: () => ({}), // Default to an empty object
			},
		},
		data: function () {
			return {
				results: [],
				totalCount: 0,
				currentPage: 1,
				totalPages: 0,
				sortField: 'ac_msn',
				sortOrder: 'asc',
				countData: {},
				pageWindowSize: 10,
				abortController: null,
				loadingCounts: false,

				selectedFlight: [],
				flights_count: 0,

				groupBy: 'flights',
				selectedRows: [],

				flights: [],

				filters_fields: [
					{
						label: 'Aircraft',
						placeholder: 'code-4',
						key: 'ac_type_icao',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Serial',
						placeholder: 'msn',
						key: 'ac_msn',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Registration',
						placeholder: 'registration',
						key: 'tail_number',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Airline',
						placeholder: 'name',
						key: 'airline_name',
						secondkey: 'airline_name',
						thirdkey: 'airline_iata',
						col: 'col',
						type: 'string',
					},

					{
						label: 'ICAO',
						placeholder: 'airline',
						key: 'airline_icao',
						col: 'col',
						type: 'string',
						// hidden: 'false',
					},

					{
						label: 'Flight',
						placeholder: 'number',
						key: 'flight_number',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Route',
						placeholder: 'IATA-IATA',
						key: 'route',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Airport',
						placeholder: 'IATA',
						key: 'departure_airport_iata',
						secondkey: '',
						thirdkey: '',
						fourkey: '',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Airspace',
						placeholder: 'ISO-2',
						key: 'departure_airport_country',
						col: 'col',
						type: 'string',
					},
				],
			};
		},
		created() {
			this.groupBy = this.page;

			const now = moment();
			let minDate = moment();
			let maxDate = moment();

			minDate = this.accesslimit;

			this.dateLimitations = {
				minDate: minDate,
				maxDate: maxDate.format('YYYY-MM-DD'),
			};
		},
		computed: {
			tableFields() {
				return [
					{ key: 'ac_type_icao', label: 'Aircraft' },
					{ key: 'ac_msn', label: 'Serial' },
					{ key: 'tail_number', label: 'Registration' },
					{ key: 'airline_name', label: 'Airline' },
					{ key: 'airline_icao', label: 'ICAO' },
					{ key: 'flight_number', label: 'Flight' },
					{ key: 'route', label: 'Route' },
					{ key: 'flights', label: 'Flights' },
					{ key: 'fh_avg', label: 'FH_avg' },
					{ key: 'fh_sum', label: 'FH_sum' },
				];
			},
			paginatedPages() {
				let startPage = Math.max(1, this.currentPage - Math.floor(this.pageWindowSize / 2));
				startPage = Math.min(startPage, Math.max(1, this.totalPages - this.pageWindowSize + 1));
				return Array.from({ length: Math.min(this.pageWindowSize, this.totalPages) }, (v, i) => i + startPage);
			},
		},

		mounted() {},

		methods: {
			displayList() {
				this.fetchData();
			},

			viewFlight(flight) {
				console.log(this.selectedFlight);
			},

			displayField(entry, type) {
				switch (type) {
					case 'date':
						return this.customDateFormat(entry, 'YYYY MMM DD');
						break;

					default:
						return entry;
				}
			},

			applyFilters(newFilters) {
				this.filters = newFilters;
				this.fetchData(this.filters, 1);
			},

			fetchData(filters, page) {
				const self = this;

				this.$refs.airTableRef.startLoading();

				this.groupBy = filters.groupBy;

				// Helper function to format date
				// this function is not necessary. We have this.customDateFormat from datemixing. I am just trying helper function to fix a sql bug
				function formatDate(dateString) {
					const date = new Date(dateString);
					if (isNaN(date.getTime())) {
						return null;
					} else {
						// Get the time zone offset in minutes and convert it to milliseconds
						const timeZoneOffset = date.getTimezoneOffset() * 60000;
						// Adjust the date by subtracting the time zone offset
						const adjustedDate = new Date(date.getTime() - timeZoneOffset);
						// Convert the adjusted date to ISO string and slice to get YYYY-MM-DD format
						return adjustedDate.toISOString().slice(0, 10);
					}
				}

				// Prepare the filter to be sent
				const activeFilter = {};
				for (const key in filters) {
					if (filters[key]) {
						if (key === 'takeoff_start' || key === 'takeoff_finish') {
							activeFilter[key] = formatDate(filters[key]);
						} else {
							activeFilter[key] = filters[key];
						}
					}
				}

				// this is supposed to cancel a request and starts a new one, but I am not getting it to work 100%
				if (this.abortController) {
					this.abortController.abort();
				}
				this.abortController = new AbortController();
				let signal = this.abortController.signal;

				const queryParams = new URLSearchParams({
					...filters,
					page: page,
					sortField: this.sortField,
					sortOrder: this.sortOrder,
				}).toString();

				axios
					.get(`/api/flights/get?${queryParams}`, { signal })
					.then(response => {
						// old way
						// /// // / / /// this.flights = data;
						//  // // /// this.flights_count = data.length;

						// previous way.
						// this.flights_count = response.data.totalCount;

						// this.flights = response.data.results;

						// this.totalPages = response.data.totalPages;
						// this.totalCount = response.data.totalCount;

						// this.currentPage = page;
						// if (page === 1) {
						//   this.fetchCounts(filters);
						// }

						// copied from aircraftfleet
						self.results = response.data.results;
						self.totalPages = response.data.totalPages;
						self.totalCount = response.data.totalCount;
						self.currentPage = page;

						if (page === 1) {
							self.fetchCounts(queryParams);
						}
						self.$refs.airTableRef.stopLoading();
					})
					.catch(error => {
						// console.error('Error fetching flights:', error);
						this.flights = []; // Reset flights array on error
						this.flights_count = 0; // Reset count on error
					});
			},

			fetchCounts(queryParams) {
				const self = this;
				self.loadingCounts = true;
				axios
					.get(`/api/flights/counts?${queryParams}`)
					// .get(`/api/aircraftfleets/counts?icaoCode=${icaoCode}`)
					.then(response => {
						self.countData = response.data;
						self.loadingCounts = false;
					})
					.catch(error => {
						console.error('Error fetching counts:', error);
						self.loadingCounts = false;
					});
			},

			handleSortChange({ sortField, sortOrder }) {
				this.sortField = sortField;
				this.sortOrder = sortOrder;
				this.fetchData(this.filters, this.currentPage);
			},

			viewRecordDetails(result) {
				// Implement viewing logic or navigation
				// this.selectedFlight = result;
				// this.fleetList = false;
				// this.flightsProfile = true;
			},
		},
	};
</script>
