<template id="organizations">
	<div class="container-fluid p-0">
		<GeneralFilter :counts="counts" @filter-field="applyFilter" :nItems="3" :nGreater="5" />

		<search-input placeholder="find organization" v-model="filters.name.value" />

		<LoadingBar ref="loadingBarRef" />

		<template v-if="FilteredOrganization">
			<div style="overflow-x: auto">
				<v-table :data="FilteredOrganization" :filters="filters" :currentPage.sync="currentPage" :pageSize="50" @totalPagesChanged="totalPages = $event" class="table table-striped-white bg-light border">
					<thead slot="head" class="text-center bg-blue-5 text-light">
						<tr class="text-center">
							<v-th scope="col" sortKey="organization" class="clickable w-200px">Organization</v-th>
							<v-th scope="col" sortKey="country" class="clickable w-200px">Profession</v-th>
							<v-th scope="col" sortKey="founded" class="clickable w-120px">Founded</v-th>
							<v-th scope="col" sortKey="country" class="clickable w-200px text-center">Domicile</v-th>
						</tr>
					</thead>
					<tbody slot="body" slot-scope="{ displayData }" class="text-center">
						<tr v-for="row in displayData" :key="row.id" class="text-center">
							<td v-if="row.organization">
								<a :href="url + 'directories/organizations/' + row.id + ''">{{ row.organization }}</a>
							</td>
							<td v-else></td>
							<td>{{ row.profession ?? '' }}</td>
							<td>{{ row.founded ?? '' }}</td>
							<td v-if="row.country_id">
								<img v-bind:src="url + 'flags/blank.gif'" :class="'flag flag-' + getCountryFlagCode(row.country.domicile)" :alt="row.country" :title="row.country" />
								{{ toTitleCase(row.country.domicile) }}
							</td>
							<td v-else></td>
						</tr>
					</tbody>
				</v-table>

				<smart-pagination :currentPage.sync="currentPage" :totalPages="totalPages" :maxPageLinks="12" />
			</div>
		</template>
	</div>
</template>

<script>
	import Vue2Filters from 'vue2-filters';
	import GeneralFilter from './GeneralFilter';
	import LoadingBar from './LoadingBar';

	export default {
		mixins: [Vue2Filters.mixin],

		components: { GeneralFilter, LoadingBar },
		props: ['counts'],
		data: function () {
			return {
				currentPage: 1,
				totalPages: 0,

				results: [],
				errors: [],

				id: '',

				currentSort: 'organization',
				currentSortDir: 'asc',
				url: '//' + window.location.hostname + '/',

				searchQuery: null,
				selectedFilter: null,
				filteredList: this.results,
				filters: {
					name: {
						value: '',
						keys: ['organization', 'profession', 'code', 'founded', 'country'],
					},
				},
			};
		},
		mounted() {
			this.fetchData();
		},

		watch: {},
		methods: {
			async fetchData() {
				const self = this;

				this.$nextTick(() => {
					self.$refs.loadingBarRef.startLoading();
				});

				axios
					.get('/api/organizations/get')
					.then(function (response) {
						self.results = response.data.organizations;
						self.showModal = false;
						self.$refs.loadingBarRef.stopLoading();
					})
					.catch(function (error) {});
			},

			matches(obj) {
				const organization = this.searchQuery.toLowerCase();
				return filteredList.toLowerCase().includes(organization);
			},

			close: function () {
				this.showModal = false;
			},

			applyFilter(profession) {
				this.selectedFilter = profession;
			},
		},
		computed: {
			FilteredOrganization() {
				const self = this;

				if (this.searchQuery === null && this.selectedFilter === null) {
					return this.results;
				}

				return this.results.filter(function (company) {
					return company.profession === self.selectedFilter;
				});
			},
		},
	};
</script>
