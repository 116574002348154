<template>
  <div class="container">
    <search-input placeholder="Find Aircraft" v-model="search" />

    <subcount>
      <slot></slot>
    </subcount>

    <div
      v-for="(builder, index) in filteredBuilders"
      :key="builder.id"
      class="container border mb-15 p-2 bg-light"
    >
      <div class="row text-center">
        <div class="col h5 pt-5">
          <template
            v-if="builder.country_id != '' && builder.country"
            class="text-left pl-35"
          >
            <img
              :src="url + 'flags/blank.gif'"
              :class="
                'flag flag-' + getCountryFlagCode(builder.country.domicile)
              "
              :alt="builder.country.domicile"
              :title="builder.country.domicile"
            />
          </template>

          <span v-html="builder.flag"></span>
          <a :href="'/builders/' + builder.id"
            ><strong>{{ builder.builder }}</strong></a
          >
          <span class="text-capitalize small"
            >({{ builder.aircrafts.length }})</span
          >
        </div>
      </div>

      <div class="row pb-5">
        <div class="col">
          <div class="row justify-content-md-center">
            <div
              v-for="aircraft in builder.aircrafts"
              :key="aircraft.id"
              class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 pt-1 text-center"
            >
              <a :href="url + 'aircraft/' + aircraft.id">{{
                aircraft.model
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchInput from './SearchInput.vue';
Vue.component('search-input', SearchInput);

export default {
  mixins: ['./numberformatmixin'],
  components: {
    SearchInput,
  },
  props: ['builders'],
  data() {
    return {
      search: '',

      aircraftBuildersCount: '',
      aircraftCount: '',
      aircraftFleetTypesCount: '',

      url: '//' + window.location.hostname + '/',
    };
  },
  computed: {
    // filteredBuilders() {
    //   console.log(this.search);
    //   return this.builders.filter(builder =>
    //     builder.builder.toLowerCase().includes(this.search.toLowerCase())
    //   );
    // },
    filteredBuilders() {
      if (!this.search) {
        return this.builders;
      }
      const searchLower = this.search.toLowerCase();
      return this.builders.filter(builder => {
        const builderMatch = builder.builder
          .toLowerCase()
          .includes(searchLower);
        const aircraftMatch = builder.aircrafts.some(aircraft =>
          aircraft.model.toLowerCase().includes(searchLower)
        );
        return builderMatch || aircraftMatch;
      });
    },
  },
  mounted() {},
  methods: {
    // handleSearch(value) {
    //   this.search = value;
    //   // Implement your search logic here
    // },
  },
};
</script>
