<template id="query-popup">
    <div class=" " >
         <h2>Bulletin</h2>
        <form @submit.prevent="bulletinSubscription">
            <div v-if=" form.bulletin_subscribed == 1 " class=" text-center">
                <div class="mb-2 t-16" v-if=" form.bulletin_subscribed_at ">Activated: {{ customDisplayDateFormat(form.bulletin_subscribed_at) }}</div>
                <a href="/account" class=""><small>Activate Bulletin</small></a>
                <button class="btn-sm text-sm bg-success-light rounded-0  mx-auto border-0
                t-20 btn btn-sm rounded-0 btn-primary border-black  text-light btn-sm pl-4 pr-4 pt-1 pb-1 w-200px
                " style=" color: #942192; ">De-Activate</button>
            </div>    
            <div v-else-if=" form.bulletin_unsubscribed_at ">

                <div class="mb-2" v-if=" form.bulletin_unsubscribed_at ">Inactive: {{ customDisplayDateFormat(form.bulletin_unsubscribed_at) }}</div>
                    <button class="btn  bg-success-light text-violet rounded-0 mx-auto
                    t-20 btn btn-sm rounded-0 btn-primary border-black  text-light btn-sm pl-4 pr-4 pt-1 pb-1 w-200px
                    ">Activate</button>
            </div>
            
            <div v-else>
                    <button class="btn btn-block bg-success-light text-violet rounded-0 w-50 mx-auto" >Activate</button>
            </div>
        </form>

    </div>  

  
</template>
 
<script> 
 
export default{
 
  props: [ 'user' , 'subscription'  ],
    data: function () {
        return { 
            errors: []
              
            , form: {
                bulletin_subscribed: '',
                bulletin_subscribed_at: '',
                bulletin_unsubscribed: '',
                bulletin_unsubscribed_at: '',
                option : ''
            }
 
            , showModal: false
            , modalTitle: ''
            
            , url: '//'+window.location.hostname+'/'
              
        };
    }, 
    created () {   
        this.form.bulletin_subscribed = this.user.bulletin_subscribed;
        this.form.bulletin_subscribed_at = this.user.bulletin_subscribed_at;
        this.form.bulletin_unsubscribed = this.user.bulletin_unsubscribed;
        this.form.bulletin_unsubscribed_at = this.user.bulletin_unsubscribed_at;
    },
    
    watch: {
         
       
    }, 
    computed: {
 
           
    }, 
    methods: {  

        openPopup( option ) {
            this.option = option;
            this.showModal = !this.showModal;
            this.modalTitle = this.option;
        },
        bulletinSubscription() {
            var self = this;
            
            axios.post('/bulletin/processsubscription', self.form).then( function(response) {
                  
                self.form.bulletin_subscribed = response.data.user.bulletin_subscribed;
                self.form.bulletin_subscribed_at = response.data.user.bulletin_subscribed_at;
                self.form.bulletin_unsubscribed = response.data.user.bulletin_unsubscribed;
                self.form.bulletin_unsubscribed_at = response.data.user.bulletin_unsubscribed_at;

                setTimeout(function() {
                    self.show = false;
                }, 4000); // hide the message after 3 seconds
            }); 
        }
   

    },

    mounted() {

    },
    beforeDestroy() {
         
    },
}
</script>   