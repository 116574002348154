<template>
  <div class="container-fluid pl-15 pr-15 pt-15 pb-0">
    <form @submit.prevent="applyFilters">
      <div class="row text-center mb-5">
        <div
          v-for="field in filters_fields"
          :key="field.key"
          :class="'p-5 ' + field.col + (field.hidden == true ? ' hidden' : '')"
        >
          <div v-show="!field.hidden">
            <div class="row text-center">
              <div class="col">
                {{ field.label }}
              </div>
            </div>
            <div class="row">
              <div class="col">
                <input
                  type="text"
                  v-model="filter[field.key]"
                  :placeholder="field.placeholder"
                  class="form-control"
                  ref="additionalFields"
                  @input="validateField(field.key)"
                  @keyup.enter="applyFilters"
                />

                <button
                  v-if="filter[field.key]"
                  type="button"
                  @click="clearField(field.key)"
                  class="border-0 text-12 pt-0 pl-5 pr-5 pb-0 text-gray-6 text-center"
                >
                  <li class="fa fa-times"></li>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center">
        <button
          type="button"
          class="btn btn-primary bg-blue-5"
          @click="applyFilters"
        >
          Search
        </button>
        <br />
        {{ error_message }}
      </div>

      <div class="row mt-15" style="border-top: 2px solid #2f74b5"></div>

      <div class="row text-center mt-5 justify-content-center">
        <div class="col">
          <template>
            <h3 class="mt-15 mb-15 text-center">
              <template v-if="!searchPerformed"> Fleet </template>
              <template v-else-if="totalCount.length === 0">
                Fleets (0)
              </template>
              <template v-else>
                Fleets ({{ totalCount.toLocaleString() }})
              </template>
            </h3>
          </template>
        </div>
      </div>

      <div
        class="row text-center mt-5 justify-content-center"
        v-if="
          filter.airline_name ||
          filter.ac_type_icao ||
          filter.airline_icao ||
          filter.ac_role
        "
      ></div>
    </form>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    page: '',
    filters_fields: {
      type: Array,
      default: () => [],
    },
    filtersheading: String,
    totalCount: 0,
  },
  data() {
    return {
      total_cycles: 0,
      total_hours: 0,
      total_ratio: 0,

      filter: {},
      errors: {},
      error_message: '',
      searchPerformed: false,
    };
  },
  computed: {
    filteredFilter() {
      return Object.keys(this.filter)
        .filter(key => key !== 'groupBy')
        .reduce((obj, key) => {
          if (this.filter[key] !== '') {
            obj[key] = this.filter[key];
          }
          return obj;
        }, {});
    },
  },
  created() {},

  watch: {
    results: {
      handler(newResults) {
        this.calculatePerformance(newResults);
      },
      deep: true,
    },
  },

  methods: {
    clearFilters() {
      this.filter = {}; // Reset filters
      this.searchPerformed = false; // Reset the search performed flag
    },
    clearField(key) {
      this.filter[key] = '';
      this.applyFilters();
    },
    applyFilters() {
      this.error_message = '';
      if (!this.allFieldsValid()) {
        this.error_message =
          'Please input Start, Finish, and at least one other field.';
        return;
      }
      this.searchPerformed = true;
      this.$emit('apply-filters', this.filter);
    },

    validateField(key) {
      this.errors[key] = !this.filter[key];
    },
    allFieldsValid() {
      const atLeastOneOtherField = Object.keys(this.filter).some(
        key => this.filter[key]
      );
      const groupSelected = this.filter.groupBy;

      return atLeastOneOtherField;
    },
    focusFirstInvalidField() {
      const firstInvalidKey = Object.keys(this.errors).find(
        key => this.errors[key]
      );
      if (firstInvalidKey && this.$refs[firstInvalidKey]) {
        this.$refs[firstInvalidKey].focus();
      }
    },
    calculatePerformance(results) {
      this.total_cycles_calc = 0;

      results.forEach(flight => {
        if (flight.freq) {
          this.total_cycles_calc += parseInt(flight.freq, 10);
        }
      });

      this.total_cycles = this.total_cycles_calc.toLocaleString();

      this.total_hours_calc = results.reduce(
        (sum, result) => sum + parseFloat(result.hours || 0),
        0
      );

      this.total_hours = this.total_hours_calc.toLocaleString(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      });

      this.total_ratio = this.total_cycles
        ? (this.total_hours_calc / this.total_cycles_calc).toFixed(1)
        : 0;
    },
  },
};
</script>

<style scoped>
.error {
  border: 2px solid red;
  box-shadow: 2px 2px 2px solid red;
}

button {
  margin-top: 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}
button:hover {
  background-color: #e2e2e2;
}
.error {
  border: 2px solid red;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hidden {
  display: none;
}
</style>
