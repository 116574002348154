<template id="utilities-pop-up">
	<div class="container-fluid">
		<nav class="navbar fixed-bottom navbar-expand-sm navbar-dark text-white footer-bar text-left m-0 p-0 nav-utilities-popup">
			<button class="navbar-toggler text-center" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" style="padding: 10px; margin: auto">
				<small>Reference</small>
			</button>

			<div class="collapse navbar-collapse" id="navbarCollapse">
				<div class="container row-0">
					<ul class="navbar-nav justify-content-centerml-auto mr-auto nav-fill w-100">
						<li class="nav-item menu-item pt-1">
							<div class="dropup">
								<a class="luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
									<span class="text-light" :title="getNavEntryDescription('Availabilities')">Availabilities</span>
								</a>
								<div class="dropdown-menu collapse">
									<a class="dropdown-item" href="http://www.aeroconnect.com" rel="noopener noreferrer" target="_blank">AirConnect</a>
									<a class="dropdown-item" href="http://www.jetclassified.com" rel="noopener noreferrer" target="_blank">Jet Classified</a>
									<a class="dropdown-item" href="http://www.myairtrade.com" rel="noopener noreferrer" target="_blank">MyAirLease</a>
								</div>
							</div>
						</li>

						<!-- 
                    <li class="nav-item menu-item pt-1" >
                        <div class="  dropup">
            
                            <a class=" luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
                                <span class=" text-light"  :title="getNavEntryDescription('Capacities')"  >
                                    Capacities
                                </span>
                            </a>
                            <div class="dropdown-menu collapse">
                                <span class="dropdown-item"><a href="/capacities/list/I 19-29">I 19-29</a></span>
                                <span class="dropdown-item"><a href="/capacities/list/II 30-49">II 30-49</a></span>
                                <span class="dropdown-item"><a href="/capacities/list/III 50-69">III 50-69</a></span>
                                <span class="dropdown-item"><a href="/capacities/list/IV 70-89">IV 70-89</a></span>
                                <span class="dropdown-item"><a href="/capacities/list/V 90-119">V 90-119</a></span>
                                <span class="dropdown-item"><a href="/capacities/list/VI 120-159">VI 120-159</a></span>
                                <span class="dropdown-item"><a href="/capacities/list/VII 160-199">VII 160-199</a></span>
                                <span class="dropdown-item"><a href="/capacities/list/VIII 200-299">VIII 200-299</a></span>
                                <span class="dropdown-item"><a href="/capacities/list/IX 300-399">IX 300-399</a></span>
                                <span class="dropdown-item"><a href="/capacities/list/X 400-499">X 400-499</a></span>
                                <span class="dropdown-item"><a href="/capacities/list/XI 500+">XI 500+</a></span> 
                            </div>
                        </div>  
                    </li>
                -->

						<li class="nav-item menu-item pt-1">
							<div class="dropup">
								<a class="luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
									<span class="text-light" :title="getNavEntryDescription('Currencies')">Currencies</span>
								</a>
								<div class="dropdown-menu collapse">
									<a class="dropdown-item" href="https://www.oanda.com/currency-converter" target="_blank" rel="noopener noreferrer">Oanda</a>
									<a class="dropdown-item" href="https://www.currency.wiki" target="_blank" rel="noopener noreferrer">Wiki</a>
									<a class="dropdown-item" href="https://www.xe.com/currencyconverter" target="_blank" rel="noopener noreferrer">Xe</a>
								</div>
							</div>
						</li>

						<li class="nav-item menu-item pt-1">
							<div class="dropup">
								<a class="luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
									<span class="text-light" :title="getNavEntryDescription('Flights')">Flights</span>
								</a>
								<div class="dropdown-menu collapse">
									<a class="dropdown-item" href="https://flightaware.com" target="_blank" rel="noopener noreferrer">FlightAware</a>
									<a class="dropdown-item" href="https://flighradar24.com" target="_blank" rel="noopener noreferrer">FlightRadar24</a>
									<a class="dropdown-item" href="https://flightstats.com" target="_blank" rel="noopener noreferrer">FlightStats</a>
									<!-- <a class="dropdown-item" href="https://flightmapper.net" target="_blank" rel="noopener noreferrer">FlightMapper</a> -->
									<!-- <a class="dropdown-item" href="https://www.radarbox.com" target="_blank" rel="noopener noreferrer">RadarBox</a> -->
									<!-- <a class="dropdown-item" href="https://planefinder.net" target="_blank" rel="noopener noreferrer">PlaneFinder</a> -->
								</div>
							</div>
						</li>

						<li class="nav-item menu-item pt-1">
							<div class="dropup">
								<a class="luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
									<span class="text-light" :title="getNavEntryDescription('Fuel')">Fuel</span>
								</a>
								<div class="dropdown-menu collapse">
									<slot name="fuel"></slot>
									<a class="dropdown-item" href="https://www.iata.org/en/publications/economics/fuel-monitor/" target="_blank" rel="noopener noreferrer">IATA Jet Fuel Monitor</a>
									<!-- <a class="dropdown-item" href="http://www.indexmundi.com/commodities/?commodity=jet-fuel&months=60&currency=sgd" target="_blank" rel="noopener noreferrer">Mundi Jet Fuel Monthly</a> -->
									<a class="dropdown-item" href="https://www.spglobal.com/commodityinsights/en/oil/refined-products/jetfuel" target="_blank" rel="noopener noreferrer">S&P Global Jet Fuel</a>
									<!-- <a class="dropdown-item" href="https://www.eia.gov/dnav/pet/hist/eer_epjk_pf4_rgc_dpgD.htm" target="_blank" rel="noopener noreferrer">US EIA Petroleum</a> -->
									<!-- <a class="dropdown-item" href="https://www.opisnet.com/product/pricing/spot/asia-jet-fuel-gasoil-report/" target="_blank" rel="noopener noreferrer">OPIS Worldwide Jet Fuel</a> -->
									<a class="dropdown-item" href="https://jet-a1-fuel.com/" target="_blank" rel="noopener noreferrer">Jet-A1-Fuel</a>
								</div>
							</div>
						</li>

						<li class="nav-item menu-item pt-1">
							<a class="luso-nav-link" :class="{ sectionActive: this.isSectionActive['freedoms'] }" :href="url + freedoms" data-toggle="lightbox" title="Open Freedoms" @click.prevent="openFreedoms()">
								<span class="text-light" :title="getNavEntryDescription('Freedoms')">Freedoms</span>
							</a>
						</li>

						<li class="nav-item menu-item pt-1">
							<a class="luso-nav-link" :class="{ sectionActive: this.isSectionActive['glossary'] }" href="#" @click.prevent="openModal('glossary')" title="Open Glossary">
								<span class="text-light" :title="getNavEntryDescription('Glossary')">Glossary</span>
							</a>
						</li>

						<li class="nav-item menu-item pt-1">
							<div class="dropup">
								<a class="luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
									<span class="text-light" :title="getNavEntryDescription('Maps')">Maps</span>
								</a>
								<div class="dropdown-menu collapse">
									<a class="dropdown-item" href="http://www.gcmap.com" target="_blank" rel="noopener noreferrer">Great Circle Mapper</a>
									<a class="dropdown-item" :href="url + ICAOLocatorMap" data-toggle="lightbox">ICAO Locator Map</a>
									<!-- <a class="dropdown-item" href="https://skybrary.aero/airports/directory-map" target="_blank" rel="noopener noreferrer">Skybrary</a> -->
									<a class="dropdown-item" href="https://skyvector.com" target="_blank" rel="noopener noreferrer">SkyVector</a>
								</div>
							</div>
						</li>

						<li class="nav-item menu-item pt-1">
							<!-- <a class="luso-nav-link " href="#" @click.prevent="openModal('measures')" title="Open Measures"><strong>Measures</strong></a> -->
							<measures></measures>
						</li>
					</ul>

					<ul class="navbar-nav justify-content-centerml-auto mr-auto nav-fill w-100 border-top-1 m-0 p-0 footer-nav-second-row">
						<li class="nav-item menu-item pt-1">
							<div class="dropup">
								<a class="luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
									<span class="text-light" :title="getNavEntryDescription('Ocean')">Ocean</span>
								</a>
								<div class="dropdown-menu collapse">
									<a class="dropdown-item" href="http://www.drewry.co.uk" rel="noopener noreferrer" target="_blank">Dreary</a>
									<a class="dropdown-item" href="http://fbx.freightos.com" rel="noopener noreferrer" target="_blank">Freightos</a>
									<a class="dropdown-item" href="https://www.globalmaratimehub.com" rel="noopener noreferrer" target="_blank">Global Maratime Hub</a>
								</div>
							</div>
						</li>
						<li class="nav-item menu-item pt-1">
							<div class="dropup">
								<a class="luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
									<span class="text-light" :title="getNavEntryDescription('Pallets')">Pallets</span>
								</a>
								<div class="dropdown-menu collapse">
									<a class="dropdown-item" href="http://incodocs.com/blog/unit-load-device-uld-air-container-specifications" rel="noopener noreferrer" target="_blank">IncoDocs</a>
									<a class="dropdown-item" href="http://uldcare.com/uld-tools-and-solutions/uld-types" rel="noopener noreferrer" target="_blank">ULDcare</a>
									<a class="dropdown-item" href="http://wikipedia.org/wiki/Unit_load_device" rel="noopener noreferrer" target="_blank">Wiki ULD</a>
								</div>
							</div>
						</li>
						<li class="nav-item menu-item pt-1">
							<div class="dropup">
								<a class="luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
									<span class="text-light" :title="getNavEntryDescription('Photos')">Photos</span>
								</a>
								<div class="dropdown-menu collapse">
									<a class="dropdown-item" href="http://www.airliners.net" rel="noopener noreferrer" target="_blank">Airliners</a>
									<a class="dropdown-item" href="http://www.jetphotos.com" rel="noopener noreferrer" target="_blank">JetPhotos</a>
									<a class="dropdown-item" href="http://www.justplanes.com/" rel="noopener noreferrer" target="_blank">Just Planes</a>
									<!-- <a class="dropdown-item" href="http://www.planespotters.com/photos" rel="noopener noreferrer" target="_blank">Planespotters</a> -->
								</div>
							</div>
						</li>

						<li class="nav-item menu-item pt-1">
							<div class="dropup">
								<a class="luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
									<span class="text-light" :title="getNavEntryDescription('Routes')">Routes</span>
								</a>
								<div class="dropdown-menu collapse">
									<a class="dropdown-item" href="http://www.anna.aero" rel="noopener noreferrer" target="_blank">Anna.aero</a>
									<!-- <a class="dropdown-item" href="http://www.flightconnections.com" rel="noopener noreferrer" target="_blank">FlightConnections</a> -->
									<a class="dropdown-item" href="http://www.flightsfrom.com" rel="noopener noreferrer" target="_blank">FlightsFrom</a>
									<a class="dropdown-item" href="http://www.routesonline.com" rel="noopener noreferrer" target="_blank">Routes Online</a>
									<!-- <a class="dropdown-item" href="http://www.skyscanner.com" rel="noopener noreferrer" target="_blank">Skyscanner</a> -->
								</div>
							</div>
						</li>

						<li class="nav-item menu-item pt-1">
							<div class="dropup">
								<a class="luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
									<span class="text-light" :title="getNavEntryDescription('Safety')">Safety</span>
								</a>
								<div class="dropdown-menu collapse">
									<a class="dropdown-item" href="http://aviation-safety.net" rel="noopener noreferrer" target="_blank">Air Safety Network</a>
									<a class="dropdown-item" href="http://avherald.com" rel="noopener noreferrer" target="_blank">Aviation Herald</a>
									<a class="dropdown-item" href="http://www.baaa-acro.com" rel="noopener noreferrer" target="_blank">Aircraft Accidents Archive</a>
									<!-- <a class="dropdown-item" href="http://skybrary.aero" rel="noopener noreferrer" target="_blank">Skybrary</a> -->
								</div>
							</div>
						</li>

						<li class="nav-item menu-item pt-1">
							<div class="dropup">
								<a class="luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
									<span class="text-light" :title="getNavEntryDescription('Seats')">Seats</span>
								</a>
								<div class="dropdown-menu collapse">
									<a class="dropdown-item" href="http://www.seatguru.com" rel="noopener noreferrer" target="_blank">Seat Guru</a>
									<a class="dropdown-item" href="http://www.seatmaestro.com" rel="noopener noreferrer" target="_blank">Seatmaestro</a>
									<a class="dropdown-item" href="http://seatmaps.com" rel="noopener noreferrer" target="_blank">SeatMaps</a>
								</div>
							</div>
						</li>

						<li class="nav-item menu-item pt-1">
							<div class="dropup">
								<a class="luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
									<span class="text-light" :title="getNavEntryDescription('Signals')">Signals</span>
								</a>
								<div class="dropdown-menu collapse">
									<a class="dropdown-item" :href="url + marshals" data-toggle="lightbox">Marshals</a>
									<a class="dropdown-item" :href="url + morse" data-toggle="lightbox">Morse</a>
									<a class="dropdown-item" :href="url + phonetics" data-toggle="lightbox">Phonetics</a>
								</div>
							</div>
						</li>

						<li class="nav-item menu-item pt-1">
							<div class="dropup">
								<a class="luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
									<span class="text-light" :title="getNavEntryDescription('Times')">Times</span>
								</a>
								<div class="dropdown-menu collapse">
									<a class="dropdown-item" href="https://www.timeanddate.com" rel="noopener noreferrer" target="_blank">TimeandDate</a>
									<a class="dropdown-item" href="https://www.24timezones.com" rel="noopener noreferrer" target="_blank">24timezones</a>
									<a class="dropdown-item" href="https://gisgeography.com/world-time-zone-map/" rel="noopener noreferrer" target="_blank">World Time Zone Map</a>
								</div>
							</div>
						</li>

						<li class="nav-item menu-item pt-1">
							<div class="dropup">
								<a class="luso-nav-link" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" href="#">
									<span class="text-light" :title="getNavEntryDescription('Weather')">Weather</span>
								</a>
								<div class="dropdown-menu collapse">
									<!-- <a class="dropdown-item" href="https://www.icao.int/safety/iStars/Pages/Weather-Conditions.aspx?" rel="noopener noreferrer" target="_blank">ICAO Weather</a> -->
									<a class="dropdown-item" href="https://www.aviationweather.gov" rel="noopener noreferrer" target="_blank">AWC</a>
									<a class="dropdown-item" href="https://www.fltplan.com" rel="noopener noreferrer" target="_blank">FltPlan</a>
									<a class="dropdown-item" href="https://www.skybrary.aero" rel="noopener noreferrer" target="_blank">Skybrary</a>
								</div>
							</div>
						</li>

						<div class="ConvertModal">
							<transition name="modal">
								<div class="modal-mask" @click="close" v-show="showModal">
									<div class="modal-container-2 text-left" style="overflow-y: auto; border: 1px solid #ddd" @click.stop>
										<div class="row bg-dark-gray text-light p-3">
											<div class="col-5">
												<span class="h5" v-show="option == 'measures'">Measures</span>
												<span class="h5" v-show="option == 'glossary'">Glossary</span>
												<span class="h5" v-show="option == ''">User Guide</span>
											</div>
											<div class="col-6">
												<strong>AirLibrary</strong>
											</div>
											<div class="col-1 text-right">
												<a href="#" @click="close"><strong>X</strong></a>
											</div>
										</div>

										<div class="modal-body scrollable-lg">
											<div v-if="option == 'measures'">
												<measures></measures>
											</div>

											<div v-if="option == 'fuel'">
												<fuel></fuel>
											</div>

											<div v-if="option == 'glossary'">
												<glossary></glossary>
											</div>

											<div v-if="option == 'guide'">
												<user-guide></user-guide>
											</div>

											<!-- <div v-if=" option=='freedoms' && this.freedoms == false " >
                                        <freedoms></freedoms> 
                                    </div> -->
										</div>
									</div>
								</div>
							</transition>
						</div>
					</ul>
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
	export default {
		props: ['naventries'],
		data() {
			return {
				url: '//' + window.location.hostname + '/',
				phonetics: 'images/phonetic-alphabet.png',
				morse: 'images/morse.png',
				marshals: 'images/marshal.png',
				freedoms: 'images/ICAO-Freedoms.png',
				ICAOLocatorMap: 'images/ICAO-Locator-Map.jpg',

				isSectionActive: {
					glossary: false,
					publications: false,
					organizations: false,
					events: false,
					freedoms: false,
				},

				todayDate: '',
				modalOpen: false,
				showModal: false,
				option: false,
			};
		},
		created() {},

		watch: {},

		methods: {
			getNavEntryDescription(menuEntry) {
				var tooltip = this.naventries.find(item => item.label === menuEntry);
				return tooltip.description;
			},
			isActive(path) {
				// console.log(path.startsWith('/publications'));
				if (path.startsWith('/publications')) {
					this.isSectionActive['publications'] = true;
				}
				if (path.startsWith('/organizations')) {
					this.isSectionActive['organizations'] = true;
				}
				if (path.startsWith('/events')) {
					this.isSectionActive['events'] = true;
				}
				if (path.startsWith('/glossary')) {
					this.isSectionActive['glossary'] = true;
				}
			},
			openFreedoms: function () {
				// if( this.isSectionActive['freedoms'] == true ) {
				//     this.isSectionActive['freedoms'] = false;
				//     $(this).modal('close');
				// } else {
				//     this.isSectionActive['freedoms'] = true;
				// }
			},
			openModal: function (option) {
				const self = this;
				self.option = option;

				if (self.modalOpen == true) {
					self.modalOpen = false;
				} else {
					self.showModal = true;
					self.isActive('/' + option);
				}
			},
			close: function () {
				this.showModal = false;

				Object.keys(this.isSectionActive).forEach(key => {
					this.isSectionActive[key] = false;
				});
			},

			getTodayDate: function () {
				var today = new Date();
				var dd = String(today.getDate()).padStart(2, '0');
				var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
				var yyyy = today.getFullYear();

				let shortMonth = today.toLocaleString('en-us', { month: 'short' }); /* Jun */

				this.todayDate = yyyy + ' ' + shortMonth + ' ' + dd;
			},
		},

		mounted() {
			this.getTodayDate();

			var path = window.location.pathname;
			this.isActive(path);
		},
	};
</script>
