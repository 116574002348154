<template id="filter">

    <div id="filter" > 
       
            <div class="card" >
                <div class="card-header search-profile"> 
                    <input v-model="mySearch" :placeholder="filterPlaceHolder" class="form-control " type="text" on:change="filterChange">
                </div>
            </div> 
    </div> 
</template>
 
<script> 
// import Fields_Manager from './FieldsManager';
export default{  
        props: [ 'thelist', 'filterPlaceHolder' ],
      data () {
            return { 
                  filteredList: this.thelist  
                  , mySearch: ''  
            }
      },
       methods: { 
        //    searchedWordChanged (event) {
        //     // console.log('It changed: ' + event.target.value );
        //     // this.filteredList = this.mylist; 
        //     // this.$emit('searchedWordChanged',  event.target.value);
        //         this.$emit('filterChange', this.filteredList )
        //     } 
        
        
    }, 
     
      computed: {
          
        //    searchedWordChanged (event) {
        //     // console.log('It changed: ' + event.target.value );
        //     // this.filteredList = this.mylist; 
        //     // this.$emit('searchedWordChanged',  event.target.value);
        //         this.$emit('filterChange', this.filteredList )
        //     } ,
          
            filterChange () {
                // var matcher = new RegExp(this.mySearch, 'i');
                var word = this.mySearch.toLowerCase();
                var result;
 
                if ( word =="" ) {
                    this.$emit('filterChange', this.filteredList)
                    return this.filteredList;
                }
               result = this.filteredList.filter(function( item ){
                    return Object.values(item).some( val => 
                        String(val).toLowerCase().includes(word) );
                }); 
               
               this.$emit('filterChange', result)
                return result;
                
            }
            
      }
      ,watch : {
          filterChange () {
                // var matcher = new RegExp(this.mySearch, 'i');
            //     var word = this.mySearch.toLowerCase();
            //     var result;
 
            //     if ( word =="" ) {
            //         this.$emit('filterChange', this.filteredList)
            //         return this.filteredList;
            //     }
            //    result = this.filteredList.filter(function( item ){
            //         return Object.values(item).some( val => 
            //             String(val).toLowerCase().includes(word) );
            //     }); 
               
            //    this.$emit('filterChange', result)
                // return result;
                
            }
          
      }
}
</script>