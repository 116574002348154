<template id="airlines">
  <div class="container">
    <div class="row mb-2">
      <div class="col text-center">
        <h3>Airlines Alignments</h3>
        Alignments ({{ filteredList.length }})
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="container">
          <div class="sticky">
            <!-- <search-profile section="airlines"></search-profile> -->
            <!-- <filter section="airlines"></filter> -->

            <div class="card">
              <div class="card-header">
                <input
                  class="form-control"
                  v-model="filters.name.value"
                  placeholder="            Search"
                />
              </div>
            </div>

            <v-table
              :data="fleet"
              :filters="filters"
              class="table table-striped-white border"
            >
              <thead slot="head" class="text-center">
                <v-th sortKey="airline" class="col-4 clickable">Airline</v-th>
                <v-th sortKey="country" class="col-4 clickable text-left"
                  >Domicile</v-th
                >
                <v-th sortKey="date" class="col-4 clickable">Date</v-th>
                <v-th sortKey="alignments" class="col-4 clickable"
                  >Alignment</v-th
                >
              </thead>
              <tbody slot="body" slot-scope="{ displayData }">
                <tr
                  v-for="row in displayData"
                  :key="row.id"
                  class="text-center"
                >
                  <td v-if="row.airline_id">
                    <a :href="url + 'airlines/' + row.airline_id + ''">{{
                      row.airline
                    }}</a>
                  </td>
                  <td v-else class="col"></td>

                  <td v-if="row.country != ''" class="text-left">
                    <img
                      v-bind:src="url + 'flags/blank.gif'"
                      :class="'flag flag-' + getCountryFlagCode(row.country)"
                      :alt="row.country"
                      :title="row.country"
                    />
                    {{ row.country }}
                  </td>
                  <td v-else class="col"></td>
                  <td v-if="row.date">
                    {{ customDisplayDateFormat(row.date) }}
                  </td>
                  <td v-else class="col"></td>
                  <td>{{ row.alignments }}</td>
                </tr>
              </tbody>
            </v-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters';
//    import SmartTable from 'vuejs-smart-table'

// Vue.use(SmartTable)

export default {
  mixins: [Vue2Filters.mixin],
  props: ['fleet', 'filter'],
  data: function () {
    return {
      section: 'fleets_alignments',
      currentSort: 'airline.airline',
      currentSortDir: 'asc',
      searchQuery: '',
      totalResults: '',
      filteredList: '',
      url: '//' + window.location.hostname + '/',

      filters: {
        name: {
          value: '',
          keys: ['airline', 'country', 'change'],
        },
      },

      sortKey: 'airline',
      reverse: false,
      columns: ['airlines', 'codes', 'license', 'fleet', 'status', 'domicile'],
    };
  },
  created() {
    this.filteredList = this.fleet;
    this.filters.name.value = this.filter;
    // this.totalResults = Object.keys(this.filteredList).length;
  },
  mounted() {
    this.filteredList = this.fleet;
    this.totalResults = this.fleet.length;
  },
  methods: {
    updateFilteredList() {
      this.filteredList = this.filterBy(
        this.fleet,
        this.filters.name.value,
        this.filters.name.keys
      );
    },
  },
  computed: {
    countList: function () {
      var n = this.filterBy(this.filteredList, this.searchQuery);
      this.totalResults = n.length;
      return this.totalResults;
    },
  },
  watch: {
    'filters.name.value'() {
      this.updateFilteredList();
    },
    fleet() {
      this.updateFilteredList();
    },
  },
};
</script>
