<template id="utilities">
	<div class="notes-container">
		<textarea class="notes form-group w-100" @input="autoSave" v-model="form.notes"></textarea>
	</div>
</template>

<script>
	import _ from 'lodash';

	export default {
		props: [],
		data() {
			return {
				form: {
					notes: '',
				},
				isAuthenticated: false,
				modalOpen: false,
				showModal: false
			};
		},
		created() {},

		watch: {},

		methods: {
			autoSave: _.debounce(function () {
				var self = this;
				axios.post('/api/notes', this.form).then(function (response) {
					self.form.notes = response.data.notes;
				});
			}, 1000),

			fetchData() {
				const self = this;
				if (this.isAuthenticated === undefined || this.isAuthenticated === 'false') return;

				axios.get('/api/notes').then(function (response) {
					self.form.notes = response.data.notes;
				});
			},
		},

		mounted() {
			this.isAuthenticated = this.$root.isAuthenticated;
			this.fetchData();
		},
	};
</script>

<style>
	textarea.notes {
		width: 225px;
		height: 300px;
		overflow-y: scroll;
	}
</style>
