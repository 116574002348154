<template id="capacities">
    <div class=" "> 
<!-- 
        <div class="card sticky" >
            <div class="card-header "> 
                <input type="text" placeholder="Find Aircraft" v-model="searchQuery" class="form-control ">
            </div>
        </div>  -->
        
        <!-- Aircraft Builder Seats Pallets Compartments -->

        <table class="table table-striped   mt-3">
        <thead class=" bg-dark-blue-gray ">
            <tr>
                    <th scope="col" @click="sorting('model')" class="clickable text-center">Aircraft</th>
                    <th scope="col" @click="sorting('builder.builder')" class="clickable text-center">Builder</th>
                    <th scope="col" @click="sorting('seats')" class="clickable text-center">Cabin Seats</th>
                    <th scope="col" @click="sorting('pallets')" class="clickable text-center">Cabin Pallets</th>
                    <th scope="col" @click="sorting('compartments')" class="clickable text-center">Compartments</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in filterBy(filteredList, searchQuery)" :key="item.id">
                <td v-if=" item.model " class="text-center"><a :href="url+'aircraft/' + item.id +''">{{ item.model }}</a></td><td v-else></td>
                <td v-if=" item.builder_id && item.builder " class="text-center"><a :href="url+'builders/' + item.builder_id +''">{{ item.builder.builder }}</a></td><td v-else></td>
                <td v-if=" item.seats" class="text-center">{{ item.seats }}</a></td><td v-else></td>
                <td v-if=" item.pallets" class="text-center">{{ item.pallets }}</a></td><td v-else></td>
                <td v-if=" item.compartments" class="text-center">{{ item.compartments }}</a></td><td v-else></td>
            </tr>
        </tbody>
        </table>
    </div> 
</template>
 
 
<script> 
   import Vue2Filters from 'vue2-filters'

    export default{
        mixins: [ Vue2Filters.mixin],
        props: [ 'capacities' ],
        data: function () {
            return {  
                searchQuery: ''
                 
                , currentSort:'group'
                , urrentSortDir:'asc'
                , filteredList: this.capacities
                , url: '//'+window.location.hostname+'/'
            };
        },
        created () {   

            this.filteredList = this.capacities;

        },
        methods: { 
        
            morethan () {
                return 
            } , 
            
            matches(obj) {
                const term = this.searchQuery.toLowerCase(); 
                return filteredList.toLowerCase().includes(term);
            } ,

            filteredAirports: function() {

                        if( this.searchQuery =="" )
                            return;

                            const self = this; 
                            // console.log('we are filtering: ' +  this.searchQuery );

                            let formData = new FormData();

                            formData.append('searchQuery', this.searchQuery ); 
                        
                            var $url = "/capacities/filter"; 
                            formData.append('_method', 'post' );  
                            
                            axios.post( $url,  formData )
                            .then(function(response){
                                    self.filteredList = response.data; 
                                    return;
                            })
                            .catch( function(error){  
                            }); 

                            if ( this.searchQuery =="" ) {
                                return this.filteredList;
                            }
            
                    },
                    cleanStr: function(str, type) {
                        
                        return str.replace(type, "");
                    }

        }
        , computed: {
            // filteredAirport() {
            //     if ( this.searchQuery =="" ) {
            //         return this.filteredList;
            //     }
            //     return this.filteredList.filter(function(builder) {
            //         return airport.includes(function(item) {
            //             return item.airport.includes(this.searchQuery);
            //         });
            //     });
            // }  
        } 
    }
    
</script>
 