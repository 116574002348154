<template id="builders">
	<div class="container-fluid">
		<search-input placeholder="Find Builder" v-model="search" />

		<subcount>
			<slot></slot>
		</subcount>

		<div style="overflow-y: auto">
			<v-table :data="builders" :filters="filters" class="table bg-light bordered border">
				<thead slot="head" class="text-center">
					<!-- <thead class=" bg-white"> -->
					<tr>
						<v-th scope="col" sortKey="builder" class="clickable text-center w-150px">Builder</v-th>
						<v-th scope="col" sortKey="country" class="clickable text-center w-150px">Domicile</v-th>
						<v-th scope="col" sortKey="product" class="clickable text-center w-200px">Product</v-th>
						<v-th scope="col" sortKey="types" class="clickable text-center w-100px">Types</v-th>
						<v-th scope="col" sortKey="models" class="clickable text-center w-150px">Models</v-th>
					</tr>
				</thead>
				<tbody slot="body" slot-scope="{ displayData }">
					<tr v-for="item in displayData" :key="item.id">
						<td v-if="item.builder" class="text-center">
							<a :href="url + 'builders/' + item.id + ''">{{ item.builder }}</a>
						</td>
						<td v-else></td>
						<td v-if="item.country_id != '' && item.country" class="text-center">
							<img v-bind:src="url + 'flags/blank.gif'" :class="'flag flag-' + getCountryFlagCode(item.country)" :alt="item.country" :title="item.country" />
							{{ item.country }}
						</td>
						<td v-else></td>

						<td v-if="item.product" class="text-center">{{ item.product }}</td>
						<td v-else></td>
						<td v-if="item.types" class="text-center text-capitalize">
							{{ item.types }}
						</td>
						<td v-else></td>
						<td v-if="item.models" class="text-center">{{ item.models }}</td>
						<td v-else></td>
					</tr>
				</tbody>
			</v-table>
		</div>
	</div>
</template>

<script>
	import Vue2Filters from 'vue2-filters';

	import SearchInput from './SearchInput.vue';
	Vue.component('search-input', SearchInput);

	export default {
		mixins: [Vue2Filters.mixin, './numberformatmixin'],

		components: {
			SearchInput,
		},

		props: ['builders', 'builder_type'],
		data: function () {
			return {
				search: '',
				section: 'builders',
				currentSort: 'builder',
				currentSortDir: 'asc',
				searchQuery: '',
				filteredList: this.builders,
				url: '//' + window.location.hostname + '/',
				filters: {
					name: {
						value: '',
						keys: ['builder', 'product', 'types', 'models', 'country', 'builder_type'],
					},
				},
			};
		},
		created() {
			this.mylist = this.builders;
			console.log(this.builder_type);
			if (this.builder_type) {
				this.filters.name.value = this.builder_type;
			}
		},
		methods: {},
	};
</script>
