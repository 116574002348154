<template id="search-section">
	<div class="container-fluid mb-2" @focusout="handleFocusOut" @focus="handleFocus" tabindex="0">
		<!-- <div class="row sticky card">  -->
		<div class="card">
			<div class="card-header search-profile p-1">
				<input v-model="searchQuery" class="form-control text-capitalize" :placeholder="placeholder" @keyup.esc="clearAll()" @focus="handleFocus" />
			</div>

			<template v-if="displayResults == 'active'">
				<div class="search-body-2">
					<table class="table table-hover auto-complete" style="overflow-y: scroll; height: auto; width: 100%; border: 5px solid #fbeaa3 !important">
						<tbody class="bg-white">
							<tr v-if="totalResults >= 0">
								<td class="no-hover pl-2">
									<small class="text-extra-small">
										<em>Website search results: {{ totalResults }}</em>
										<span v-if="totalResults > 1000">+</span>
									</small>
								</td>
							</tr>
							<tr v-for="item in fetchedResults" :key="item.id" class="widen2 border p-1">
								<td class="widen2">
									<div class="row pl-3">
										<template v-if="section == 'airlines/manager' || section == 'updates/manager'">
											<a :href="url + '' + section + '/' + item.id + '/edit'">{{ item.name }}</a>
										</template>
										<template v-else>
											<a :href="url + '' + section + '/' + item.id">{{ item.name }}</a>
										</template>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import Vue2Filters from 'vue2-filters';

	export default {
		mixins: [Vue2Filters.mixin],
		props: ['section'],
		data: function () {
			return {
				currentSort: 'airline',
				displayResults: 'innactive',
				currentSortDir: 'asc',
				searchQuery: '',
				fetchedResults: [],
				section_singular: '',
				searchResults: '',
				totalResults: '',
				placeholder: 'Find ',

				url: '//' + window.location.hostname + '/',
			};
		},
		created() {},
		mounted() {
			switch (this.section) {
				case 'airlines/manager':
					this.placeholder += 'Airline';
					break;
				case 'updates/manager':
					this.placeholder += 'Update';
					break;
				default:
					// for sections ending in S we remove the S so on the serach box we get Singular ie Find Airline instead of Find AirlineS. Does not Apply to Aircraft or airspace.
					// sections: aircraft, airlines, airports, builders, engines, domiciles, airspace
					if (this.section.substring(this.section.length, this.section.length - 1, 1) == 's') this.placeholder += this.section.substring(0, this.section.length - 1);
					else this.placeholder += this.section;
			}
		},
		computed: {},
		methods: {
			clearAll: function () {
				this.fetchedResults = '';
				this.searchResults = '';
				this.searchQuery = '';
				this.displayResults = 'innactive';
			},

			getResults: function () {
				if (this.searchQuery == '') {
					return;
				}

				const self = this;

				let formData = new FormData();

				formData.append('searchQuery', this.searchQuery);

				var $url = self.url + self.section + '/search/1000';
				formData.append('_method', 'post');

				axios
					.post($url, formData)
					.then(function (response) {
						self.fetchedResults = response.data;
						self.totalResults = self.fetchedResults.length;
						self.displayResults = 'active';
						return response.data;
					})
					.catch(function (e) {
						console.log('Error' + e);
					});
			},

			handleFocus() {
				// if @input focus the totalResults >=1, display existing results.
				// This may be better than if searchQuery not empty because some searchQuery may be blank.
				if (this.totalResults >= 1) {
					this.displayResults = 'active';
				}
			},
			handleFocusOut(event) {
				// if the window clik is out of the focus area, hide sarch results.
				if (!event.currentTarget.contains(event.relatedTarget)) {
					this.displayResults = 'innactive';
				}
			},
		},
		watch: {
			searchQuery: function () {
				if (this.searchQuery == '') {
					this.fetchedResults = [];
					this.displayResults = 'innactive';
				} else {
					this.getResults();
				}
			},
		},
	};
</script>
