<template id="measures-link">
	<div class="measures-link">
		<a class="text-blue-7 dropdown-item" href="#" @click.prevent="showModal = true" title="Open measures">
			<slot name="text"></slot>
			<slot name="image"></slot>
		</a>

		<modal v-if="showModal" @close="showModal = false" header="0">
			<div slot="header"></div>
			<div slot="body"><measures></measures></div>
			<div slot="footer"></div>
		</modal>
	</div>
</template>

<script>
	import Vue2Filters from 'vue2-filters';
	import Modal from './Modal';
	Vue.component('modal', Modal);

	export default {
		mixins: [Vue2Filters.mixin],
		props: ['measures'],
		data: function () {
			return {
				showModal: false,
				filteredList: this.measures,
				url: '//' + window.location.hostname + '/',
			};
		},
		created() {},
		methods: {
			getGlossary() {
				self = this;
				var $url = '/measures/get/none';

				axios
					.get($url)
					.then(function (response) {
						// self.measures = response.data.measures;
						self.filteredList = response.data.measures;
						self.show = false;
					})
					.catch(function (error) {});
			},
		},
		computed: {},
		mounted() {},
	};
</script>
