<template id="airline-fleet-show">
	<div class="test">
		<v-table :data="airlinefleet" class="table table-striped table-bordered">
			<thead slot="head">
				<tr class="table-header">
					<v-th scope="col" sortKey="aircraft.model" defaultSort="asc" class="clickable text-center">Model</v-th>
					<v-th scope="col" sortKey="aircraft.code" class="clickable text-center">Code</v-th>
					<v-th scope="col" sortKey="variants" class="clickable text-center">Variant</v-th>
					<v-th scope="col" sortKey="engines.engine" class="clickable text-center">Engines</v-th>
					<v-th scope="col" sortKey="configuration" class="clickable text-center">Cabin</v-th>
					<v-th scope="col" sortKey="units" class="clickable text-center">Units</v-th>
				</tr>
			</thead>
			<tbody slot="body" slot-scope="{ displayData }">
				<tr v-for="item in displayData" :key="item.id" class="text-center">
					<td v-if="item.aircraft">
						<a :href="url + 'aircraft/' + item.aircraft.id + ''">{{ item.aircraft.model }}</a>
					</td>
					<td v-else></td>
					<td v-if="item.aircraft">{{ item.aircraft.code }}</td>
					<td v-else></td>
					<td v-if="item.variants">{{ item.variants }}</td>
					<td v-else></td>
					<td v-if="item.engines">{{ item.engines.model }}</td>
					<td v-else></td>
					<td v-if="item.configuration" class="text-capitalize">
						{{ item.configuration }}
					</td>
					<td v-else></td>
					<td v-if="item.units">{{ item.units }}</td>
					<td v-else></td>
				</tr>
			</tbody>
		</v-table>
	</div>
</template>

<script>
	import Vue2Filters from 'vue2-filters';

	export default {
		mixins: [Vue2Filters.mixin],
		props: ['airlinefleet'],
		data: function () {
			return {
				currentSort: 'aircraft.model',
				currentSortDir: 'asc',
				searchQuery: '',
				filteredList: this.airlinefleet,
				url: '//' + window.location.hostname + '/',
			};
		},
		created() {
			this.filteredList = this.airlinefleet;
		},
		methods: {
			morethan() {
				return;
			},
			filterMyList(value) {
				this.filteredList = value;
			},
		},
	};
</script>
