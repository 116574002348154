<template>
	<div class="card mb-15 bg-gray-10">
		<div class="card-header search-webpage">
			<input class="form-control" v-model="searchQuery" :placeholder="placeholder" @input="$emit('input', searchQuery)" />
		</div>
	</div>
</template>

<script>
	import Vue2Filters from 'vue2-filters';

	export default {
		mixins: [Vue2Filters.mixin],
		name: 'SearchInput',
		props: {
			placeholder: {
				type: String,
				default: 'Search webpage',
			},
		},
		data() {
			return {
				searchQuery: '',
			};
		},
	};
</script>
