<template id="pop-up">
	<div class="notes-container">
		<div>
			<a class="text-light" href="#" @click.prevent="openPopup()">
				<slot name="linktext">Click Here</slot>
			</a>
		</div>

		<modal v-if="showModal" @close="closeModal" :header="header">
			<template v-slot:header>
				<slot name="header"></slot>
			</template>

			<template v-slot:body>
				<slot name="body"></slot>
			</template>
			<template v-slot:footer>
				<slot name="footer"></slot>
			</template>
		</modal>
	</div>
</template>

<script>
	import _ from 'lodash';

	export default {
		props: {
			header: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				showModal: false,
			};
		},

		methods: {
			openPopup() {
				this.showModal = true;
			},
			closeModal() {
				this.showModal = false;
			},
		},

		mounted() {},
	};
</script>

<style>
	textarea.notes {
		width: 225px;
		height: 300px;
		overflow-y: scroll;
		padding-bottom: 325px;
	}
</style>
