<template id="converter">
	<div class="wrap text-left" style="border: 0 solid #ddd">
		<!-- <div ref="draggableContainer" id="draggable-container"> -->
		<!-- <div id="draggable-container"> -->
		<!-- <div id="draggable-header" @mousedown="dragMouseDown"> -->
		<!-- <div id="draggable-header"> -->
		<div class="row bg-dark text-light m-1 p-0 converter-table-header" id="draggable-header">
			<div class="col-3 col-sm-2 col-md-3 text-center p-3 font-weight-bold">Measure</div>
			<div class="col-3 col-sm-3 col-md-3 text-center p-3 font-weight-bold">Metric</div>
			<div class="col-1 col-sm-1 col-md-1 text-center p-3 font-weight-bold"></div>
			<div class="col-3 col-sm-3 col-md-3 text-center p-3 font-weight-bold">Imperial</div>
			<div class="col-1 col-sm-1 col-md-1 text-center p-3 font-weight-bold"></div>
		</div>
		<div class="row" v-if="error.length">
			<div class="col text-danger text-center">
				<b>{{ error }}</b>
			</div>
		</div>
		<!-- </div> -->

		<div class="body p-0">
			<converter-form-2 name="Area" factormetric="10.76" factorimperial="0.09" metricprefix="m" imperialprefix="f" sup="2" decimal="1" bg="white" @getError="childError"></converter-form-2>

			<converter-form-2 name="Distance sm" factormetric="0.6213711" factorimperial="1.609344" metricprefix="km" imperialprefix="sm" sup="" decimal="2" bg="#efefef" @getError="childError"></converter-form-2>

			<converter-form-2 name="Distance nm" factormetric="0.539957" factorimperial="1.852" metricprefix="km" imperialprefix="nm" sup="" decimal="2" bg="white" @getError="childError"></converter-form-2>

			<converter-form-2 name="Distance nm = sm" factormetric="1.15078" factorimperial="0.868976" metricprefix="nm" imperialprefix="sm" sup="" decimal="2" bg="white" @getError="childError"></converter-form-2>

			<converter-form-2 name="Fuel" factormetric="0.264172" factorimperial="3.78541" metricprefix="liter" imperialprefix="gal" sup="" decimal="2" bg="#efefef" @getError="childError"></converter-form-2>

			<converter-form-2 name="Dimension" factormetric="3.28084" factorimperial="0.3048" metricprefix="m" imperialprefix="ft" sup="" decimal="1" bg="white" @getError="childError"></converter-form-2>

			<converter-form-2 name="LWH" factormetric="0.3937" factorimperial="2.54" metricprefix="cm" imperialprefix="in" sup="" decimal="1" bg="#efefef" @getError="childError"></converter-form-2>

			<converter-form-2 name="Load" factormetric="4.8824" factorimperial="0.20482" metricprefix="kg/m2" imperialprefix="lb/f2" sup="" decimal="2" bg="white" @getError="childError"></converter-form-2>

			<converter-form-2 name="Power" factormetric="1.35962" factorimperial="0.74569987" metricprefix="kW" imperialprefix="hp" sup="" decimal="2" bg="#efefef" @getError="childError"></converter-form-2>

			<converter-form-2 name="Speed sm" factormetric="0.62137119223" factorimperial="1.60934" metricprefix="km/hr" imperialprefix="sm/hr" sup="" decimal="0" bg="white" @getError="childError"></converter-form-2>

			<converter-form-2 name="Speed nm" factormetric="0.53995680345" factorimperial="1.852" metricprefix="km/hr" imperialprefix="nm/hr" sup="" decimal="0" bg="#efefef" @getError="childError"></converter-form-2>

			<converter-form-2 name="Temperature" factormetric="1.8" factorimperial="0.453592" metricprefix="&#8451;" imperialprefix="&#8457;" sup="" decimal="0" bg="white" @getError="childError"></converter-form-2>

			<converter-form-2 name="Thrust" factormetric="224.809" factorimperial="4.44822" metricprefix="kN" imperialprefix="lbf" sup="" decimal="1" bg="#efefef" @getError="childError"></converter-form-2>

			<converter-form-2 name="Volume" factormetric="35.3147" factorimperial="0.3048" metricprefix="m" imperialprefix="f" sup="3" decimal="1" bg="white" @getError="childError"></converter-form-2>

			<converter-form-2 name="Weight" factormetric="2.20462" factorimperial="0.453592" metricprefix="kg" imperialprefix="lb" sup="" decimal="1" bg="white" @getError="childError"></converter-form-2>
		</div>
		<!-- </div> -->
	</div>
</template>

<script>
	export default {
		mixins: ['./draggablemixin'],
		props: [],
		data() {
			return {
				showModal: false,

				error: '',
				activeItem: 'imperial',
				isActive: true,

				positions: {
					clientX: undefined,
					clientY: undefined,
					movementX: 0,
					movementY: 0,
				},
			};
		},
		created() {},

		methods: {
			openMeasures: function () {
				this.showModal = !this.showModal;
				this.isActive = !this.isActive;
				// if( this.showModal )
				// {
				//     this.showModal = false;
				//     this.isActive = false;
				// }
				// else
				// {
				//     this.isActive = true;
				//     this.showModal = true;
				// }
			},

			childError: function (childError) {
				this.error = childError;
			},

			dragMouseDown: function (event) {
				event.preventDefault();
				// get the mouse cursor position at startup:
				this.positions.clientX = event.clientX;
				this.positions.clientY = event.clientY;
				document.onmousemove = this.elementDrag;
				document.onmouseup = this.closeDragElement;
			},
			elementDrag: function (event) {
				event.preventDefault();
				this.positions.movementX = this.positions.clientX - event.clientX;
				this.positions.movementY = this.positions.clientY - event.clientY;
				this.positions.clientX = event.clientX;
				this.positions.clientY = event.clientY;
				// set the element's new position:
				this.$refs.draggableContainer.style.top = this.$refs.draggableContainer.offsetTop - this.positions.movementY + 'px';
				this.$refs.draggableContainer.style.left = this.$refs.draggableContainer.offsetLeft - this.positions.movementX + 'px';
			},

			closeDragElement() {
				document.onmouseup = null;
				document.onmousemove = null;
				this.isActive = false;
			},

			close: function () {
				this.showModal = false;
				this.isActive = false;
			},
		},

		mounted() {
			const self = this;
			document.addEventListener('keydown', e => {
				if (self.showModal && e.keyCode == 27) {
					s;
					self.showModal = false;
				}
			});
		},
	};
</script>

<style>
	#draggable-container {
		background: #fff;
		position: fixed;
		top: 50px;
		/* left: 30%;  */
		z-index: 9999;
		width: 650px;
		/* height: 200px; */
		color: #333;
		box-shadow: 1px 1px 10px rgba(1, 1, 1, 0.5);
	}
	#draggable-header {
		z-index: 10;
	}
</style>
