<template>
	<div class="p-0 m-0">
		<div class="row p-0">
			<div class="col text-center text-blue-5 p-15">
				<h1 class="t-32 text-blue-5 text-capitalize p-0 m-0" style="line-height: 30px">
					<a href="#" @click.prevent="resetFilter">Placements ({{ totalResultsCount.toLocaleString() }})</a>
				</h1>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<GeneralFilter :counts="counts" @filter-field="applyStatusFilters" :nItems="0" :nGreater="-1" ref="devFilter" />
			</div>
		</div>
		<div class="row p-0">
			<div class="col bg-light text-center text-blue-5 pl-25 pr-25">
				<DateFilters @apply-filters="applyFilters" :results="results" :totalCount="totalCount" filtersheading="Enter one item" :date-limitations="dateLimitations" :filledfields="filled"></DateFilters>

				<!-- <AirTable :fields="tableFields" :data="results" :totalPages="totalPages" :totalCount="totalCount" :currentPage="currentPage" :sortField="sortField" :sortOrder="sortOrder" :enableCounts="false" :countRow="countData" @rowSelected="viewRecordDetails" @page-changed="fetchData(filters, $event)" @sort-changed="handleSortChange" ref="airTableRef" /> -->

				<LoadingBar ref="loadingBarRef" />

				<div style="overflow-x: auto">
					<v-table :data="results" :filters="filters" :currentPage.sync="currentPage" :pageSize="50" @totalPagesChanged="totalPages = $event" class="table table-striped-white bg-light border">
						<thead slot="head" class="text-center bg-blue-5 text-light">
							<v-th v-for="field in tableFields" :key="field.key" :sortKey="field.key" class="clickable w-120px">
								<span>{{ field.label ?? '' }}</span>
							</v-th>

							<!-- <v-th sortKey="models" class="clickable w-220px">Models</v-th>
	<v-th sortKey="line" class="clickable w-300px">Line</v-th>
	<v-th sortKey="site" class="clickable w-200px">Site</v-th>
	<v-th sortKey="conversions" class="clickable w-200px">Conversions</v-th>
	<v-th sortKey="contractors" class="clickable w-200px">Contractors</v-th> -->
						</thead>
						<tbody slot="body" slot-scope="{ displayData }">
							<tr v-for="row in displayData" :key="row.id" class="text-center">
								<td v-for="field in tableFields" :key="field.key">
									<!-- Render cell content dynamically -->
									<span v-if="field.type === 'date'">
										<template>
											{{ formatField(row, field) }}
										</template>
									</span>
									<span v-else-if="field.key === 'lessor_name'">
										<a :href="url + 'airlines/' + row.airline_id" rel="noopener noreferrer">{{ row[field.key] ?? '-' }}</a>
									</span>
									<span v-else-if="field.key === 'lessee_name'">
										<a :href="url + 'airlines/' + row.lessee_id" rel="noopener noreferrer">{{ row[field.key] ?? '-' }}</a>
									</span>
									<span v-else-if="field.key === 'aircraft_name'">
										<a :href="url + 'aircraft/' + row.aircraft_id" rel="noopener noreferrer">{{ row[field.key] ?? '-' }}</a>
									</span>
									<span v-else-if="field.key === 'configuration'">
										{{ switchConfiguration(row[field.key]) }}
									</span>
									<span v-else>{{ row[field.key] ?? '-' }}</span>
								</td>
							</tr>
						</tbody>
					</v-table>

					<smart-pagination :currentPage.sync="currentPage" :totalPages="totalPages" :maxPageLinks="12" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import DateFilters from './DateFilters';
	import AirTable from './AirTable';
	import GeneralFilter from './GeneralFilter';
	import LoadingBar from './LoadingBar';

	export default {
		components: {
			DateFilters,
			AirTable,
			GeneralFilter,
			LoadingBar,
		},

		props: {
			plan: String,
			accesslimit: String,
			tableFields: {
				type: Array,
				default: () => [],
			},
			filled: {
				type: Object,
				default: () => ({}),
			},
			counts: {
				type: Object,
				// default: () => [],
			},
			totalResultsCount: {
				type: String,
				default: 0,
			},
			status: String,
		},
		data() {
			return {
				currentPage: 1,
				totalPages: 0,
				results: [],

				totalCount: 0,

				sortField: 'start_date',
				sortOrder: 'desc',

				countData: {},

				pageWindowSize: 10,

				abortController: null,
				loadingCounts: false,

				// New additions
				filters: {},
				dateLimitations: {},
				isLoading: false, // For handling loading states

				statusFilter: null,
			};
		},
		created() {
			let minDate = moment()
				.subtract(365 * 3, 'days')
				.format('YYYY-MM-DD');
			let maxDate = moment().format('YYYY-MM-DD');

			this.dateLimitations = {
				minDate: minDate,
				maxDate: maxDate,
			};
			// Removed assignments from placements
		},
		mounted() {
			this.fetchData();
		},
		computed: {
			// paginatedPages() {
			// 	let startPage = Math.max(1, this.currentPage - Math.floor(this.pageWindowSize / 2));
			// 	startPage = Math.min(startPage, Math.max(1, this.totalPages - this.pageWindowSize + 1));
			// 	return Array.from({ length: Math.min(this.pageWindowSize, this.totalPages) }, (v, i) => i + startPage);
			// },
		},

		methods: {
			displayList() {
				this.fetchData();
			},

			applyFilters(newFilters) {
				if (!window.Airvita.isLoggedIn) return;

				this.filters = newFilters;
				this.fetchData(this.filters);
			},

			fetchData(filters) {
				console.log(filters);
				if (!window.Airvita.isLoggedIn) return;

				const self = this;

				this.$nextTick(() => {
					self.$refs.loadingBarRef.startLoading();
				});

				const queryParams = new URLSearchParams({
					...filters,
					sortField: this.sortField,
					sortOrder: this.sortOrder,
				});

				// console.log(this.statusFilter);

				if (this.statusFilter) {
					queryParams.append('status', this.statusFilter);
				}

				var url;

				console.log(this.statusFilter);

				switch (this.statusFilter) {
					case 'Completed':
						url = `/api/placements/completed?${queryParams}`;
						break;
					case 'Operating':
						url = `/api/placements/operating?${queryParams}`;
						break;
					case 'Flying':
						url = `/api/placements/flying?${queryParams}`;
						break;
					case 'Flown':
						url = `/api/placements/flown?${queryParams}`;
						break;
					case 'Pending':
						url = `/api/placements/pending?${queryParams}`;
						break;

					default:
						url = `/api/placements/get?${queryParams}`;
				}

				axios
					.get(url)
					.then(response => {
						self.results = response.data.results;
						// self.totalPages = response.data.totalPages;
						self.totalCount = response.data.totalCount;
						// this.currentPage = page;
						if (self.$refs.loadingBarRef && typeof self.$refs.loadingBarRef.stopLoading === 'function') {
							self.$refs.loadingBarRef.stopLoading();
						}

						self.isLoading = false;
					})
					.catch(error => {
						if (axios.isCancel(error)) {
							console.log('Request canceled', error.message);
						} else {
							this.results = [];
							this.totalCount = 0;
							// this.totalPages = 0;
							// this.currentPage = 1;
							// Optionally display an error message to the user
							this.$toast.error('Failed to fetch data.');

							if (self.$refs.loadingBarRef && typeof self.$refs.loadingBarRef.stopLoading === 'function') {
								self.$refs.loadingBarRef.stopLoading();
							}
						}

						// Stop loading indicator
						self.isLoading = false;
					});
			},

			handleSortChange({ sortField, sortOrder }) {
				this.sortField = sortField;
				this.sortOrder = sortOrder;
				this.fetchData(this.filters, this.currentPage);
			},
			viewRecordDetails() {
				// Implement as needed
			},

			switchConfiguration(configuration) {
				if (!configuration) return '-';
				
				const configLower = configuration.toLowerCase();
				console.log(configLower);
				
				if (configLower === 'car' || configLower === 'ftr' || configLower === 'freighter' || configLower === 'cgo') {
					return 'FTR';
				} else if (configLower === 'passenger' || configLower === 'pax') {
					return 'PAX';
				} else if (configLower === 'executive' || configLower === 'vip') {
					return 'VIP';
				} else {
					return configuration;
				}
			},
			applyStatusFilters(statusType) {
				this.statusFilter = statusType;

				this.fetchData(this.filters, 1);
			},
			resetFilter() {
				this.$refs.devFilter.resetFilter();
				this.applyStatusFilters(null);
			},
		},
	};
</script>

<style>
	/* Add your styles here */
	.loading-spinner {
		/* Style your loading spinner */
		text-align: center;
		padding: 20px;
	}
</style>
