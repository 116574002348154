<template id="airlines">
  <div class="container">
    <search-input placeholder="" v-model="filters.name.value" />

    <v-table
      :data="fleet"
      :filters="filters"
      class="table table-striped-white border mt-15"
    >
      <thead slot="head" class="text-center">
        <v-th sortKey="builder" class="col-4 clickable">Builder</v-th>
        <v-th sortKey="type" class="col-4 clickable">Type</v-th>
        <v-th sortKey="code" class="col-4 clickable">Model</v-th>
        <v-th sortKey="total_units" class="col-4 clickable">Fleet</v-th>
        <v-th sortKey="operators" class="col-4 clickable">Operators</v-th>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr v-for="row in displayData" :key="row.id" class="text-center">
          <td v-if="row.builder">
            <a :href="url + 'builders/' + row.builder_id + ''">{{
              row.builder
            }}</a>
          </td>
          <td v-else class="col"></td>

          <td>{{ row.type }}</td>

          <td>
            <a :href="url + 'aircraft/' + row.id + ''">{{ row.code }}</a>
          </td>

          <td>{{ row.total_units }}</td>

          <td>{{ row.operators }}</td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters';

export default {
  mixins: [Vue2Filters.mixin],
  props: ['fleet'],
  data: function () {
    return {
      section: 'fleets_alignments',
      currentSort: 'airline.airline',
      currentSortDir: 'asc',
      searchQuery: '',
      totalResults: '',
      filteredList: '',
      url: '//' + window.location.hostname + '/',

      filters: {
        name: {
          value: '',
          keys: ['model', 'code', 'builder', 'total_units', 'operators'],
        },
      },

      sortKey: 'airline',
      reverse: false,
      columns: ['model', 'code', 'builder', 'total_units', 'operators'],
    };
  },
  created() {
    this.filteredList = this.fleet;
    // this.totalResults = Object.keys(this.filteredList).length;
  },
  mounted() {
    this.filteredList = this.fleet;
    this.totalResults = this.fleet.length;
  },
  methods: {},
  computed: {
    countList: function () {
      var n = this.filterBy(this.filteredList, this.searchQuery);
      this.totalResults = n.length;
      return this.totalResults;
    },
  },
  watch: {},
};
</script>
