<template id="glossary-link">
	<div class="glossary-link p-5" style="min-height: 185px">
		<a class="text-blue-7" href="#" @click.prevent="showModal = true" title="Open Glossary">
			<slot name="image"></slot>
		</a>

		<modal v-if="showModal" @close="showModal = false" header="1">
			<div slot="header">Glossary</div>
			<div slot="body"><glossary :glossary="glossary"></glossary></div>
		</modal>
	</div>
</template>

<script>
	import Vue2Filters from 'vue2-filters';
	import Modal from './Modal';
	Vue.component('modal', Modal);

	export default {
		mixins: [Vue2Filters.mixin],
		props: ['glossary'],
		data: function () {
			return {
				showModal: false,
				filteredList: this.glossary,
				url: '//' + window.location.hostname + '/',
			};
		},
		created() {},
		methods: {
			getGlossary() {
				self = this;
				var $url = '/glossary/get/none';

				axios
					.get($url)
					.then(function (response) {
						// self.glossary = response.data.glossary;
						self.filteredList = response.data.glossary;
						self.show = false;
					})
					.catch(function (error) {});
			},
		},
		computed: {},
		mounted() {},
	};
</script>
