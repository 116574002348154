import Vue from 'vue';
window.Vue = Vue;

import moment from 'moment';

var datemixin = {
	data: function () {
		return {
			mylist: [],
		};
	},
	methods: {
		formatDate(date) {
			let d = new Date(date);
			let month = '' + (d.getMonth() + 1);
			let day = '' + d.getDate();
			let year = d.getFullYear();

			if (month.length < 2) month = '0' + month;
			if (day.length < 2) day = '0' + day;

			return [year, month, day].join('-');
		},

		// customDisplayDateFormat: function(date) {
		//     var selectedDate = moment(date).format('yyyy MMM DD');
		//     return selectedDate;
		// },
		getCurrentTime() {
			const now = new Date();
			// Format the current time as HH:MM
			return now.toTimeString().slice(0, 5);
		},

		customDateFormat: function (d, format) {
			var date = moment(d);
			return date.isValid() ? date.format(format) : ' ';
		},

		customDisplayDateFormat: function (d) {
			return moment(d).format('YYYY MMM DD');
		},
		customDisplayDate: function (d, format) {
			return moment(d).format(format ? format : 'YYYY MMM');
		},

		customSubmitDateFormat: function (date) {
			var selectedDate = moment(date).format('yyyy MMM DD');
			return selectedDate;
		},

		timeDuration: function (startTime, endTime) {
			const moment1 = moment(startTime);
			const moment2 = moment(endTime);

			if (moment1 > moment2) return;
			const difference = moment2.diff(moment1);

			// Convert the difference into hours, minutes, and seconds
			const duration = moment.duration(difference);

			// Extract hours, minutes, and seconds
			const hours = Math.floor(duration.asHours());
			const minutes = duration.minutes();
			const seconds = duration.seconds();

			// Return the difference as a formatted string
			return `${hours} hours, ${minutes} minutes, ${seconds} seconds`;

			//   return endTime.diff(startTime, 'minutes');

			//   var end = moment(endTime);
			//   var days = startTime.diff(end, 'days');
			//   return days;
			//   var duration = moment.duration(startTime.diff(endTime, 'minutes'));
			//   return duration;
			//   if (endTime > startTime) return moment.duration(endTime.diff(startTime));
			//   else return;
		},
	},
	mounted() {
		const self = this;
		document.addEventListener('keydown', e => {
			if (self.showModal && e.keyCode == 27) {
				s;
				self.showModal = false;
			}
		});
	},
};

export default datemixin;
