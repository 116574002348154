<template id="airports">
	<div class="container-fluid">
		<div class="sticky">
			<search-input placeholder="Find Airport" v-model="filters.name.value" @input="filterData" />
			<div v-show="filters.name.value" class="text-center">
				<subcount>
					<span>Airports {{ totalResults }}</span>
				</subcount>
			</div>
			<v-table :data="airports" :filters="filters" class="table bg-light border">
				<thead slot="head" class="text-center">
					<tr class="text-center">
						<v-th scope="col" sortKey="city" class="clickable">Airport</v-th>
						<!-- <v-th scope="col" sortKey="airport" class="clickable">Airport</v-th> -->
						<v-th scope="col" sortKey="codes" class="clickable">Codes</v-th>
						<v-th scope="col" sortKey="clearance" class="clickable">Clearance</v-th>
						<v-th scope="col" sortKey="country" class="clickable text-center">Domicile</v-th>
					</tr>
				</thead>
				<tbody slot="body" slot-scope="{ displayData }">
					<tr v-for="item in displayData" :key="item.id" class="text-center">
						<td>
							<a :href="url + 'airports/' + item.id + ''">{{ item.city ?? '' }}</a>
						</td>
						<td>{{ item.codes ?? '' }}</td>
						<td>{{ item.clearance ?? '' }}</td>
						<td v-if="item.country_id != '' && item.country" class="text-center">
							<img v-bind:src="url + 'flags/blank.gif'" :class="'flag flag-' + getCountryFlagCode(item.country)" :alt="item.country" :title="item.country" />
							{{ item.country ?? '' }}
						</td>
						<td v-else></td>
					</tr>
				</tbody>
			</v-table>
		</div>
	</div>
</template>

<script>
	import Vue2Filters from 'vue2-filters';

	export default {
		mixins: [Vue2Filters.mixin, './numberformatmixin'],
		props: ['airports'],
		data: function () {
			return {
				section: 'airports',
				currentSort: 'city',
				currentSortDir: 'asc',
				searchQuery: '',
				totalResults: '',
				filteredList: '',
				url: '//' + window.location.hostname + '/',
				filters: {
					name: {
						value: '',
						keys: ['city', 'airport', 'codes', 'clearance', 'country', 'icao_locator'],
					},
				},
			};
		},
		created() {
			this.filteredList = this.airports;
			this.mylist = this.filteredList;

			this.totalResults = this.filteredList.length;
		},
		computed() {
			this.totalResults = this.filteredList.length;
		},
		methods: {
			filterData() {
				this.filteredList = this.airports.filter(item => {
					return this.filters.name.keys.some(key => {
						return item[key] && item[key].toLowerCase().includes(this.filters.name.value.toLowerCase());
					});
				});
				this.totalResults = this.filteredList.length;
			},
		},
		computed: {},
		watch: {
			'filters.name.value': function () {
				this.filterData();
			},
		},
	};
</script>
