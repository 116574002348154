<template id="search-global">
	<div class="searchglobal-container">
		<input class="header-search-box" ref="searchBoxMain" :placeholder="placeholder" @keyup.esc="clearAll()" @focus="openSearch" />

		<span class="input-group-append">
			<button class="btn btn-outline-secondary bg-white border-start-0 border rounded-pill ms-n3" type="button">
				<i class="fa fa-search"></i>
			</button>
		</span>

		<div class="container" @focusout="handleFocusOut" @focus="handleFocus" tabindex="0" @click="close" v-show="showModal">
			<div class="SearchGlobal">
				<transition name="modal">
					<div class="modal-mask">
						<div class="modal-container-search text-left ml-auto mr-auto mt-3" @click.stop>
							<div class="card-header search-profile p-1 stickysearch">
								<input ref="searchBox" v-model="searchQuery" class="form-control" :placeholder="placeholder" @keyup.esc="clearAll()" @focus="handleFocus" />
							</div>

							<template v-if="displayResults == 'active'">
								<div class="search-body bg-white text-dark">
									<div v-if="totalResults >= 0" class="border-bottom-1 p-2">
										<small class="text-extra-small">
											<em>Website search results: {{ totalResults }}</em>
											<span v-if="totalResults > 1000">+</span>
										</small>
									</div>
									<template v-for="item in fetchedResults">
										<div v-if="item.data.length > 0" :key="item.id" class="widen2">
											<div class="widen2 border-bottom-1">
												<div class="row p-2">
													<div class="col-2 col-md-2 col-lg-2 text-capitalize text-small p-2">
														{{ item.section }}
													</div>
													<div class="col-10 col-md-10 col-lg-10">
														<div class="row border">
															<div class="col-4 p-2" v-for="item2 in item.data" :key="item2.id">
																<a :href="url + '' + item.section + '/' + item2.id" :html="item2.name"></a>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</template>
								</div>
							</template>
						</div>
					</div>
				</transition>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue2Filters from 'vue2-filters';

	export default {
		mixins: [Vue2Filters.mixin],
		props: ['section'],
		data: function () {
			return {
				showModal: false,

				form: {
					section: '',
					searchQuery: '',
					q: '',
				},
				currentSort: 'airline',
				displayResults: 'innactive',
				currentSortDir: 'asc',
				searchQuery: '',
				fetchedResults: [],
				section_singular: '',
				searchResults: '',
				totalResults: '',
				placeholder: '',

				url: '//' + window.location.hostname + '/',
			};
		},
		created() {},
		mounted() {
			document.addEventListener('keypress', e => {
				const arrayOfStrings = ['edit', 'manager'];

				var urlpath = window.location.pathname;
				// ";
				let result = urlpath.includes('manager');
				// console.log(result)

				// if (substrings.some(v => str.includes(v))) {
				// // There's at least one
				//     }

				if (urlpath.includes('manager') == false && urlpath.includes('edit') == false) {
					if (e.keyCode == 47) {
						this.openSearch();
					}
				}
			});
		},
		computed: {},
		methods: {
			openSearch: function () {
				const self = this;
				this.showModal = true;
				// self.searchQuery ='';
				// this.$nextTick(() => this.$refs.searchBox.focus() );
				setTimeout(() => {
					self.$refs.searchBox.focus();
				}, 200);
			},
			close: function () {
				//this.$emit('close');
				this.showModal = false;
			},

			clearAll: function () {
				this.fetchedResults = '';
				this.searchResults = '';
				this.searchQuery = '';
				this.displayResults = 'innactive';
			},

			getResults: function () {
				const self = this;
				if (self.searchQuery == '') {
					return;
				}
				if (this.hasSection(self.searchQuery)) {
					var section = self.searchQuery;
					section = section.match(/#[a-z]+/gi); // get the section/hashtag the user is searching
					if (section !== null) section = section[0];
				} else {
					var section = '';
				}

				var q = self.searchQuery;
				var regexp = /#\S+/g;
				q = q.replace(regexp, ''); // remove section/hashtag from the search query.

				self.form.searchQuery = self.searchQuery; // submit the search query
				self.form.section = section; // submit the section
				self.form.q = q; // add the query without the section

				var $url = '/search/1000';

				axios
					.post($url, self.form)
					.then(function (response) {
						self.fetchedResults = response.data.results;
						self.totalResults = response.data.total_result;
						// self.totalResults = self.fetchedResults.total_results;

						self.displayResults = 'active';
						return response.data;
					})
					.catch(function (e) {
						console.log('Error' + e);
					});
			},

			handleFocus() {
				// if @input focus the totalResults >=1, display existing results.
				// This may be better than if searchQuery not empty because some searchQuery may be blank.
				if (this.totalResults >= 1) {
					this.displayResults = 'active';
				}
			},
			handleFocusOut(event) {
				// if the window clik is out of the focus area, hide sarch results.
				//uncomment the lines below on lunch.
				// if (!event.currentTarget.contains(event.relatedTarget)) {
				//     this.displayResults = 'innactive';
				// }
			},

			hasSection: function (inputElem) {
				return /[#\w*]/.test(inputElem.value);
			},
		},
		watch: {
			searchQuery: function () {
				if (this.searchQuery == '') {
					this.fetchedResults = [];
					this.displayResults = 'innactive';
					this.searchQuery = '';
					// this.showModal = false;
				} else {
					this.getResults();
				}
			},
		},
	};
</script>
