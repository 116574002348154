<template id="countries-componenet">
  <div class="general-container">
    <!-- <div class="card" >
                <div class="card-header "> 
                    <input type="text" placeholder="Find Airspace" v-model="searchQuery" class="form-control ">
                </div>
        </div> -->

    <!-- <search-profile section="airspace"></search-profile> -->
    <search-input placeholder="" v-model="filters.name.value"></search-input>

    <subcount>
      Domiciles ({{ number_format(Object.keys(domiciles).length) }})
    </subcount>

    <v-table
      :data="domiciles"
      :filters="filters"
      class="table table-light table-hover border"
    >
      <thead slot="head" class="text-center">
        <tr class="border">
          <v-th scope="col" sortKey="domicile" class="clickable text-left"
            >Domicile</v-th
          >
          <v-th scope="col" sortKey="sovereign" class="clickable text-center"
            >Sovereign</v-th
          >
          <v-th scope="col" sortKey="kind" class="clickable">Kind</v-th>
          <v-th scope="col" sortKey="codes" class="clickable">Codes</v-th>
          <v-th scope="col" sortKey="aircraft_registration" class="clickable"
            >Aircraft</v-th
          >
          <v-th scope="col" sortKey="icao_region" class="clickable"
            >ICAO Region</v-th
          >
        </tr>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr
          v-for="country in displayData"
          :key="country.id"
          class="text-center"
        >
          <td v-if="country.sovereign" class="text-left">
            <img
              v-if="country.sovereign"
              v-bind:src="url + 'flags/blank.gif'"
              :class="'flag flag-' + getCountryFlagCode(country.flag)"
              :alt="country.sovereign"
              :title="country.sovereign"
            />
            &nbsp;
            <span v-if="country.domicile"
              ><a :href="url + 'airspaces/' + country.id + ''">{{
                country.domicile
              }}</a></span
            >
          </td>
          <td v-else></td>
          <td class="text-center">{{ country.sovereign }}</td>
          <td class="text-center">{{ country.kind }}</td>
          <td class="text-center">{{ country.codes ?? '' }}</td>
          <td class="text-center">{{ country.aircraft_registration ?? '' }}</td>
          <td class="text-center">{{ country.icao_region ?? '' }}</td>
        </tr>
      </tbody>
    </v-table>
    <!-- <smart-pagination
      :currentPage.sync="currentPage"
      :totalPages="totalPages"
      :maxPageLinks="16"
    /> -->
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters';

export default {
  mixins: [Vue2Filters.mixin, './numberformatmixin'],
  props: ['domiciles'],
  data: function () {
    return {
      currentSort: 'country',
      currentSortDir: 'asc',
      searchQuery: '',
      filteredList: this.domiciles,
      url: '//' + window.location.hostname + '/',
      filters: {
        name: {
          value: '',
          keys: [
            'domicile',
            'sovereign',
            'airspace',
            'authority',
            'codes',
            'aircraft_registration',
            'icao_locator',
          ],
        },
      },
    };
  },
  created() {
    this.mylist = this.domiciles;
  },
  methods: {
    morethan() {
      return;
    },

    matches(obj) {
      const term = this.searchQuery.toLowerCase();
      return filteredList.toLowerCase().includes(term);
    },
  },
  computed: {
    filteredcountry() {
      if (this.searchQuery == '') {
        // this.$emit('filterChange', this.filteredList)
        return this.filteredList;
      }
      return this.filteredList.filter(function (builder) {
        return country.includes(function (item) {
          return item.country.includes(this.searchQuery);
        });
        // val => String(val).toLowerCase().includes( this.searchQuery ) );  ///filters the word object
      });
    },
  },
};
</script>
