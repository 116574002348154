<template>
	<div class="p-0 m-0">
		<div class="row p-0">
			<div class="col bg-light text-center text-blue-5 p-25">
				<h1 class="t-32 text-blue-5 text-capitalize p-0 m-0">
					<a href="/fleetsflights" class="text-blue-5">Fleets & Flights</a>
				</h1>
				<h2 class="t-20 text-blue-5 text-capitalize p-0 m-0">Search</h2>

				<!-- <FlightsFilters
          @apply-filters="fetchData"
          :filters_fields="filters_fields"
          :results="flights"
          filtersheading="Enter one item"
          :date-limitations="dateLimitations"
          :page="groupBy"
          :showGroupBy="true"
        ></FlightsFilters> -->

				<FlightsFilters @apply-filters="applyFilters" :filters_fields="filters_fields" :results="results" :totalCount="totalCount" filtersheading="Enter one item" :date-limitations="dateLimitations" :page="groupBy" :showGroupBy="false" :filledfields="filled"></FlightsFilters>
			</div>
		</div>
		<div v-if="results.length >= 1 && groupBy == 'fleets'" class="p-0 m-0">
			<fleets-flights-fleets :flights-list="results" />
		</div>
		<div v-if="results.length >= 1 && groupBy == 'flights'" class="p-0 m-0">
			<fleets-flights-flights :flights-list="results" />
		</div>
	</div>
</template>

<script>
	import FlightsFilters from './FlightsFilters';

	import FleetsFlightsFlights from './FleetsFlightsFlights';
	import FleetsFlightsFleets from './FleetsFlightsFleets';

	export default {
		components: {
			FlightsFilters,
			FleetsFlightsFlights,
			FleetsFlightsFleets,
		},
		props: {
			dateLimitations: {
				type: Object,
				default: () => ({}), // Default to an empty object
			},
			page: '',
			plan: String,
			accesslimit: String,
			filled: {
				type: Object,
				default: () => ({}), // Default to an empty object
			},
		},
		data: function () {
			return {
				results: [],
				totalCount: 0,
				currentPage: 1,
				totalPages: 0,

				selectedFlight: [],
				flights_count: 0,
				currentPage: 1,
				totalPages: 0,
				groupBy: '',
				selectedRows: [],

				filters_fields: [
					// { label: 'Date', key: 'date', col: 'col', type: 'date' },

					// {
					//   label: 'Start',
					//   placeholder: 'Takeoff start',
					//   key: 'takeoff_start',
					//   col: 'col',
					//   type: 'datepicker',
					// },

					// {
					//   label: 'Finish',
					//   placeholder: 'Takeoff finish',
					//   key: 'takeoff_finish',
					//   col: 'col',
					//   type: 'datepicker',
					// },

					{
						label: 'Aircraft',
						placeholder: 'ICAO',
						key: 'ac_type_icao',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Serial',
						placeholder: 'msn',
						key: 'ac_msn',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Registration',
						placeholder: 'registration',
						key: 'tail_number',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Airline',
						placeholder: 'name',
						key: 'airline_name',
						secondkey: 'airline_name',
						thirdkey: 'airline_iata',
						col: 'col',
						type: 'string',
					},

					{
						label: 'ICAO',
						placeholder: 'airline',
						key: 'airline_icao',
						col: 'col',
						type: 'string',
						// hidden: 'false',
					},

					{
						label: 'Flight',
						placeholder: 'number',
						key: 'flight_number',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Route',
						placeholder: 'IATA-IATA',
						key: 'route',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Airport',
						placeholder: 'IATA',
						key: 'departure_airport_iata',
						secondkey: '',
						thirdkey: '',
						fourkey: '',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Airspace',
						placeholder: 'ISO-2',
						key: 'departure_airport_country',
						col: 'col',
						type: 'string',
					},
				],
			};
		},
		created() {
			this.groupBy = this.page;
		},
		computed: {},

		mounted() {},

		methods: {
			applyFilters(newFilters) {
				this.filters = newFilters;
				this.fetchData(this.filters, 1);
			},

			displayList() {
				this.fetchData();
			},

			viewFlight(flight) {
				console.log(this.selectedFlight);
			},

			displayField(entry, type) {
				switch (type) {
					case 'date':
						return this.customDateFormat(entry, 'yyyy MMM DD');
						break;

					default:
						return entry;
				}
			},
			fetchData(filter) {
				// Helper function to format date
				// this function is not necessary. We have this.customDateFormat from datemixing. I am just trying helper function to fix a sql bug
				this.groupBy = filter.groupBy;

				function formatDate(dateString) {
					const date = new Date(dateString);
					if (isNaN(date.getTime())) {
						return null;
					} else {
						// Get the time zone offset in minutes and convert it to milliseconds
						const timeZoneOffset = date.getTimezoneOffset() * 60000;
						// Adjust the date by subtracting the time zone offset
						const adjustedDate = new Date(date.getTime() - timeZoneOffset);
						// Convert the adjusted date to ISO string and slice to get YYYY-MM-DD format
						return adjustedDate.toISOString().slice(0, 10);
					}
				}

				// Prepare the filter to be sent
				const activeFilter = {};
				for (const key in filter) {
					if (filter[key]) {
						if (key === 'takeoff_start' || key === 'takeoff_finish') {
							activeFilter[key] = formatDate(filter[key]); // Format date fields
						} else {
							activeFilter[key] = filter[key]; // Add other filters
						}
					}
				}

				// Create query parameters from the active filter
				const queryParams = new URLSearchParams(activeFilter).toString();

				// Check if query parameters are empty (no filters provided)
				if (!queryParams) {
					// console.log('No valid filters provided, not fetching flights.');
					this.results = [];
					this.flights_count = 0;
					return; // Exit the function if no filters are active
				}

				const apiUrl = `/api/fleetsflights/get?${queryParams}`;

				fetch(apiUrl)
					.then(response => {
						if (!response.ok) {
							throw new Error('Network response was not ok');
						}
						return response.json();
					})
					.then(data => {
						this.results = data;

						// this.flights_count = data.length;
					})
					.catch(error => {
						// console.error('Error fetching flights:', error);
						this.results = []; // Reset flights array on error
						this.flights_count = 0; // Reset count on error
					});
			},
		},
	};
</script>
