// import globalData from 'app.js'

import Vue from 'vue';
window.Vue = Vue;

var countrymixin = {
	data: function () {
		return {
			countries: {
				Afghanistan: 'af',
				Albania: 'al',
				Algeria: 'dz',
				Alaska: 'alaska',
				'American samoa': 'as',
				Andorra: 'ad',
				Angola: 'ao',
				Anguilla: 'ai',
				Antarctica: 'aq',
				Antigua: 'ag',
				Argentina: 'ar',
				Armenia: 'am',
				Aruba: 'aw',
				Australia: 'au',
				Austria: 'at',
				Azerbaijan: 'az',
				Azoress: 'azoress',
				Bahamas: 'bs',
				Bahrain: 'bh',
				Balearics: 'balearics',
				Bangladesh: 'bd',
				Barbados: 'bb',
				Belarus: 'by',
				Belgium: 'be',
				Belize: 'bz',
				Benin: 'bj',
				Bermuda: 'bm',
				Bhutan: 'bt',
				Bolivia: 'bo',
				Bonaire: 'bonaire',
				Bosnia: 'ba',
				Botswana: 'bw',
				Brazil: 'br',
				// "Brasil": "br",
				'British Indian Ocean': 'io',
				'British Virgins': 'vg',
				Brunei: 'bn',
				Bulgaria: 'bg',
				'Burkina Faso': 'bf',
				Burundi: 'bi',
				Canaries: 'canaries',
				Cambodia: 'kh',
				Cameroon: 'cm',
				Canada: 'ca',
				'Cape Verde': 'cv',
				'Cayman Islands': 'ky',
				Caymans: 'ky',
				'Central African': 'cf',
				Chad: 'td',
				Chile: 'cl',
				China: 'cn',
				'Christmas Island': 'cx',
				'Cocos Islands': 'cc',
				Colombia: 'co',
				Comoros: 'km',
				Cooks: 'ck',
				'Cook Islands': 'ck',
				'Costa Rica': 'cr',
				Croatia: 'hr',
				Cuba: 'cu',
				Curacao: 'cw',
				Cyprus: 'cy',
				Czech: 'cz',
				Czechia: 'cz',
				'Congo Brazzaville': 'cg',
				'Congo Kinshasa': 'cd',
				Corsica: 'corsica',
				Denmark: 'dk',
				Djibouti: 'dj',
				Dominica: 'dm',
				Dominican: 'do',
				'East Timor': 'tl',
				Easter: 'easter',
				Ecuador: 'ec',
				Egypt: 'eg',
				'El Salvador': 'sv',
				'Equatorial Guinea': 'gq',
				Eritrea: 'er',
				Estonia: 'ee',
				Eswatini: 'eswatini',
				Ethiopia: 'et',
				Europe: 'eu',
				Falklands: 'fk',
				Faroes: 'fo',
				Fiji: 'fj',
				Finland: 'fi',
				Guadeloupe: 'fr',
				France: 'fr',
				'french guiana': 'french-guiana',
				'French Polynesia': 'pf',
				Gabon: 'ga',
				Gambia: 'gm',
				Georgia: 'ge',
				Germany: 'de',
				Ghana: 'gh',
				Gibraltar: 'gi',
				Greece: 'gr',
				Greenland: 'gl',
				Grenada: 'gd',
				Guam: 'gu',
				Guatemala: 'gt',
				Guernsey: 'gg',
				Guinea: 'gn',
				'Guinea Bissau': 'gw',
				Guyana: 'gy',
				Haiti: 'ht',
				Hawaii: 'hawaii',
				Honduras: 'hn',
				'Hong Kong': 'hk',
				Hungary: 'hu',
				Iceland: 'is',
				India: 'in',
				Indonesia: 'id',
				Iran: 'ir',
				Iraq: 'iq',
				Ireland: 'ie',
				'Northern Ireland': 'gb',
				'Isle Of Man': 'im',
				Israel: 'il',
				Italy: 'it',
				'Ivory Coast': 'ci',
				"Cote D'Ivoire": 'ci',
				Jamaica: 'jm',
				Japan: 'jp',
				Jersey: 'je',
				Jordan: 'jo',
				Qazakstan: 'kz',
				Kazakhstan: 'kz',
				Kenya: 'ke',
				Kiribati: 'ki',
				Kosovo: 'xk',
				kurdistan: 'kurdistan',
				Kuwait: 'kw',
				Kyrgyzstan: 'kg',
				Laos: 'la',
				Latvia: 'lv',
				Lebanon: 'lb',
				Lesotho: 'ls',
				Liberia: 'lr',
				Libya: 'ly',
				Liechtenstein: 'li',
				Lithuania: 'lt',
				Luxembourg: 'lu',
				Macau: 'mo',
				Macedonia: 'mk',
				Madagascar: 'mg',
				Madeira: 'madeira',
				Malawi: 'mw',
				Malaysia: 'my',
				Maldives: 'mv',
				Mali: 'ml',
				Malta: 'mt',
				'Marshall Islands': 'mh',
				Marshalls: 'mh',
				Martinique: 'martinique',
				Mauritania: 'mr',
				Mauritius: 'mu',
				Mayotte: 'yt',
				Mexico: 'mx',
				Micronesia: 'fm',
				Moldova: 'md',
				Monaco: 'mc',
				Mongolia: 'mn',
				Montenegro: 'me',
				Montserrat: 'ms',
				Morocco: 'ma',
				Mozambique: 'mz',
				Myanmar: 'mm',
				Namibia: 'na',
				Nauru: 'nr',
				Nepal: 'np',
				Netherlands: 'nl',
				'Netherlands Antilles': 'an',
				'New Caledonia': 'nc',
				'New Zealand': 'nz',
				Nicaragua: 'ni',
				Niger: 'ne',
				Nigeria: 'ng',
				Niue: 'nu',
				'North Korea': 'kp',
				'Northern Marianas': 'mp',
				Norway: 'no',
				Oman: 'om',
				Pakistan: 'pk',
				Palau: 'pw',
				Palestine: 'ps',
				Panama: 'pa',
				'Papua New Guinea': 'pg',
				Paraguay: 'py',
				Peru: 'pe',
				Philippines: 'ph',
				Pitcairn: 'pn',
				Poland: 'pl',
				Portugal: 'pt',
				'Puerto Rico': 'pr',
				Qatar: 'qa',
				Reunion: 're',
				Romania: 'ro',
				Russia: 'ru',
				Rwanda: 'rw',
				Saba: 'saba',
				Sardinia: 'sardinia',
				'St Barthelemy': 'bl',
				'St Helena': 'sh',
				'St Kitts': 'kn',
				'Saint Lucia': 'lc',
				'Saint Martin': 'mf',
				Scotland: 'scotland',
				'St Pierre': 'pm',
				'St Vincent': 'vc',
				Samoa: 'ws',
				'San Marino': 'sm',
				'Sao Tome': 'st',
				'Sao Tome and Principe': 'st',
				'Saudi Arabia': 'sa',
				Senegal: 'sn',
				Serbia: 'rs',
				Seychelles: 'sc',
				'Sierra Leone': 'sl',
				Singapore: 'sg',
				'Sint-Eustatius': 'sint-eustatius',
				'Sint Maarten': 'sx',
				Slovakia: 'sk',
				Slovenia: 'si',
				Solomons: 'sb',
				Somalia: 'so',
				'South Africa': 'za',
				'South Korea': 'kr',
				'South Sudan': 'ss',
				Spain: 'es',
				'Sri Lanka': 'lk',
				Sudan: 'sd',
				Suriname: 'sr',
				'Svalbard and Jan Mayen': 'sj',
				Swaziland: 'sz',
				Sweden: 'se',
				Switzerland: 'ch',
				Syria: 'sy',
				Taiwan: 'tw',
				Tajikistan: 'tj',
				Tanzania: 'tz',
				Tasmania: 'gs',
				Thailand: 'th',
				Togo: 'tg',
				Tokelau: 'tk',
				Tonga: 'to',
				'Trinidad and Tobago': 'tt',
				Trinidad: 'tt',
				Tunisia: 'tn',
				Turkiye: 'tr',
				Turkmenistan: 'tm',
				'Turks & Caicos': 'tc',
				'Turks and Caicos': 'tc',
				Tuvalu: 'tv',
				'U. S. Virgins': 'vi',
				Uganda: 'ug',
				Ukraine: 'ua',
				'United Arab Emirates': 'ae',
				'United Emirates': 'ae',
				'United Kingdom': 'gb',
				'United States': 'us',
				Uruguay: 'uy',
				Uzbekistan: 'uz',
				Vanuatu: 'vu',
				Vatican: 'va',
				Venezuela: 've',
				Vietnam: 'vn',
				'Wallis and Futuna': 'wf',
				'Western Sahara': 'eh',
				Yemen: 'ye',
				Zambia: 'zm',
				Zimbabwe: 'zw',
			},
		};
	},
	methods: {
		getCountryFlagCode: function (country, flag) {
			if (flag != null) {
				return this.$data.countries[country];
			} else if (country == null) {
				return;
			} else if (country == "Cote d'Ivoire") {
				country = 'Ivory Coast';
				return this.$data.countries[country];
			} else {
				// return country.toUpperCase();
				return this.$data.countries[country];
			}
		},
	},
};

export default countrymixin;
