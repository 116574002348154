<template id="utilitieslinks">

  <div>  

    <ul class="nav pt-4 justify-content-center">
        <li class="nav-item"><a class="nav-link" href="#" @click.prevent="openModal('freedoms')" title="Open Freedoms" >Freedoms</a></li>
        <li class="nav-item"><a class="nav-link" href="#" @click.prevent="openModal('marshals')" title="Open Marshal" >Marshals</a></li>
        <li class="nav-item"><a class="nav-link" href="#" @click.prevent="openModal('morse')" title="Open Marshal" >Morse</a></li>
        <li class="nav-item"><a class="nav-link" href="#" @click.prevent="openModal('phonetics')" title="Open Phonetics" >Phonetics</a></li>
    </ul>

 
        <div class="ConvertModal">  
            <transition name="modal" > 
                <div class="modal-mask" @click="close" v-show="showModal">
                    <div class="modal-container-2 text-left" style="overflow-y: auto; border: 1px solid #ddd;"  @click.stop>
                        <div class="row bg-dark-gray text-light p-3">
                            <div class="col-5">
                                <span class="h5" v-show=" option=='freedoms' ">Freedoms</span>
                                <span class="h5" v-show=" option=='marshals' ">Marshals</span>
                                <span class="h5" v-show=" option=='morse' ">Morse</span>
                                <span class="h5" v-show=" option=='phonetics' ">Phonetics</span>
                            </div>
                            <div class="col-6">
                                <strong>AirLibrary</strong>

                            </div>
                            <div class="col-1 text-right">
                                <a href="#" @click="close"><strong>X</strong></a>
                            </div>
                        </div>
                        
                        <div class="modal-body scrollable-lg">


                            <div v-if=" option=='freedoms' " >
                                <freedoms></freedoms> 
                            </div>

                            <div v-if=" option=='marshals' " >
                                <marshals></marshals> 
                            </div>
                            <div v-if=" option=='phonetics' " >
                                <phonetics></phonetics> 
                            </div>
                            <div v-if=" option=='morse' " >
                                <morse></morse> 
                            </div>

                        </div>
                    </div>
                </div>
            </transition>  
        </div>

    </div>   
</template>
 
<script> 

export default{  
    props: [  ],
    data() {
        return {

        todayDate: ''
        , modalOpen: false
        , showModal: false
        , option : false
        };
    }, 
    created () {   
    },
     
    methods: {  
        openModal: function(option){
            const self = this;
            self.modalOpen = true;
            self.option = option;  
            self.showModal = true;  
        }
        , close: function () {
            this.showModal = false;

            
        } 

        , getTodayDate : function() {

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

// let date = new Date(2020, 05, 21); // 2020-06-21

let shortMonth = today.toLocaleString('en-us', { month: 'short' }); /* Jun */


            this.todayDate = yyyy + ' ' + shortMonth + ' ' + dd;

        }
         
    },

    mounted() {
        this.getTodayDate();
    },

      
 
}
  
</script>
