<template id="engines">
  <div class="engineslist">
    <search-input placeholder="" v-model="filters.name.value" />

    <v-table
      :data="engines"
      :filters="filters"
      class="table table-striped-white border mt-15"
    >
      <thead slot="head">
        <v-th sortKey="builder.builder" class="clickable text-center"
          >Builder</v-th
        >

        <v-th sortKey="country.domicile" class="clickable text-left"
          >Domicile</v-th
        >
        <v-th sortKey="kind" class="clickable text-center">Kind</v-th>
        <v-th sortKey="nominal" class="clickable text-center">Type</v-th>
        <v-th sortKey="model" class="clickable text-center">Models</v-th>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }" class="text-center">
        <tr v-for="row in displayData" :key="row.id">
          <td>
            <a :href="url + 'builders/' + row.builder.id">{{
              row.builder.builder
            }}</a>
          </td>
          <td v-if="row.country_id && row.country" class="text-left">
            <img
              v-bind:src="url + 'flags/blank.gif'"
              :class="'flag flag-' + getCountryFlagCode(row.country.domicile)"
              :alt="row.country.domicile"
              :title="row.country.domicile"
            />
            {{ row.country.domicile }}
          </td>
          <td>
            {{ row.kind }}
          </td>
          <td>
            {{ row.nominal }}
          </td>
          <td>
            <a :href="row.id">{{ row.model }}</a>
          </td>
          <!-- <td>
            <a :href="row.operators_count">{{ row.operators_count }}</a>
          </td> -->
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters';

export default {
  mixins: [Vue2Filters.mixin],
  props: ['builders', 'engines'],
  data: function () {
    return {
      // buildersList: this.builders
      // thelist: this.builders,
      searchQuery: '',
      filteredList: this.builders,
      url: '//' + window.location.hostname + '/',
      filters: {
        name: {
          value: '',
          keys: ['model', 'builder.builder', 'builder.country.domicile'],
        },
      },
    };
  },
  created() {},
  methods: {
    morethan() {
      return;
    },

    matches(obj) {
      const term = this.searchQuery.toLowerCase();
      return filteredList.toLowerCase().includes(term);
    },
  },
  computed: {
    filteredEngines() {
      if (this.searchQuery == '') {
        // this.$emit('filterChange', this.filteredList)
        return this.filteredList;
      }
      return this.filteredList.filter(function (builder) {
        return builder.engines_list.includes(function (item) {
          return item.model.includes(this.searchQuery);
        });
        // val => String(val).toLowerCase().includes( this.searchQuery ) );  ///filters the word object
      });
    },
  },
};
</script>
