<template id="AuthPasswordChange">
	<div class="card p-4 border-black text-center">
		<h2>Password</h2>

		<p v-if="errors.length">
			<b>Please correct the following error(s):</b>
			<ul>
			<li v-for="error in errors" :key="error">{{ error }}</li>
			</ul>
		</p> 

		<form method="POST" @submit.prevent="updatePassword"  enctype="multipart/form-data" >
			<div class="form-group">
				<label for="password">Current Password:</label>
				<div class="input-group">
					<input v-if="showPassword" type="text" class="form-control text-center" id="current_password" v-model="form.current_password" placeholder="Your current password" REQUIRED />
					<input v-else type="password" class="form-control text-center" id="current_password" v-model="form.current_password" placeholder="Your current password" REQUIRED />
					<span class="input-group-text" @click="toggleShow">
						<span v-show="showPassword"><i class="fa fa-eye-slash"></i></span>
						<span v-show="!showPassword"><i class="fa fa-eye"></i></span>
					</span>
				</div>
				<div class="text-center"><a class="underline text-sm text-gray-600" href="/account/passwordreset">Reset your password?</a></div>
			</div>

			<div class="form-group">
				<label for="password">New Password:</label>
				<div class="input-group mb-3">
					<input v-if="showPassword" name="password" type="text" v-model="form.password" class="form-control text-center" id="password" placeholder="Your New Password" required="true" />
					<input v-else name="password" type="password" v-model="form.password" value="" class="form-control text-center" id="password" placeholder="Your New Password" required="true" />
					<span class="input-group-text" @click="toggleShow">
						<span v-show="showPassword"><i class="fa fa-eye-slash"></i></span>
						<span v-show="!showPassword"><i class="fa fa-eye"></i></span>
					</span>
				</div>
			</div>

			<div class="form-group">
				<input type="hidden" class="form-control" v-model="form.option" />
				<button type="submit" class="btn btn-primary btn-block" name="formpassword">Update</button>
			</div>
		</form>
	</div>
</template>

<script>
	import _ from 'lodash';
	import Vue2Filters from 'vue2-filters';

	export default {
		mixins: [Vue2Filters.mixin],

		components: {},
		props: ['queries'],
		data() {
			return {
				errors: [],
				form: {
					current_password: '',
					password: '',
					option: 'passwordupdate',
				},
				show: false,
				showPassword: false,
				searchQuery: '',
				filteredList: this.queries,
			};
		},
		created() {},

		watch: {},

		methods: {
			toggleShow() {
				this.showPassword = !this.showPassword;
			},
			updatePassword() {
				var self = this;

				axios.post('account/password/update', self.form).then(function (response) {
					self.show = response.data.message;
					setTimeout(function () {
						self.show = false;
					}, 4000); // hide the message after 3 seconds
				});
			},
		},

		mounted() {
			// var self = this;
			// axios.get('/notes', this.form).then( function(response) {
			//     self.form.query = response.data.queries;
			// });
		},
	};
</script>
