<template id="compare">
	<div class="container tuan123">
		<div class="row">
			<div class="col">
				<div class="container sticky bg-blue-7 text-light">
					<div class="row p-10">
						<div class="col-12 col-md-4 col-lg-4 text-center mx-auto d-sm-block"></div>
						<div class="col-8 col-md-4 col-lg-4 text-center mx-auto">
							<select class="form-control text-center" name="item1" v-model="item1" @change="item1Changed">
								<option>&nbsp;</option>
								<option v-for="item1 in sectionlist" :key="item1.id" :value="item1.id">
									{{ item1[sec_main_field] }}
								</option>
							</select>
						</div>
						<div class="col-8 col-md-4 col-lg-4 text-center mx-auto">
							<select class="form-control text-center" name="item2" v-model="item2" @change="item2Changed">
								<option>&nbsp;</option>
								<option v-for="item2 in sectionlist" :key="item2.id" :value="item2.id">
									{{ item2[sec_main_field] }}
								</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- <div class="row mb-0 pb-0 d-md-block d-lg-none d-xl-block" v-if="item1 != '' && item2 != ''" style="overflow-y: auto">
			<div class="col mb-0 pb-0">
				<div class="text-gray-5 text-center t-22"><i class="fa-solid fa-left-right"></i></div>
			</div>
		</div> -->

		<div class="row pt-0 mt-0" v-if="item1 != '' && item2 != ''" style="overflow-y: auto">
			<div class="col pt-0 mt-0">
				<table class="table">
					<thead class="bg-blue-6 text-light">
						<tr>
							<th class="text-center">Field</th>
							<th class="text-center" v-if="section.section_name === 'Airport'">
								<strong>
									<a :href="url + '' + sec_url + '/' + item1data[0]['id'] + ''" class="text-light">
										{{ item1data[0]['city'] }}
									</a>
								</strong>
							</th>

							<th class="text-center" v-else>
								<strong>
									<a :href="url + '' + sec_url + '/' + item1data[0]['id'] + ''" class="text-light">
										{{ item1data[0][sec_main_field] }}
									</a>
								</strong>
							</th>
							<th class="text-center" v-if="section.section_name === 'Airport'">
								<strong>
									<a :href="url + '' + sec_url + '/' + item2data[0]['id'] + ''" class="text-light">{{ item2data[0]['city'] }}</a>
								</strong>
							</th>
							<th class="text-center" v-else>
								<strong>
									<a :href="url + '' + sec_url + '/' + item2data[0]['id'] + ''" class="text-light">
										{{ item2data[0][sec_main_field] }}
									</a>
								</strong>
							</th>
						</tr>
					</thead>

					<tbody v-for="group in groups" :key="group.id" class="text-blue-7">
						
						<tr v-for="field in group.active_compare_fields" scope="row" v-bind:class="getBackgroundClass(group)" :key="field.id">
							<template v-if="field.field != 'brief' && field.field != 'model' && field.field != 'flag' && field.listed != 1 && !(section.section_name === 'Airspace' && field.field === 'status')" >
								<!-- {{ group.name }} 1 1 1 -->
								<!-- <th style="width: 80px" v-if="group.name == 'Default'" class="text-center"></th> -->
								<!-- <th style="width: 80px" v-else>{{ group.name }}</th> -->
								<th class="text-center w-220px">
									{{ field.title }}
								</th>

								<td v-if="field.field == 'builder_id'" class="text-center">
									{{ item1data[0].builder.builder }}
								</td>
								<td v-else-if="field.type == 'domicile' || field.title == 'Domicile'" class="text-center">
									<div v-if="item1data[0].country">
										<img v-bind:src="url + 'flags/blank.gif'" :class="'flag flag-' + getCountryFlagCode(item1data[0].country.domicile)" :alt="item1data[0].country.domicile" :title="item1data[0].country.domicile" />
										{{ item1data[0].country.domicile }}
									</div>
								</td>
								<td v-else-if="field.type == 'boolean'" class="text-center">
									{{ item1data[0][field.field] == 1 ? 'Yes' : 'No' }}
								</td>
								<td v-else-if="field.field == 'builder_type'" class="text-capitalize text-center">
									<span v-html="item1data[0][field.field]"></span>
								</td>
								<td v-else-if="field.type == 'textarea'" class="text-center">
									<span v-html="item1data[0][field.field]"></span>
								</td>
								<td v-else-if="field.type == 'date'" class="text-center">
									{{ customDisplayDateFormat(item1data[0][field.field]) }}
								</td>
								<td v-else-if="field.field == 'publisher_id'" class="text-center">
									{{ item1data[0].publisher.publisher }}
								</td>
								<td v-else class="text-center">
									{{ item1data[0][field.field] }}
								</td>

								<td v-if="field.field == 'builder_id'" class="text-center">
									{{ item2data[0].builder.builder }}
								</td>
								<td v-else-if="field.type == 'domicile' || field.title == 'Domicile'" class="text-center">
									<div v-if="item2data[0].country">
										<img v-bind:src="url + 'flags/blank.gif'" :class="'flag flag-' + getCountryFlagCode(item2data[0].country.domicile)" :alt="item2data[0].country.domicile" :title="item2data[0].country.domicile" />
										{{ item2data[0].country.domicile }} 
									</div>
								</td>
								<td v-else-if="field.field == 'builder_type'" class="text-capitalize text-center">
									<span v-html="item2data[0][field.field]"></span>
								</td>

								<td v-else-if="field.field == 'publisher_id'" class="text-center">
									{{ item2data[0].publisher.publisher }}
								</td>
								<td v-else-if="field.type == 'boolean'" class="text-center">
									{{ item2data[0][field.field] == 1 ? 'Yes' : 'No' }}
								</td>
								<td v-else-if="field.type == 'textarea'" class="text-center">
									<span v-html="item2data[0][field.field]"></span>
								</td>
								<td v-else-if="field.type == 'date'" class="text-center">
									{{ customDisplayDateFormat(item2data[0][field.field]) }}
								</td>
								<td v-else class="text-center">
									{{ item2data[0][field.field] }}
								</td>
							</template>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue2Filters from 'vue2-filters';
	export default {
		mixins: [Vue2Filters.mixin],
		props: ['section', 'sectionlist', 'fields', 'builders', 'groups', 'firstselected'],
		data() {
			return {
				url: '//' + window.location.hostname + '/',
				item1: '',
				item1data: '',
				item2: '',
				item1image: '',
				item2data: '',
				item2image: '',
				builder1name: '',
				builder2name: '',
				country1name: '',
				country2name: '',
				builder: '',

				sec_main_field: this.section.section_main_field,
				sec_name: this.section.section_name,
				sec_name_plural: this.section.section_name_plural,
				sec_url: this.section.section_url,
			};
		},
		computed: {},
		methods: {
			getBackgroundClass(groups) {
				var color;

				if (groups.group_order % 2) {
					color = 'bg-white';
				} else {
					color = 'bg-light';
				}

				return color;
			},
			filterEngines1() {
				return this.sectionlist.filter(item => {
					return item.id == this.item1;
				});
			},
			filterEngines2() {
				return this.sectionlist.filter(item => {
					return item.id == this.item2;
				});
			},
			item1Changed(items) {
				var id = this.item1;
				this.item1data = this.filterEngines1(this.sectionlist);
				this.item1image = this.item1data[0]['image_path'];
				this.updateBrowserTitle();
			},
			item2Changed(items) {
				var id2 = this.item2;
				this.item2data = this.filterEngines2(this.sectionlist);
				this.item2image = this.item2data[0]['image_path'];

				this.updateBrowserTitle();
			},
			updateBrowserTitle() {
				let item1, item2;
				document.title = 'Compare ';

				if (this.item1data === '' || this.item1data === 'undefined') return;

				item1 = this.item1data[0][this.sec_main_field];
				document.title += ': ' + item1;

				if (this.item2data === '' || this.item2data === undefined) return;

				item2 = ' vs ' + this.item2data[0][this.sec_main_field];

				document.title += ' ' + item2 + ' - AirLibrary';
			},
			groupname(groupId) {
				// let result = this.groups.map(groupId => groupId.id);
				for (var field of this.groups) {
					if (field.id == groupId) return field.name;
				}
			},
		},
		mounted() {
			if (this.firstselected != '') {
				// var id = this.firstselected;
				this.item1 = this.firstselected;
				this.item1Changed();
			}
			axios
				.get('/aircraftdata', this.$data)
				.then(function (response) {
					this.sectionlist = response.data;
					return this.sectionlist;
				})
				.catch(function (error) {
					// console.log('error:' + error.response );
				});
		},
	};
</script>
