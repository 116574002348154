<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div
          class="link-with-tooltip"
          @click="toggleTooltip('cargo')"
          @blur="showCargo = false"
        >
          Air cargo rates
          <div v-if="showCargo" class="tooltip-bubble">
            <slot name="cargo"></slot>
          </div>
        </div>
        &centerdot;

        <div
          class="link-with-tooltip"
          @click="toggleTooltip('currency')"
          @blur="showCurrency = false"
        >
          Currency values
          <div v-if="showCurrency" class="tooltip-bubble">
            <slot name="currency"></slot>
          </div>
        </div>
        &centerdot;

        <div
          class="link-with-tooltip"
          @click="toggleTooltip('fuel')"
          @blur="showFuel = false"
        >
          Jet fuel prices
          <div v-if="showFuel" class="tooltip-bubble">
            <slot name="fuel"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showCargo: false,
      showCurrency: false,
      showFuel: false,
      tooltipContent: null,
    };
  },
  methods: {
    toggleTooltip(tooltip) {
      this.showCargo = tooltip === 'cargo' ? !this.showCargo : false;
      this.showCurrency = tooltip === 'currency' ? !this.showCurrency : false;
      this.showFuel = tooltip === 'fuel' ? !this.showFuel : false;
    },
  },
};
</script>
<style>
.link-with-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-bubble {
  position: absolute;
  /* Adjust based on your needs */
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  padding: 5px 10px;
  border-radius: 6px;
  white-space: nowrap;
  z-index: 1000; /* Ensure it's above other content */
  min-width: 250px;
  text-align: center;
  border: 1px solid #bababd;
}
</style>
