<template id="utilities">
<div> 
    
    <nav class="navbar fixed-bottom navbar-expand-sm navbar-dark bg-dark-blue text-white footer-bar text-left">
       
        <div class="collapse navbar-collapse " id="navbarCollapse">
            <div class="container-fluid bg-dark-blue ">


                    <ul class="navbar-nav justify-content-centerml-auto mr-auto nav-fill w-100">
                 
                        <utilities-menu :naventries="naventries">
                        
                        </utilities-menu> 
                        <!-- <utilities-horizontal-menu></utilities-horizontal-menu>
                        <fuel-info></fuel-info>
                        <date-time></date-time> -->
                    </ul>
                 
            </div> 
        </div> 
    </nav> 

</div> 

</template>
 
<script> 

export default{  
    props: [ "naventries"  ],
    data() {
            return { 
            
        }
    }, 
    created () {   
 

    },
    
    watch: {   
  

    },
    
    methods: {  
        
    },

    mounted() {
       
    },

      
 
}
  
</script>
  