<template>
	<div style="overflow-x: auto;">
		<div class="loading-bar" v-show="isLoading" :style="{ width: loadingWidth + '%' }"></div>
		<table class="airtable table table-striped table-bordered text-center">
			<thead class="bg-blue-5 text-light">
				<tr>
					<th v-for="field in fields" :key="field.key + '-heading'" class="clickable" @click="changeSort(field.key)">
						<div class="no-wrap">
							<span v-if="sortField === field.key">{{ sortOrder === 'asc' ? '↑' : '↓' }}</span>
							<span v-else class="t-16">&varr;</span>
							{{ field.label }}
						</div>
					</th>
				</tr>
			</thead>
			<tbody>
				<!-- Special Count Row -->
				<tr class="table-info" v-if="enableCounts">
					<td v-for="field in fields" :key="field.key + '-count'">
						{{ countRow[field.key] || '-' }}
					</td>
				</tr>
				<!-- Data Rows -->
				<tr v-for="item in data" :key="(item.index ?? Math.random(1000000)) + '-row'" @click="selectRow(item)" :class="{ 'bg-light': selectedItem === item }">
					<td v-for="field in fields" :key="field.key + '-field'">
						<span v-if="['url', 'domicile', 'airline', 'no-wrap'].includes(field.type)" v-html="formatField(item, field)"></span>
						<span v-else>{{ formatField(item, field) }}</span>
					</td>
				</tr>
			</tbody>
		</table>

		<!-- Pagination Controls -->
		<nav aria-label="Page navigation example" v-if="totalPages > 1">
			<ul class="pagination">
				<li class="page-item" :class="{ disabled: currentPage <= 1 }">
					<a class="page-link arrow" href="#" @click="changePage(currentPage - 1)">&#8249;</a>
				</li>

				<li class="page-item" :class="{ disabled: currentPage >= totalPages }" v-if="currentPage >= pageWindowSize">
					<a class="page-link" href="#" @click="changePage(currentPage - pageWindowSize)">...</a>
				</li>
				<li v-for="page in paginatedPages" :key="page" class="page-item" :class="{ active: page === currentPage }">
					<a class="page-link" href="#" @click="changePage(page)">{{ page }}</a>
				</li>
				<li class="page-item" :class="{ disabled: currentPage >= totalPages }" v-if="currentPage <= totalPages">
					<a class="page-link" href="#" @click="changePage(currentPage + pageWindowSize)">...</a>
				</li>
				<li class="page-item" :class="{ disabled: currentPage >= totalPages }">
					<a class="page-link arrow" href="#" @click="changePage(currentPage + 1)">&#8250;</a>
				</li>
			</ul>
		</nav>
	</div>
</template>

<script>
	export default {
		props: {
			fields: Array,
			data: Array,
			enableCounts: Boolean,
			countRow: Object,
			totalCount: Number,
			totalPages: Number,
			sortFieldInput: String,
			sortOrderInput: String,
			countData: {},
		},

		data() {
			return {
				selectedItem: null,
				isLoading: false,
				loadingWidth: 0,

				currentPage: 1,
				selectedItem: null,

				isLoading: false,
				loadingWidth: 0,

				pageWindowSize: 8,
				sortField: '',
				sortOrder: '',
			};
		},

		created() {
			this.sortField = this.sortFieldInput;
			this.sortOrder = this.sortOrderInput;
		},
		computed: {
			paginatedPages() {
				let startPage = Math.max(1, this.currentPage - Math.floor(this.pageWindowSize / 2));
				startPage = Math.min(startPage, Math.max(1, this.totalPages - this.pageWindowSize + 1));
				return Array.from({ length: Math.min(this.pageWindowSize, this.totalPages) }, (v, i) => i + startPage);
			},
		},
		methods: {
			changePage(newPage) {
				if (newPage < 1 || newPage > this.totalPages || newPage === this.currentPage) {
					return;
				}
				this.currentPage = newPage;
				this.$emit('page-changed', newPage);
			},

			selectRow(item) {
				this.selectedItem = item;
				this.$emit('rowSelected', item);
			},

			startLoading() {
				this.isLoading = true;
				this.loadingWidth = 10; // Initial width

				const interval = setInterval(() => {
					if (this.loadingWidth < 90) {
						this.loadingWidth += 5;
					}
				}, 100);

				this.loadingInterval = interval;
			},
			stopLoading() {
				clearInterval(this.loadingInterval);
				this.loadingWidth = 100;
				setTimeout(() => {
					this.isLoading = false;
					this.loadingWidth = 0;
				}, 300);
			},

			changeSort(field) {
				if (this.sortField === field) {
					this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
				} else {
					this.sortField = field;
					this.sortOrder = 'asc';
				}
				// Emit both sortField and sortOrder
				this.$emit('sort-changed', {
					sortField: this.sortField,
					sortOrder: this.sortOrder,
				});
			},

			/**
			 * Formats the field value based on its type and key.
			 * @param {Object} item - The data item containing the field.
			 * @param {Object} field - The field definition with key, label, and type.
			 * @returns {String} - The formatted field value.
			 */
			formatField(item, field) {
				const value = item[field.key];

				switch (field.type) {
					case 'date':
						if (field.key === 'finish_date') {
							return value ? this.customDisplayDate(value, 'YYYY MMM DD') : 'Present';
						}
						return value ? this.customDisplayDate(value, 'YYYY MMM DD') : '';
						break;

					case 'number':
						return value ? Number(value).toLocaleString() : '';

					case 'url':
						return value ? `<a href="${value}" target="_blank" rel="noopener noreferrer">${value}</a>` : '';
						break;

					case 'no-wrap':
						return value ? `<div class="no-wrap">${value}</div>` : '';
						break;

					case 'airline':
						// console.log(item['airline']);
						// console.log(field.key);
						// console.log(item.updateable_id);
						if (this.isLoggedIn) return value ? `<div class="no-wrap"><a href="airlines/${item.airline_id}" target="_blank" rel="noopener noreferrer">${value}</a></div>` : '';
						else return value;
						break;

					case 'domicile':
						const flagCode = this.getCountryFlagCode(item.country.domicile, item.country.flag);
						if (this.isLoggedIn) {
							return `
							<div class="no-wrap">
						<img
							src="${this.url}flags/blank.gif"
							class="flag flag-${flagCode}"
							alt="${item.country.domicile}"
							title="${item.country.domicile}"
						/>
						<a href="airspaces/${item.country_id}" target="_blank" rel="noopener noreferrer">${item.country.domicile}</a>
						</div>
						`;
						} else {
							return `
						<img
							src="${this.url}flags/blank.gif"
							class="flag flag-${flagCode}"
							alt="${item.country.domicile}"
							title="${item.country.domicile}"
						/>
						${item.country.domicile}
						`;
						}
						break;

					case 'string':
					default:
						return value || '';
				}
			},

			getNestedValue(obj, path) {
				return;
			},
		},
	};
</script>

<style scoped>
	.clickable {
		cursor: pointer;
	}

	.loading-bar {
		height: 4px;
		background-color: #3498db;
		transition: width 0.5s ease;
	}

	table {
		table-layout: auto;
		width: 100%;
	}
	.no-wrap {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 200px;
		display: inline-block;
	}
</style>
