<template>
	<div class="container-fluid">
		<div class="row p-0">
			<div class="col bg-light text-center text-blue-5 pl-25 pr-25">
				<div v-if="fleetList">
					<AirTable :fields="tableFields" :data="results" :totalPages="totalPages" :totalCount="totalCount" :currentPage="currentPage" :sortField="sortField" :sortOrder="sortOrder" :enableCounts="true" :countRow="countResults" @rowSelected="viewRecordDetails" @page-changed="fetchData(filters, $event)" @sort-changed="handleSortChange" ref="airTableRef" />
				</div>

				<div class="sep"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import AirTable from './AirTable';
	import FleetsFilters from './FleetsFilters';
	import FlightsFilters from './FlightsFilters';
	import FleetsFlightsTable from './FleetsFlightsTable';
	import FlightDetails from './FlightDetails';
	export default {
		components: {
			AirTable,
			FleetsFilters,
			FlightsFilters,
			FleetsFlightsTable,
			FlightDetails,
		},
		props: {
			plan: String,
			accesslimit: String,
			filled: {
				type: Object,
				default: () => ({}), // Default to an empty object
			},
		},
		data() {
			return {
				results: [],
				countResults: {},
				fetchedResults: {},
				filters: {},

				totalCount: 0,
				currentPage: 1,
				totalPages: 0,
				sortField: 'ac_msn',
				sortOrder: 'asc',

				pageWindowSize: 10,
				abortController: null,
				loadingCounts: false,

				// below handles the click to display or hide list or profile
				fleetList: true,
				groupBy: 'fleets',
				selectedFlight: {},
				flightsProfile: false,

				dateLimitations: {
					minDate: '',
					maxDate: '',
				},
				url: '//' + window.location.hostname + '/',
			};
		},
		async created() {
			const plan = this.plan;

			const now = moment();
			let minDate = moment();
			let maxDate = moment();

			minDate = this.accesslimit;

			this.dateLimitations = {
				minDate: minDate,
				maxDate: maxDate.format('YYYY-MM-DD'),
			};
		},
		computed: {
			tableFields() {
				return [
					{ key: 'ac_type_icao', label: 'Aircraft' },
					{ key: 'ac_msn', label: 'Serial' },
					{ key: 'tail_number', label: 'Registration' },
					{ key: 'airline_name', label: 'Airline' },
					{ key: 'codes', label: 'Codes' },
					{ key: 'route', label: 'Routes' },
					{ key: 'flights', label: 'Flights' },
					{ key: 'fh_avg', label: 'FH avg' },
					{ key: 'fh_sum', label: 'FH sum' },
				];
			},
		},
		methods: {
			applyFilters(newFilters) {
				this.filters = newFilters;
				this.fetchData(this.filters, 1);
			},
			fetchData(filters, page) {
				this.$refs.airTableRef.startLoading();

				if (this.abortController) {
					this.abortController.abort();
				}
				this.abortController = new AbortController();
				let signal = this.abortController.signal;

				const queryParams = new URLSearchParams({
					...filters,
					page: page,
					sortField: this.sortField,
					sortOrder: this.sortOrder,
				}).toString();

				axios
					.get(`/api/fleetsroutes/routes/data?${queryParams}`, { signal })
					.then(response => {
						this.results = response.data.results;
						this.totalPages = response.data.totalPages;
						this.totalCount = response.data.totalCount;
						this.currentPage = page;

						this.handleResults();

						if (page === 1) {
							this.fetchCounts(queryParams);
						}
						this.$refs.airTableRef.stopLoading();
					})
					.catch(error => {
						if (axios.isCancel(error)) {
							console.log('Request canceled', error.message);
						} else {
							console.error('Error:', error);
						}
						this.$refs.airTableRef.stopLoading();
					});
			},

			fetchCounts(queryParams) {
				const self = this;
				self.loadingCounts = true;
				axios
					.get(`/api/fleetsroutes/routes/counts?${queryParams}`)
					// .get(`/api/aircraftfleets/counts?icaoCode=${icaoCode}`)
					.then(response => {
						self.countResults = response.data;
						self.loadingCounts = false;
					})
					.catch(error => {
						console.error('Error fetching counts:', error);
						self.loadingCounts = false;
					});
			},

			handleSortChange({ sortField, sortOrder }) {
				this.sortField = sortField;
				this.sortOrder = sortOrder;
				this.fetchData(this.filters, this.currentPage);
			},
			navigate(page) {
				this.$emit('pageChanged', page); // Emit only the page number
			},
			handleResults() {
				this.fetchedResults.results = this.results;
				this.fetchedResults.totalPages = this.totalPages;
				this.fetchedResults.totalCount = this.totalCount;
				this.fetchedResults.currentPage = this.currentPage;
				this.$emit('handleResults', this.fetchedResults); // Emit only the page number
			},

			close() {
				this.selectedFlight = [];
				this.fleetList = true;
				this.flightsProfile = false;
			},
			viewRecordDetails(result) {},
		},
	};
</script>
