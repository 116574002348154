<template>
	<div class="container-fluid p-0 m-0">
		<fleets-flights-table :flights-list="flightsList" :details_fields="details_fields" />
	</div>
</template>

<script>
	import FleetsFlightsTable from './FleetsFlightsTable';
	import AirTable from './AirTable';

	export default {
		components: { FleetsFlightsTable, AirTable },
		props: ['flightsList'],
		data: function () {
			return {
				details_fields: [
					// {
					//   label: 'Start',
					//   placeholder: 'Date flight started',
					//   key: 'takeoff_time',
					//   col: 'col',
					//   type: 'date',
					// },
					// {
					//   label: 'Finish',
					//   placeholder: 'Date flight finish',
					//   key: 'landing_time',
					//   col: 'col',
					//   type: 'date',
					// },

					{
						label: 'Aircraft',
						placeholder: 'Aircraft code',
						key: 'ac_type_icao',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Serial',
						placeholder: 'Serial',
						key: 'ac_msn',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Registration',
						placeholder: 'Aircraft Registration',
						key: 'tail_number',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Airline',
						placeholder: 'Airline name',
						key: 'airline_name',
						col: 'col',
						type: 'string',
					},

					{
						label: 'ICAO',
						placeholder: 'Airline',
						key: 'codes',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Flight',
						placeholder: 'Airline name or code',
						key: 'flight_number',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Route',
						placeholder: 'Route',
						key: 'route',
						col: 'col',
						type: 'string',
					},

					// {
					//   label: 'Airspace',
					//   placeholder: 'Airspace',
					//   key: 'airspace',
					//   col: 'col',
					//   type: 'string',
					// },

					{
						label: 'Flights',
						placeholder: 'Flights',
						key: 'freq',
						col: 'col',
						type: 'string',
					},

					{
						label: 'FH avg',
						key: 'hours',
						col: 'col',
						type: 'string',
					},
					{
						label: 'FH sum',
						key: 'fh_sum',
						col: 'col',
						type: 'float',
					},
				],
			};
		},
		created() {},
		computed: {},

		mounted() {},

		methods: {},
	};
</script>
