// import globalData from 'app.js'

import Vue from 'vue'
window.Vue = Vue
 
var sortingmixin = { 
    data: function() {
        return {
            mylist: []
        }
    },
    methods: {

        sortIt: function(field) {
            var self = this;
            this.currentSort = field;
            var form = {
                section : self.section
                , sortby : self.currentSort
                , sort : self.currentSortDir
            }

            var $url = "/"+self.section+"/sort";
             
             axios.post( $url,  form )
             .then(function(response){
                     self.filteredList = response.data.items;
                     self.currentSort = response.data.sortby;
                     self.currentSortDir = response.data.sort; 


             })
             .catch( function(error){  
             });  
        },
        sorting: function(s) {
  
            //if s == current sort, then reverse
            if(s === this.currentSort) {
                this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
            }
            //alert('sort: '+ s);
            this.currentSort = s; 
             
            return this.mylist.sort((a,b) => {
                let modifier = 1;
                if(this.currentSortDir === 'desc') modifier = -1;
                if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
 
                return 0;
            });
        }




        
 
    }
}


export default sortingmixin