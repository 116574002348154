<template id="bulletin-button">
	<div :id="id" class="container">
		<div v-if="form.bulletin_subscribed == 1" class="text-center">
			<!-- <div v-if="form.bulletin_subscribed_at">
        <a
          class="btn btn-primary not-rounded"
          href="#"
          @click.prevent="bulletinSubscription"
          >Deactivate
        </a>
      </div> -->
			<!-- {{ customDisplayDateFormat(form.bulletin_subscribed_at) }}-->
			<!-- <a href="/account" class=""><small>Activate Bulletin</small></a> -->
			<!-- <button class="btn-sm text-sm bg-success-light rounded-0  mx-auto border-0
                t-20 btn btn-sm rounded-0 btn-primary border-black  text-light btn-sm pl-4 pr-4 pt-1 pb-1 w-200px
                " style=" color: #942192; ">De-Activate</button> -->
		</div>
		<div v-else-if="form.bulletin_unsubscribed_at">
			<div class="mb-2" v-if="form.bulletin_unsubscribed_at">
				<!-- Inactive: {{ customDisplayDateFormat(form.bulletin_unsubscribed_at) }} -->
			</div>
			<a href="#" @click.prevent="bulletinSubscription" class="btn btn-primary not-rounded">
				Activate
			</a>
		</div>

		<div v-else>
			<a href="#" @click.prevent="bulletinSubscription" class="btn btn-primary not-rounded">
				Activate Bulletin
			</a>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['user', 'id'],
		data: function () {
			return {
				errors: [],

				form: {
					bulletin_subscribed: '',
					bulletin_subscribed_at: '',
					bulletin_unsubscribed: '',
					bulletin_unsubscribed_at: '',
					option: '',
				},

				showModal: false,
				modalTitle: '',

				url: '//' + window.location.hostname + '/',
			};
		},
		created() {
			this.form.bulletin_subscribed = this.user.bulletin_subscribed;
			this.form.bulletin_subscribed_at = this.user.bulletin_subscribed_at;
			this.form.bulletin_unsubscribed = this.user.bulletin_unsubscribed;
			this.form.bulletin_unsubscribed_at = this.user.bulletin_unsubscribed_at;
		},

		watch: {},
		computed: {},
		methods: {
			openPopup(option) {
				this.option = option;
				this.showModal = !this.showModal;
				this.modalTitle = this.option;
			},
			bulletinSubscription() {
				var self = this;

				console.log('here');
				axios.post('/bulletin/processsubscription', self.form).then(function (response) {
					console.log('response');
					self.form.bulletin_subscribed = response.data.user.bulletin_subscribed;
					self.form.bulletin_subscribed_at = response.data.user.bulletin_subscribed_at;
					self.form.bulletin_unsubscribed = response.data.user.bulletin_unsubscribed;
					self.form.bulletin_unsubscribed_at = response.data.user.bulletin_unsubscribed_at;

					setTimeout(function () {
						self.show = false;
					}, 4000); // hide the message after 3 seconds
				});
			},
		},

		mounted() {},
		beforeDestroy() {},
	};
</script>
