// import globalData from 'app.js'

import Vue from 'vue'
window.Vue = Vue


var formmixin = { 
    data: function() {
        return {
            mylist: []
        }
    },
    methods: {
 
        resetForm: function(form) {
            const self = this;
            Object.keys(form).forEach(function(key,index) {
                if( key == 'archived' ) {
                    self.form[key] = 0;
                } else if( key == 'status' ) {
                    self.form[key] = 'Active';
                } else {
                    self.form[key] = '';
                }
            }); 
        }, 
 
    }
}


export default formmixin