<template id="frontiers">
	<div class="container-fluid">
		<search-input placeholder="find amount" v-model="searchQuery" />

		<div style="overflow-x: auto">
			<v-table :data="results" :filters="filters" :currentPage.sync="currentPage" :pageSize="50" @totalPagesChanged="totalPages = $event" class="table table-striped-white border fleet-flight-table bg-light" selectedClass="table-info" selectionMode="multiple" @selectionChanged="selectedRows = $event">
				<thead slot="head" class="bg-blue-5 text-light">
					<v-th v-for="field in details_fields" :key="field.key" :class="'clickable text-center w-200px ' + field.col" :sortKey="field.key">
						{{ field.label }}
					</v-th>
				</thead>
				<tbody slot="body" slot-scope="{ displayData }">
					<tr v-for="row in displayData" :key="row.id" :row="row" class="text-center" :class="{ 'bg-gray-2': row.id === 'unique-count-row' }">
						<td v-for="field in details_fields" :key="field.key" :class="field.col">
							<template v-if="field.key === 'price_date'">{{ formatField(row, field) || '—' }}</template>
							<template v-else-if="field.key === 'tonne'">{{ row.tonne ? '$' + Math.floor(row.tonne).toLocaleString() : '—' }}</template>
							<template v-else>{{ formatField(row, field) || '—' }}</template>
						</td>
					</tr>
				</tbody>
			</v-table>

			<smart-pagination :currentPage.sync="currentPage" :totalPages="totalPages" :maxPageLinks="16" />
		</div>
	</div>
</template>

<script>
	import Vue2Filters from 'vue2-filters';

	export default {
		mixins: [Vue2Filters.mixin],
		props: ['results'],
		data: function () {
			return {
				section: 'fuel',
				currentSort: 'price_date',
				currentSortDir: 'desc',
				currentPage: 1,
				totalPages: 0,
				searchQuery: '',
				filteredList: this.results,
				url: '//' + window.location.hostname + '/',
				filters: {
					name: {
						value: '',
						keys: ['price_date', 'tonne', 'price_per_gallon', 'fuel_per_liter'],
					},
				},

				details_fields: [
					{
						label: 'Date',
						placeholder: 'Date',
						key: 'price_date',
						col: 'col',
						type: 'date',
					},

					{
						label: 'Fuel tonne',
						placeholder: 'Tonne',
						key: 'tonne',
						col: 'col',
						type: 'number',
					},

					{
						label: 'Fuel liter',
						placeholder: 'Fuel per liter',
						key: 'fuel_per_liter',
						col: 'col',
						type: 'number',
					},

					{
						label: 'Fuel gallon',
						placeholder: 'Fuel per gallon ',
						key: 'price_per_gallon',
						col: 'col',
						type: 'number',
					},

					{
						label: 'Oil barrel',
						placeholder: 'Oil per barrel',
						key: 'brent_crude_price_per_barrel',
						col: 'col',
						type: 'number',
					},
				],
			};
		},
		computed: {
			formattedResults() {
				return this.results.map(row => ({
					...row,
					formatted_month: format(new Date(row.price_date * 1000), 'MMM'), // Converts timestamp to "MMM" format
				}));
			},
			cleanedFilter: {
				get() {
					return this.searchQuery;
				},
				set(value) {
					this.filters.name.value = value.replace(/[\$,]/g, '').trim();
					this.searchQuery = value;
				},
			},
		},
		methods: {
			applyFilter() {
				if (!this.cleanedFilterValue) {
					this.filteredList = this.results;
					return;
				}

				this.filteredList = this.results.filter(row => {
					return this.filters.name.keys.some(key => {
						if (row[key] != null) {
							let rowValue = row[key].toString();
							return rowValue.includes(this.cleanedFilterValue);
						}
						return false;
					});
				});
			},
		},
		created() {
			this.filteredList = this.results;
		},
		watch: {
			searchQuery(newValue) {
				this.cleanedFilter = newValue;
			},
		},
	};
</script>
