<template id="airlines">
  <div class="container">
    <div class="sticky">
      <!-- <search-profile section="airlines"></search-profile> -->
      <!-- <filter section="airlines"></filter> -->
      <search-input placeholder="Search" v-model="filters.name.value" />

      <v-table
        :data="fleet"
        :filters="filters"
        class="table table-striped-white border mt-15"
      >
        <thead slot="head" class="text-center">
          <v-th sortKey="type" class="col-3 clickable">Builder</v-th>
          <v-th sortKey="type" class="col-3 clickable">Type</v-th>
          <v-th sortKey="models" class="col-5 clickable">Models</v-th>
          <v-th sortKey="total_units" class="col-2 clickable">Fleet</v-th>
          <v-th sortKey="unique_airlines_count" class="col-2 clickable"
            >Operators</v-th
          >
        </thead>
        <tbody slot="body" slot-scope="{ displayData }">
          <tr v-for="row in displayData" :key="row.id" class="text-center">
            <td v-if="row.type">
              <a :href="url + 'builder/' + row.builder_id + ''">{{
                row.builder
              }}</a>
            </td>
            <td v-if="row.type">
              <!-- <a :href="url + 'aircraft/' + row.id + ''">{{ row.type }}</a> -->
              {{ row.type }}
            </td>
            <td v-else class="col"></td>

            <td v-if="row.models">
              <span v-for="model in row.models" :key="model.id">
                <a :href="url + 'aircraft/' + model.id + ''">{{
                  model.model
                }}</a>
                &nbsp;
              </span>
            </td>

            <td v-if="row.total_units">
              {{ row.total_units }}
            </td>
            <td v-else class="col">0</td>

            <td v-if="row.unique_airlines_count">
              {{ row.unique_airlines_count }}
            </td>
            <td v-else class="col">0</td>
          </tr>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters';

export default {
  mixins: [Vue2Filters.mixin],
  props: ['fleet'],
  data: function () {
    return {
      section: 'fleets_alignments',
      currentSort: 'airline.airline',
      currentSortDir: 'asc',
      searchQuery: '',
      totalResults: '',
      filteredList: '',
      url: '//' + window.location.hostname + '/',

      filters: {
        name: {
          value: '',
          keys: ['type', 'total_units', 'unique_airlines_count', 'models'],
        },
      },

      sortKey: 'aircraft',
      reverse: false,
      columns: ['type', 'total_units', 'unique_airlines_count', 'models'],
    };
  },
  created() {
    this.filteredList = this.fleet;
  },
  mounted() {
    this.filteredList = this.fleet;
    this.totalResults = this.fleet.length;
  },
  methods: {},
  computed: {
    countList: function () {
      var n = this.filterBy(this.filteredList, this.searchQuery);
      this.totalResults = n.length;
      return this.totalResults;
    },
  },
  watch: {},
};
</script>
