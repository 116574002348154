<template>
	<div class="container">
		<search-input placeholder="Find Aircraft" v-model="filters.name.value" />

		<subcount>
			<slot></slot>
		</subcount>

		<v-table :data="aircraft" :filters="filters" class="table bg-light text-center">
			<thead slot="head" class="table-head">
				<v-th scope="col" sortKey="aircraft_builders.builder" :sortOrders="['asc', 'desc']" defaultSort="asc">Builder</v-th>
				<v-th scope="col" sortKey="model">Model</v-th>
				<v-th scope="col" sortKey="code">Code</v-th>
			</thead>
			<tbody slot="body" slot-scope="{ displayData }">
				<tr v-for="row in displayData">
					<td>{{ row.aircraft_builders.builder }}</td>
					<td>{{ row.model }}</td>
					<td>{{ row.code }}</td>
				</tr>
			</tbody>
		</v-table>
	</div>
</template>

<script>
	import SearchInput from './SearchInput.vue';
	Vue.component('search-input', SearchInput);

	export default {
		mixins: ['./numberformatmixin'],
		components: {
			SearchInput,
		},
		props: ['aircraft'],
		data() {
			return {
				search: '',
				filters: {
					name: { value: '', keys: ['model', 'aircraft_builders.builder', 'code'] },
				},
				url: '//' + window.location.hostname + '/',
			};
		},
		computed: {},
		mounted() {},
		methods: {
			// handleSearch(value) {
			//   this.search = value;
			//   // Implement your search logic here
			// },
		},
	};
</script>
