<template>
  <div class="mt-25">
    <v-table
      :data="flightsWithUniqueCount"
      :currentPage.sync="currentPage"
      :pageSize="50"
      @totalPagesChanged="totalPages = $event"
      class="table table-hover border fleet-flight-table bg-light"
      selectedClass="table-info"
      selectionMode="multiple"
      @selectionChanged="selectedRows = $event"
    >
      <thead slot="head" class="text-center bg-blue-5 text-light">
        <v-th
          v-for="field in details_fields"
          :key="field.key"
          :class="'clickable bg-blue-6' + field.col"
          :sortKey="field.key"
        >
          {{ field.label }}
        </v-th>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr
          v-for="row in displayData"
          :key="row.id"
          :row="row"
          class="text-center"
          :class="{ 'bg-gray-2': row.id === 'unique-count-row' }"
        >
          <td
            v-for="field in details_fields"
            :key="field.key"
            :class="field.col"
          >
            {{
              displayField(
                row[field.key],
                field.type,
                row.id === 'unique-count-row'
              )
            }}
          </td>
        </tr>
      </tbody>
    </v-table>

    <smart-pagination
      :currentPage.sync="currentPage"
      :totalPages="totalPages"
      :maxPageLinks="16"
    />
  </div>
</template>

<script>
export default {
  components: {},
  props: ['flightsList', 'details_fields'],
  data: function () {
    return {
      selectedFlight: [],
      flights_count: 0,
      currentPage: 1,
      totalPages: 0,
      groupBy: '',
      selectedRows: [],
      flights: [],
    };
  },
  created() {},
  computed: {
    flightsWithUniqueCount() {
      const uniqueCounts = {};
      const sums = {
        hours: 0,
        freq: 0,
        flights: 0,
        totalHours: 0,
        countHours: 0,
        fh_sums: 0,
        takeoff_start: 0, // Assuming these should count occurrences or similar
        takeoff_finish: 0,
      };

      // Initialize uniqueCounts for fields not involving sums
      this.details_fields.forEach(field => {
        if (
          ![
            'hours',
            'freq',
            'flights',
            'takeoff_start',
            'takeoff_finish',
          ].includes(field.key)
        ) {
          uniqueCounts[field.key] = new Set();
        }
      });

      // Process each flight
      this.flightsList.forEach(flight => {
        this.details_fields.forEach(field => {
          if (flight[field.key] !== undefined) {
            if (['takeoff_start', 'takeoff_finish'].includes(field.key)) {
              // Special logic for date fields used in counts
              sums[field.key]++; // Increment if present
            } else if (field.key === 'hours' && flight.hours !== undefined) {
              const hours = parseFloat(flight.hours);
              if (!isNaN(hours)) {
                sums.totalHours += hours;
                sums.countHours++;
              }
            } else if (field.key === 'freq' && flight.freq !== undefined) {
              sums.freq += parseInt(flight.freq, 10);
            } else if (
              field.key === 'flights' &&
              flight.flights !== undefined
            ) {
              sums.flights += parseInt(flight.flights, 10);
            } else {
              uniqueCounts[field.key].add(flight[field.key]);
            }
          }
        });
      });

      // Calculate averages and totals
      let fh_avg =
        sums.countHours > 0
          ? parseFloat((sums.totalHours / sums.countHours).toFixed(1))
          : 0;
      let fh_sum = parseFloat((sums.freq * fh_avg).toFixed(1)); // Calculate as freq * average hours

      // Create a new row with the unique counts and sums
      const uniqueCountRow = { id: 'unique-count-row' };
      this.details_fields.forEach(field => {
        if (field.key === 'hours') {
          uniqueCountRow[field.key] = fh_avg;
        } else if (field.key === 'flights') {
          uniqueCountRow[field.key] = sums.flights;
        } else if (field.key === 'fh_sum') {
          uniqueCountRow[field.key] = fh_sum;
        } else if (field.key === 'freq') {
          uniqueCountRow[field.key] = sums.freq;
        } else if (['takeoff_start', 'takeoff_finish'].includes(field.key)) {
          uniqueCountRow[field.key] = sums[field.key]; // Directly use the count for these fields
        } else {
          uniqueCountRow[field.key] = uniqueCounts[field.key].size; // Use unique count size for other fields
        }
      });

      // Insert the unique count row at the beginning of the flights array
      return [uniqueCountRow, ...this.flightsList];
    },
  },

  mounted() {
    // this.fetchFlights();
  },

  methods: {
    displayList() {
      this.fetchFlights();
    },

    displayField(entry, type, isCountRow = false) {
      if (isCountRow) {
        return entry; // Return the count as is for count rows
      }
      switch (type) {
        case 'date':
          return this.customDateFormat(entry, 'yyyy MMM DD');
        default:
          return entry;
      }
    },
  },
};
</script>
