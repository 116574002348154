<template id="subscribed-canellation">
  <div class="col-12 mb-4 pt-2 text-center">
    <div v-if="response.status == '' || response.status != 'Success'">
      <form method="POST" @submit.prevent="cancenSubscription">
        <button type="submit" class="btn bg-blue-6 text-light btn-block">
          Cancel
        </button>
      </form>
    </div>
    <div v-else class="text-center">
      <h3 class="text-danger">Error during the transaction</h3>
      <br />
      <p>
        <label class="col form-label" for="errorMessage"
          >Status: {{ response.result.errorMessage }}</label
        >
        <br />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['subscription_id'],
  data() {
    return {
      errors: [],
      url: '//' + window.location.hostname + '/',

      form: {
        cancelAfterBillNumber: 1,
      },

      // , ssl_amount : ''
      // , ssl_first_name: this.user.first_name
      // , ssl_last_name: this.user.last_name
      // , ssl_company: this.user.company
      // , card: ''
      // , exp: ''
      // , cvv: ''

      button: 'active',
      response: {
        status: '',
        result: [],
      },
    };
  },
  created() {},

  watch: {},

  methods: {
    cancenSubscription() {
      var self = this;

      var url =
        'https://uat.api.converge.eu.elavonaws.com/subscriptions/' +
        self.subscription_id;

      if (confirm('Do you really want to cancel your subscription?')) {
        axios
          .post(url, self.form)
          .then(function (response) {
            self.response.result = response.data.result;
            self.response.status = response.data.status;

            // self.button = "active";
          })
          .catch(function (error) {
            self.response.result = response.data.result.ssl_result_message;
            self.response.status = response.data.status;
          });
      }
    },
  },

  mounted() {},
};
</script>
