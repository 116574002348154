<template id="builders">
  <div class="container-fluid">
    <search-input placeholder="Find" v-model="filters.name.value" />

    <subcount>
      <slot></slot>
    </subcount>

    <v-table
      :data="results"
      :filters="filters"
      class="table bg-light bordered border"
    >
      <thead slot="head" class="text-center">
        <!-- <thead class=" bg-white"> -->
        <tr>
          <v-th scope="col" sortKey="builder" class="clickable text-center"
            >Builder</v-th
          >
          <v-th scope="col" sortKey="model" class="clickable text-center"
            >Model</v-th
          >
          <v-th scope="col" sortKey="kind" class="clickable text-center"
            >Kind</v-th
          >
        </tr>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr v-for="row in displayData" :key="row.id">
          <td class="text-center">
            <a :href="url + 'builders/' + row.builder_id + ''">{{
              row.builder?.builder || ''
            }}</a>
          </td>
          <td class="text-center">
            <a :href="url + 'engines/' + row.id + ''">{{
              row.model ? row.model : ''
            }}</a>
          </td>
          <td class="text-center text-capitalize">
            {{ row.kind ? row.kind : '' }}
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script>
import SearchInput from './SearchInput.vue';
Vue.component('search-input', SearchInput);

export default {
  mixins: [Vue2Filters.mixin, './numberformatmixin'],

  components: {
    SearchInput,
  },

  props: ['results', 'kind'],
  data: function () {
    return {
      search: '',

      filteredList: this.builders,
      url: '//' + window.location.hostname + '/',
      filters: {
        name: {
          value: '',
          keys: ['builder.builder', 'model'],
        },
      },
    };
  },
  created() {},
  methods: {},
};
</script>
