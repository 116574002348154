<template id="messenger">
 
<div class="notes-container">
    <!-- <a class="nav-link dropdown-toggle btn btn-light text-small my-dropdown-toggle border-radius-0" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" data-offset="10,10">Messenger</a> -->
    <!-- <div class="dropdown-menu dropdown-menu-right p-0 m-0" aria-labelledby="dropdownMenuOffset"> -->
        <form @submit.prevent="sendQuery">
                <div class="form-group" >
                    <h4><label for="Notes">Message to AirLibrary</label></h4>
                </div>
                <div class="form-group">
                    <textarea class="query"  v-model="form.querymessage" ></textarea>
                </div>
                <div class="form-group">
                    <button class="btn btn-primary btn-sm  text-right pt-0 pb-0" ><span class="text-sm t-13">Send</span></button>
                </div>
                <transition name="fade">
                <p v-if=" show " class="text-success text-strong" >
                    Thank you. We received your message.
                </p>
                </transition>
        </form>
    <!-- </div> -->
</div>  

</template>
 
<script> 
import _ from 'lodash';

export default{  
    props: [  ],
    data() {
            return { 
            
            form: {
                querymessage : ''
            },
            show: false
        }
    }, 
    created () {   
 

    },
    
    watch: {   
  

    },
    
    methods: {  

        sendQuery () {
            var self = this;
            
            axios.post('/messenger', self.form).then( function(response) {
                self.form.querymessage = '';
                self.show = response.data.status;
                setTimeout(function() {
                    self.show = false;
                }, 4000); // hide the message after 3 seconds
            }); 
        }
 
        
    },

    mounted() {
        // var self = this;
        // axios.get('/notes', this.form).then( function(response) {
        //     self.form.querymessage = response.data.querymessage;
        // }); 
    },

      
 
}
  
</script>
  