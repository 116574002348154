<template id="engines">
  <div class="container">
    <search-input placeholder="Find Engine" v-model="search" />

    <subcount>
      <slot></slot>
    </subcount>

    <div
      id="searchlist"
      v-for="(builder, index) in filteredEngines"
      :key="builder.id"
      class="container border mb-15 p-2 bg-light"
    >
      <div class="row text-center">
        <div class="col h5 pt-5">
          <img
            v-bind:src="url + 'flags/blank.gif'"
            :class="'flag flag-' + getCountryFlagCode(builder.country.domicile)"
            :alt="builder.country.domicile"
            :title="builder.country.domicile"
          />
          <a :href="url + 'builders/' + builder.id + ''"
            ><strong>{{ builder.builder }}</strong></a
          >
          <span class="text-capitalize small text-dark"
            >({{ Object.keys(builder.engines_list).length }})</span
          >
        </div>
      </div>
      <div class="row pb-5">
        <div class="col">
          <div class="row justify-content-md-center">
            <div
              v-for="engines in builder.engines_list"
              :key="engines.id"
              class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 pt-1 text-center"
            >
              <a :href="url + 'engines/' + engines.id + ''">{{
                engines.model
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters';
import SearchInput from './SearchInput.vue';
Vue.component('search-input', SearchInput);

export default {
  mixins: [Vue2Filters.mixin],
  components: {
    SearchInput,
  },
  props: ['builders', 'engines'],
  data: function () {
    return {
      search: '',
      // buildersList: this.builders
      // thelist: this.builders,
      searchQuery: '',
      buildersList: this.builders,
      url: '//' + window.location.hostname + '/',
    };
  },
  created() {
    // this.countDistinctNominals();
  },
  methods: {
    morethan() {
      return;
    },

    countDistinctNominals() {
      if (Array.isArray(this.engines)) {
        const uniqueNominals = this.engines.reduce((acc, current) => {
          if (!acc.includes(current.nominal)) {
            acc.push(current.nominal);
          }
          return acc;
        }, []);
        this.distinctNominalCount = uniqueNominals.length;
      } else {
        console.error('Engines is not an array:', this.engines);
      }
    },

    matches(obj) {
      const term = this.searchQuery.toLowerCase();
      return builders.toLowerCase().includes(term);
    },
  },
  computed: {
    filteredEngines() {
      if (!this.search) {
        return this.buildersList;
      }
      const searchLower = this.search.toLowerCase();
      return this.buildersList.filter(builder => {
        const builderMatch = builder.builder
          .toLowerCase()
          .includes(searchLower);
        const engineMatch = builder.engines_list.some(engine =>
          engine.model.toLowerCase().includes(searchLower)
        );
        return builderMatch || engineMatch;
      });
    },
  },
  //   watch: {
  //     engines: {
  //       handler(newVal, oldVal) {
  //         console.log('Engines prop changed:', newVal);
  //         this.countDistinctNominals();
  //       },
  //       deep: true,
  //       immediate: true, // This ensures the handler is called immediately after the component is created
  //     },
  //   },
};
</script>
