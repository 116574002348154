<template id="converter-form-2"> 
    <div class="row m-1 border"  v-bind:style="{ backgroundColor: background }" >
        <div class="col-3 col-sm-3 col-md-3 text-center p-1 pt-2 ">
            {{ name }}
        </div>

        <div class="col-3 col-sm-3 col-md-3 text-center p-1 ">
            <input type="text" v-model="valuemetric" placeholder="Metric" class="form-control text-center" v-on:input="convertMetric($event)">
        </div>
        <div class="col-1 col-sm-1 col-md-2 text-left p-1 pt-2 ">
            <span v-html="metricprefix"></span><sup v-if=" sup.length" >{{ sup }}</sup>
        </div>


        <div class="col-3 col-sm-3 col-md-3 text-center p-1 ">
            <input type="text" v-model="valueimperial" placeholder="Imperial" class="form-control text-center" v-on:input="convertImperial($event)">
        </div> 

        <div class="col-2 col-sm-2 col-md-1 text-left p-1 pt-2">
            <span v-html="imperialprefix"></span> <sup v-if=" sup.length" >{{ sup }}</sup>
        </div>
    </div>  
</template>
 
<script> 
 
export default{  
    props: [ 'name', 'description', 'sup', 'bg', 'decimal', 
    'metricprefix', 'imperialprefix', 'factormetric', 'factorimperial'
    ],
    // 'from', 'to', 'factor', 
    data() {
        return { 
              
        valuemetric: ''
        , valueimperial: ''
        , error: '' 
        , background: this.bg
        };
    }, 
    created () {

    },
      
    methods: { 
        showError: function(error) {
            this.$emit('getError', error);
        }
        , convertMetric(e) {
             
            if ( this.isNumber(e) )
            {
                
                if ( this.name == 'Temperature')
                {
                    var metricresult = (this.valuemetric * 1.8) + 32; 
                    this.valueimperial = this.numberWithCommas( metricresult.toFixed(this.decimal) );
                }
                else
                {
                    var metricresult = this.valuemetric * this.factormetric;
                    this.valueimperial = this.numberWithCommas( metricresult.toFixed(this.decimal) );
                }
            }

        }
        , convertImperial(e) {
            
            if ( this.isNumber(e) )
            { 
                
                if ( this.name == 'Temperature')
                {
                    var metricresult = (this.valuemetric - 32 ) / 1.8; 
                    this.valueimperial = this.numberWithCommas( metricresult.toFixed(this.decimal) );
                }
                else
                {
                    var imperialresult = this.valueimperial * this.factorimperial;
                    this.valuemetric = this.numberWithCommas( imperialresult.toFixed(this.decimal) );
                }
            }

        }
        
        
        , numberWithCommas(x) {
            const self = this; 
            self.error = "";
 
            var calc = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

            if( calc === 'NaN' ) {
                self.error = 'Error, please input a valid number.' ;
                this.showError(self.error);
                return "";
            } else {
                this.showError('');
                return calc;
            } 
        }
        , isNumber(e) { 
            e = (e) ? e : window.event;

            var charCode = (e.which) ? e.which : e.keyCode;
 
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                e.preventDefault();;
            } else {
                return true;
            }
        } 
    },

    mounted() {

    },

}

</script>
 