<template id="updates-archive">
	<div class="container-fluid">
		<search-input placeholder="Find Changes" v-model="filters.name.value" />

		<v-table :data="updates" :filters="filters" :currentPage.sync="currentPage" :pageSize="50" @totalPagesChanged="totalPages = $event" class="table bg-light border">
			>
			<thead slot="head" class="text-center">
				<tr>
					<v-th sortKey="date" class="clickable text-center w-10">Date</v-th>
					<v-th sortKey="updateable_type" class="clickable text-center w-10">Kind</v-th>
					<v-th sortKey="entity" class="clickable text-center w-20">Entity</v-th>
					<v-th sortKey="domicile" class="clickable text-left w-20">Domicile</v-th>
					<v-th sortKey="event" class="clickable text-center w-20">Event</v-th>
					<v-th sortKey="action" class="clickable text-center w-20">Subject</v-th>
					<v-th sortKey="object" class="clickable text-center w-20">Change</v-th>
					<v-th sortKey="time" class="clickable text-center w-20">Occur</v-th>
					<!-- <v-th scope="col" @click="sorting('source')" class="clickable" >Source</v-th> -->
				</tr>
			</thead>
			<tbody slot="body" slot-scope="{ displayData }">
				<tr v-for="update in displayData" :key="update.id">
					<td v-if="update.date" class="text-center">
						{{ customDisplayDateFormat(update.date) }}
					</td>
					<td v-else></td>
					<td class="text-center">{{ update.updateable_type ?? '' }}</td>

					<td v-if="update.entity" class="text-center">
						<a :href="url + getPortal(update) + '/' + update.updateable_id + ''">
							{{ update.entity }}
						</a>
					</td>
					<td v-else></td>
					<td v-if="update.country_id" class="text-left">
						<img v-bind:src="url + 'flags/blank.gif'" :class="'flag flag-' + getCountryFlagCode(toTitleCase(update.country.domicile))" :alt="update.country.domicile" :title="update.country.domicile" />
						<a :href="url + 'airspaces/' + update.country.id">
							{{ toTitleCase(update.country.domicile) }}
						</a>
					</td>
					<td v-else></td>
					<td class="text-center">{{ update.event ?? '' }}</td>

					<td class="text-center">{{ update.action ?? '' }}</td>
					<td class="text-center">{{ update.object ?? '' }}</td>
					<td class="text-center">{{ update.time ?? '' }}</td>
					<!-- <th scope="row" class="text-center">{{ customDisplayDateFormat(update.date) }}</th> -->
					<!-- <td v-if=" update.updateable_type ==null "></td> -->
					<!-- <td v-if=" update.source ">{{ update.source}}</td><td v-else></td> -->
				</tr>
			</tbody>
		</v-table>

		<smart-pagination :currentPage.sync="currentPage" :totalPages="totalPages" :maxPageLinks="16" />
	</div>
</template>

<script>
	import Datepicker from 'vuejs-datepicker';
	import moment from 'moment';

	import Vue2Filters from 'vue2-filters';

	export default {
		mixins: [Vue2Filters.mixin],

		components: {
			Datepicker,
		},
		props: ['editid', 'updates', 'airlines_list', 'builders_list', 'aircraft_list', 'engines_list', 'airports_list', 'countries_list', 'lessors_list', 'mros_list'],
		data: function () {
			return {
				errors: [],

				currentPage: 1,
				totalPages: 0,

				form: {
					id: '',
					date: new Date(),
					updateable_type: 'Airline',
					section: { updateable_id: '', entity: '' },
					country_id: '',
					event: '',
					object: '',
					action: 'Open',
					verified: '',
					source: '',
					notes: '',
					_method: '',
					// , archived: 0
					// , image_path: ''
					// , logo_path: ''
					// , diagram_path: ''
				},

				filters: {
					name: {
						value: '',
						keys: ['entity', 'domicile', 'event', 'action', 'object'],
					},
				},

				modalTitle: '',
				showModal: false,
				updateEdit: false,

				currentSort: 'date',
				currentSortDir: 'desc',
				url: '//' + window.location.hostname + '/',

				searchQuery: '',
				filteredList: this.updates,

				submitted: false,
			};
		},
		created() {
			this.mylist = this.updates;

			if (this.editid > 0) {
				this.filteredList = this.updates;
				if (this.filteredList) {
					let item = this.filterBy(this.filteredList, this.editid);
					this.editUpdate(item[0]);
				}
			}
		},

		watch: {},
		methods: {
			matches(obj) {
				const term = this.searchQuery.toLowerCase();
				return filteredList.toLowerCase().includes(term);
			},
			getPortal: function (update) {
				let portal;
				switch (update.updateable_type) {
					case 'Aircraft':
						portal = 'aircraft';
						break;
					case 'Airline':
						portal = 'airlines';
						break;
					case 'Airport':
						portal = 'airports';
						break;
					case 'Builder':
						portal = 'builders';
						break;
					case 'Engine':
						portal = 'engines';
						break;
					case 'Publication':
						portal = 'publications';
						break;
					case 'Organization':
						portal = 'organizations';
						break;
					case 'Event':
						portal = 'events';
						break;
					case 'Frontier':
						portal = 'frontiers';
						break;
					case 'Country':
						portal = 'domiciles';
						break;
					case 'Conversion':
						portal = 'conversions';
						break;
					default:
						portal = 'unknown'; // Changed to a more informative message
				}
				return portal;
			},

			createUpdate: function (event) {
				const self = this;
				self.updateEdit = false;
				self.showModal = true;
				this.submitted = false;
				self.modalTitle = 'Create Change';

				self.resetForm(self.form);

				self.form.date = new Date();

				self.section = {
					updateable_id: this.airlines_list[0].id,
					entity: this.airlines_list[0].airline,
				};
				self.updateable_type = 'Airline';
				self.action = 'Open';
				self.object = '';
			},
			editUpdate: function (update) {
				const self = this;
				self.updateEdit = true;
				self.showModal = true;
				self.submitted = false;

				self.form.id = update.id;
				self.form.updateable_type = update.updateable_type;
				self.form.section = {
					updateable_id: update.updateable_id,
					entity: update.entity,
				};
				self.form.date = update.date;
				self.form.country_name = update.country_name;
				self.form.country_id = update.country_id;
				self.form.event = update.event;
				self.form.action = update.action;
				self.form.object = update.object;
				self.form.action = update.action;
				self.form.verified = update.verified;
				self.form.source = update.source;
				self.form.notes = update.notes;
				self.modalTitle = 'Edit Change';
			},
			saveUpdate: function (event) {
				const self = this;

				this.submitted = true;

				if (!this.checkForm(self)) {
					return false;
				}

				self.form.date = self.customSubmitDateFormat(self.form.date);
				self.form.entity = self.form.section.entity;
				self.form.updateable_id = self.form.section.updateable_id;

				if (self.updateEdit) {
					let $url = '/updates/' + self.form.id;

					self.form._method = 'patch';

					axios
						.post($url, self.form)
						.then(function (response) {
							self.filteredList = response.data.updates;
							self.showModal = false;

							// self.form.diagram_path = "";
							// self.form.logo_path = "";
							// self.form.image_path = "";

							self.resetForm(self.form);

							self.redirect('changes/manager');
						})
						.catch(function (error) {});
				} else {
					let $url = '/updates';

					axios
						.post($url, self.form)
						.then(function (response) {
							self.filteredList = response.data.updates;
							self.showModal = false;

							self.resetForm(self.form);

							self.redirect('changes/manager');
						})
						.catch(function (error) {});
				}
			},
			checkForm: function (self) {
				if (self.form.date && self.form.section.updateable_id && self.form.updateable_type && self.form.country_id && self.form.action && self.form.object) {
					self.errors = [];
					return true;
				}

				self.errors = [];

				if (!self.form.date) {
					self.errors.push('Date required.');
				}
				if (!self.form.section.updateable_id) {
					self.errors.push('Name Required.');
				}
				if (!self.form.updateable_type) {
					self.errors.push('Kind required.');
				}
				if (!self.form.country_id) {
					self.errors.push('Country required.');
				}
				if (!self.form.action) {
					self.errors.push('Action required.');
				}
				if (!self.form.object) {
					self.errors.push('Activity required.');
				}

				self.submitted = false;
			},
			deleteUpdate: function (id) {
				const self = this;
				if (confirm('Do you really want to delete this Update?')) {
					let formData = new FormData();
					formData.append('id', id);

					axios
						.delete('/updates/' + id)
						.then(function (response) {
							self.filteredList = response.data.updates;
							self.showModal = false;
						})
						.catch(function (error) {});
				}
			},
			close: function () {
				this.showModal = false;
			},
			toTitleCase: function (str) {
				let newCapitalCaseWord = str.replace(/\w\S*/g, function (txt) {
					return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
				});

				return newCapitalCaseWord;
			},
			customDisplayDateFormat: function (date) {
				let selectedDate = moment(date).format('yyyy MMM DD');
				return selectedDate;
			},
			customSubmitDateFormat: function (date) {
				let selectedDate = moment(date).format('yyyy MMM DD');
				return selectedDate;
			},
		},
		computed: {},
		mounted() {
			document.addEventListener('keydown', e => {
				if (this.showModal && e.keyCode == 27) {
					this.showModal = false;
				}
			});
		},
	};
</script>
