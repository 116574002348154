<template id="modal-pop-up">
	<transition name="modal">
		<div class="modal-mask" @click="$emit('close')">
			<div @click.stop class="modal-container-2" :style="width ? { width: width } : {}">
				<div class="row">
					<!-- <div class="row > -->
					<div style="position: absolute; right: 2%; margin-top: 10px">
						<a href="#" @click.prevent="$emit('close')" class="text-gray-4 border p-5"><strong>X</strong></a>
					</div>

					<div class="w-100 text-light bg-blue-8 text-center pl-5 pr-5 pt-10 pb-10 t-22 text-capitalize" v-if="header">
						<slot name="header"></slot>
					</div>
					<!-- </div> -->
					<div class="modal-body-wrap pt-20 pl-10 pr-10 pb-10 text-left" style="max-height: 600px; overflow-y: auto">
						<slot name="body"></slot>
					</div>
					<div class="modal-footer" v-if="$slots.footer">
						<slot name="footer"></slot>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	export default {
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			header: {
				type: Boolean,
				default: 1,
			},
			width: {
				type: String,
				default: '50%',
			},
		},
		data() {
			return {};
		},
		created() {},

		watch: {},

		methods: {
			close: function () {
				this.showModal = false;
			},
		},

		mounted() {},
	};
</script>
