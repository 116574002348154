import Vue from 'vue';
window.Vue = Vue;

import moment from 'moment';

var formatfieldmixin = {
	data: function () {
		return {
			url: '//' + window.location.hostname + '/',
			items: [
				// Your data items here
			],
		};
	},
	methods: {
		/**
		 * Formats the field value based on its type and key.
		 * @param {Object} item - The data item containing the field.
		 * @param {Object} field - The field definition with key, label, and type.
		 * @returns {String} - The formatted field value or HTML string.
		 */
		formatURL(url) {
			if (!url) return false;

			const formattedUrl = this.ensureProtocol(url);
			return `<a href="${formattedUrl}" target="_blank" rel="noopener noreferrer"><i class="fas fa-link"></i> ${url}</a>`;
		},
		formatField(item, field) {
			let value;

			// Handle nested keys using dot notation
			if (field.key.includes('.')) {
				value = field.key.split('.').reduce((obj, key) => (obj ? obj[key] : null), item);
			} else {
				value = item[field.key];
			}

			switch (field.type) {
				case 'date':
					if (field.key === 'finish_date') {
						return value ? this.customDisplayDate(value, 'YYYY MMM DD') : 'Present';
					}
					return value ? this.customDisplayDate(value, 'YYYY MMM DD') : '';
					break;
				case 'number':
					if (field.key == 'XBT') return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
					else return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
					return value ? toLocaleString(undefined, { minimumFractionDigits: 2 }) : '';
					break;
				case 'url':
					if (value) {
						const formattedUrl = this.ensureProtocol(value);
						return `<a href="${formattedUrl}" target="_blank" rel="noopener noreferrer"><i class="fas fa-link"></i></a>`;
					}
					return '';
					//return value ? `<a href="${value}" target="_blank" rel="noopener noreferrer">${value}</a>` : '';
					break;
				case 'domicile':
					if (value) {
						const flagCode = this.getCountryFlagCode(value);
						return `
						<div class="no-wrap">
					<img
					  src="${this.url}flags/blank.gif"
					  class="flag flag-${flagCode}"
					  alt="${value}"
					  title="${value}"
					/>
					${value}
					</div>
				  `;
					}
					return '';
					break;
				case 'no-wrap':
					if (value) {
						const flagCode = this.getCountryFlagCode(value);
						return `<div class="no-wrap">${value}</div>`;
					}
					return '';
					break;
				case 'string':
				default:
					return value || '';
			}
		},

		/**
		 * Ensures that a URL starts with 'http://' or 'https://'.
		 * If not, prepends 'http://'.
		 * @param {String} url - The URL to format.
		 * @returns {String} - The formatted URL.
		 */
		ensureProtocol(url) {
			if (!/^https?:\/\//i.test(url)) {
				return `http://${url}`;
			}
			return url;
		},
	},
	mounted() {
		const self = this;
		document.addEventListener('keydown', e => {
			if (self.showModal && e.keyCode == 27) {
				s;
				self.showModal = false;
			}
		});
	},
};

export default formatfieldmixin;
