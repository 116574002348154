<template>
	<div class="bordered bg-light">
		<div class="row">
			<div class="col-1 text-left p-10">
				<button class="btn btn-primary btn-sm" @click="editFlight">Edit</button>
			</div>
			<div class="col-1 text-left p-10">
				<button class="btn btn-danger btn-sm" @click="deleteFlight">Delete</button>
			</div>
			<div class="col text-right">
				<button @click="close" class="t-22 bg-blue-5 text-light text-right border-0">X</button>
			</div>
		</div>
		<!-- Existing flight details table -->
		<div class="text-center t-20 text-blue-6 mb-25">
			<h2 class="p-0 m-0 t-20">Flight</h2>
			<div>{{ flight.flight_number }}</div>
			<div>
				{{ customDateFormat(flight.takeoff_time, 'YYYY MMM DD') }} -
				{{ customDateFormat(flight.landing_time, 'YYYY MMM DD') }}
			</div>
		</div>

		<table class="table text-blue-5">
			<thead class="table-header text-uppercase text-center">
				<th v-for="field in details_fields" :key="field.key" :class="'' + field.col">
					{{ field.label }}
				</th>
			</thead>
			<tbody>
				<tr class="bg-gray-1 text-center" v-if="result">
					<td v-for="field in details_fields" :key="field.key">
						{{ result[field.key] }}
					</td>
				</tr>
			</tbody>
		</table>

		<table class="table text-blue-5 mt-4">
			<thead class="table-header text-uppercase text-center">
				<th>Field</th>
				<th>Value</th>
			</thead>
			<tbody>
				<tr v-for="(value, key) in flight" :key="key" class="text-center">
					<template v-if="!fieldsToSkip.includes(key)">
						<td>{{ key }}</td>
						<td>{{ value }}</td>
					</template>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
	import FlightDetailsTables from './FlightDetailsTables';
	import FlightDetailsAirlinesTables from './FlightDetailsAirlinesTables';
	import FlightDetailsAircraftTables from './FlightDetailsAircraftTables';

	export default {
		components: {
			FlightDetailsTables,
			FlightDetailsAirlinesTables,
			FlightDetailsAircraftTables,
		},
		props: ['flight'],
		data: function () {
			return {
				result: [],
				showModal: false,
				fieldsToSkip: ['id', 'arrival_scheduled_time', 'departure_scheduled_time', 'created_at', 'updated_at', 'ratio'], // List of fields to skip
				details_fields: [
					{ label: 'Date', key: 'date', col: 'col', type: 'date' },

					{
						label: 'Aircraft',
						placeholder: 'Aircraft code',
						key: 'ac_type_icao',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Tail',
						placeholder: 'Aircrarft registration',
						key: 'tail_number',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Airline',
						placeholder: 'Airline name or code',
						key: 'airline_name',
						secondkey: 'airline_icao',
						thirdkey: 'airline_iata',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Route',
						placeholder: 'depart-arrive code',
						key: 'route',
						col: 'col',
						type: 'string',
					},
					{
						label: 'Airport',
						placeholder: 'Depart or arrive code',
						key: 'departure_airport_iata',
						secondkey: 'departure_airport_icao',
						thirdkey: 'arrival_airport_icao',
						fourkey: 'arrival_airport_iata',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Airspace',
						placeholder: 'airspace code',
						key: 'departure_airport_country',
						secondkey: '',
						col: 'col',
						type: 'string',
					},
					{
						label: 'Hours',
						key: 'hours',
						col: 'col',
						type: 'string',
					},
					{
						label: 'Flights',
						key: 'freq',
						col: 'col',
						type: 'string',
					},
					// {
					//   label: 'FH Sum',
					//   key: 'fh_sum',
					//   col: 'col',
					//   type: 'string',
					// },
					// {
					//   label: 'FH avg',
					//   key: 'ah_avg',
					//   col: 'col',
					//   type: 'string',
					// },
					// {
					//   label: 'Ratio',
					//   key: 'ratio',
					//   col: 'col',
					//   type: 'string',
					// },
				],
			};
		},
		created() {
			this.fetchData(this.flight);
			window.addEventListener('keypress', this.close);
		},
		methods: {
			editFlight() {
				this.$emit('editFlight', this.flight);
			},
			deleteFlight() {
				this.$emit('deleteFlight', this.flight);
			},
			close() {
				this.$emit('close');
			},

			fetchData(flight) {
				const self = this;
				if (this.abortController) {
					this.abortController.abort();
				}
				this.abortController = new AbortController();

				axios
					.get(`/api/flight/get/${flight.id}`, {
						signal: this.abortController.signal,
					})
					.then(response => {
						self.result = response.data.results[0];
					})
					.catch(err => {
						if (axios.isCancel(err)) {
							console.log('Request canceled', err.message);
						} else {
							console.error('Error:', err);
						}
					});
			},
		},
	};
</script>
