<template id="compare">
  <div class="container">
    <div class="row pt-2 pb-2">
      <select v-model="selected_section" @change="redirectSection">
        <option
          v-for="section in sections"
          :key="section.name"
          :value="section"
        >
          {{ section.name }}
        </option>
      </select>
    </div>
    <div v-if="selected_section">
      <compare-all></compare-all>
    </div>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters';
export default {
  mixins: [Vue2Filters.mixin],
  props: ['sections'],
  data() {
    return {
      url: '//' + window.location.hostname + '/',
      selected_section: '',
      selected_section_url: '',
      option: '',
    };
  },
  computed: {},
  methods: {
    redirectSection() {
      let self = this;
      // var url = "compare/" + self.selected_sect

      var url = 'compare/' + self.selected_section.url + '/1';
      axios
        .get(url, self.option)
        .then(function (response) {
          this.sectionlist = response.data;
          return this.sectionlist;
        })
        .catch(function (error) {
          // console.log('error:' + error.response );
        });
    },
  },
  mounted() {
    axios
      .get('/aircraftdata', this.$data)
      .then(function (response) {
        this.sectionlist = response.data;
        return this.sectionlist;
      })
      .catch(function (error) {
        // console.log('error:' + error.response );
      });
  },
};
</script>
