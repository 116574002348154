<template id="subscribe">
    
<div class="container-fluid mb-4 ">  
 
    <div class="row ">
        <div id="payment"  style="  display:hidden; "></div>
        
        <div class="bg-blue-5" style="  height: 6px; margin: 0 auto 35px;"></div>

            <div  class="container-fluid mt-5" v-show=" response.status != 'Success' ">
                 
                <div class="row bg-dark-blue-welcome text-gold-4 text-center t-24 mt-0 mb-35 mt-2 p-2  " >
                    <div class="col text-center">
                        Payment
                    </div>
                </div>

                    <div class="row" >
                        <div class="col-12 col-sm-8 col-md-6 col-lg-5 mx-auto" >
                            <form method="POST" @submit.prevent="processCC">
 
                                <h3 class="text-gold-6 text-center p-0 mt-35 mb-20">Plan</h3>

                                <div class=" p-15  m-0 mb-15 text-center text-blue-6 text-capitalize bg-gray-1 t-18" >
                                    {{ selected_plan.description }}  ${{ selected_plan.price/100 }} 
                                </div> 

                                <div class="form-group row text-center p-0  mt-15 mb-15">
                                    <div class="col">
                                        <h3 class="text-gold-6 m-0 text-center">Payment</h3>
                                    </div>  
                                </div>  

                                <p v-if="errors.length">
                                    <b>Please correct the following error(s):</b>
                                    <ul>
                                    <li v-for="error in errors" :key="error">{{ error }}</li>
                                    </ul>
                                </p> 

                                <div class="form-group row p-0  m-0 ">
                                    <input type="text" v-model="ssl_first_name" class="col-6 form-control border-right-1 border-bottom-1 t-16 p-30 bg-blue-1 text-blue-7" placeholder="First Name" />
                                    <input type="text" v-model="ssl_last_name" class="col-6 form-control border-bottom-1 t-16 p-30 bg-blue-1 text-blue-7" placeholder="Last Name"/> 
                                </div>
                            
                                <div class="form-group row p-0  m-0 ">
                                    <input type="tel" :value="card | formatCardNumber" @input="updateCardValue"  class=" form-control border-bottom-1 t-14 p-30 bg-blue-1 text-blue-7" placeholder="1234 1234 1234 1234"/>
                                </div>
                                <div class="form-group row p-0  m-0 ">
                                <input type="text" v-model="exp" class=" form-control border-bottom-1 t-16 p-30 bg-blue-1 text-blue-7" placeholder="MM/YY" /> 
                                </div>
                                <div class="form-group row p-0  m-0 ">
                                <input type="text" v-model="cvv" class=" form-control border-bottom-1 t-16 p-30 bg-blue-1 text-blue-7" placeholder="CVV" > 
                                </div>

                                <div class="form-group row p-0  m-0 text-center">
                                    <slot name="bottomtext"></slot>
                                </div>

                                <div class="form-group row p-0 ">
                                    <div class="col text-center  ml-1 mr-1 p-0">
                                    <input id="gettoken" type="hidden" name="gettoken" value="y" />
                                    <input id="addtoken" type="hidden" name="addtoken" value="y" />
                                    <button type="submit" class="mt-wwww0 btn bg-blue-5 pt-10 pb-10 pr-35 pl-35  text-light rounded" :disabled="button === 'innactive'">Start Trial</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>  
            
 
            <div class="container"  >

                <div class="row" v-if=" response.status ==='Success'  " v-show=" response.status !=''  ">
                    <div class="col-12  mx-auto ">
                        
                        <div class="form-group row bg-light text-gold-6 pl-35 pr-35 p-10 ">
                            <div class="col text-center">
                                <h2>Receipt</h2>
                            </div>
                        </div>

                        <div class="form-group row text-left text-blue-7">
                            <div class="col-6">
                                <h3><strong>Customer</strong></h3>
                                <table class="table table-bordered text-left">
                                    <tbody>
                                        <tr><td class="text-left">First Name:</td><td class="text-center">{{ response.result.ssl_first_name }}</td></tr>
                                        <tr><td class="text-left" >Last Name:</td><td class="text-center">{{ response.result.ssl_last_name }}</td></tr>
                                        <tr><td class="text-left" >Company:</td><td class="text-center" >{{ response.result.ssl_company }}</td></tr>
                                    </tbody>
                                </table>

                            </div>
                            <div class="col-6">
                                <h3><strong>Subscription</strong></h3>
                                <table class="table table-bordered text-left">
                                    <tbody>
                                        <tr><td class="text-left">Plan:</td><td class="text-center">{{ selected_plan.description }}</td></tr>
                                        <tr><td class="text-left" >Amount:</td><td class="text-center">${{ selected_plan.price/100 }}</td></tr>
                                        <tr><td class="text-left" >Receipt:</td><td class="text-center" >{{ response.result.ssl_txn_id }}</td></tr>
                                    </tbody>
                                </table>

                            </div> 
                        </div> 
 
                    </div>
                </div>
                <div class="container" v-else-if=" response.status ==='Error' ">
                    <div class="row">
                        <div  class="col-12 mx-auto text-center">
 
                            <h3 class="text-danger">Error during the transaction</h3>
                            <br>
                            <p>
                                <!-- <label class="col form-label" for="errorMessage">Status: {{ response.result.errorMessage }}</label> <br>     -->
                            </p> 
                        </div> 
                    </div> 
                </div>

                <div class=" pt-5 pb-5 text-center "  v-if=" response.status ==='Success'  " v-show=" response.status !=''  ">

                        <div class="form-group row  bg-blue-5 text-light pl-35 pr-35 pt-5 pb-5 mb-25"  >
                            <div class="col text-center" >
                                Thank you for subscribing to AirLibrary
                            </div> 
                        </div> 

                        <label class="col-12 form-label" for="Status">
                            <a href="/" class="btn bg-blue-5 text-light">Home Page</a>
                        </label> 
                </div>
                <div class="mt-15 text-center text-blue-7 t-18" v-if=" response.status ===''  " v-show=" response.status ==''  ">   
                       

                </div>
           

        </div> 
    </div> 
</div>

</template>
 
<script> 


export default {
    props: [ 'plan', 'plans', 'user' ],
    data() {
        return { 
            errors: [],
            signupform_errors: [],
            url: '//'+window.location.hostname+'/'
             
            , ssl_amount : ''
            , ssl_first_name: this.user.first_name
            , ssl_last_name: this.user.last_name
            , ssl_company: this.user.company
            , card: ''
            , exp: ''
            , cvv: ''
            , plan_id: this.plan.id
            , cycle: this.plan.cycle
            , selected_plan: this.plan

            , form: {
                first_name : ''
                , last_name: ''
                , email:  this.user.email
                , company:  ''
                , password:  ''
            }
            

            , button: 'active'
            , response: {
                status : ''
                , result : []
            },

            todayDate: ''
            , modalOpen: false
            , showModal: false
            , option : false 
        };
    }, 
    created () {   
        this.selected_plan = this.plan;
    },
    
    filters: {
        formatCardNumber(value){
        return value ? value.match(/.{1,4}/g).join(' ') : '';
        } 
    },
    watch: {   
  

    },
    
    methods: {  
         
        updateCardValue(e){
            this.card = e.target.value.replace(/ /g,'');
        }, 

        changePlan() {
            let self = this;

            let selected_plan = this.plans.filter((item) => {
                if( item.id == self.plan_id )
                    return item;
            });
            
            self.selected_plan = selected_plan[0]; 
            self.ssl_amount = selected_plan[0].price/100;

            
            document.getElementById('signup-form').scrollIntoView({
                    behavior: 'smooth' 
            });
             
        },
        getAmount() {
            var self = this;
            let selected_plan = this.plans.filter((item) => {
                return item.id == this.plan_id;
            });
            
            self.ssl_amount = selected_plan[0].price/100;

        },
        processCC() {
            var self = this;
            self.process_message = "innactive"

            if (!this.checkForm(self)){
                return false;
            }

            self.ssl_amount = self.selected_plan.price;
            // alert(self.selected_plan.price);
            // alert(self.selected_plan.price);
            // return 
 
            // var tokenRequest = {
            //     ssl_amount: document.getElementById('ssl_amount').value
            //     , _token:"{{ csrf_token() }}"
            // };

            // $.post("/payments/process", tokenRequest, function (data) {
            //     document.getElementById('token').value = data;
            //     transactionToken = data;
            // });

            // this.getAmount(); // not needed anymore

            let formData = new FormData(); 
 
            var $url = "/subscribe/process"; 
            formData.append('plan_id', self.plan_id );
            formData.append('ssl_first_name', self.ssl_first_name );
            formData.append('ssl_last_name', self.ssl_last_name );
            formData.append('ssl_company', self.ssl_company );
            formData.append('ssl_amount', self.ssl_amount );
            formData.append('card', self.card );
            formData.append('exp', self.exp );
            formData.append('cvv', self.cvv );
            formData.append('cycle', self.cycle );
            // formData.append('_method', 'patch' );  
             
            axios.post( $url,  formData )
            .then(function(response){
                    self.response.result = response.data.result; 
                    self.response.status = response.data.status; 
                     
                    self.button = "active";
                    
                    // self.response.result = response.data.result;
                    // self.response.result = response.data.result.result;
                    // console.log('response:' + response.data);
                    // self.pay(response);
                    // console.log(response.data);
                    // ConvergeEmbeddedPayment.pay(1, 2);
                    // console.log('ddd');
        
            })
            .catch( function(error){  
                self.response.result = response.data.result.ssl_result_message; 
                self.response.status = response.data.status;
            }); 



        }, 
        currencyFormat(amount) {
            // let num = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);

            // amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            //  alert ( );
            return amount.toLocaleString();
        },

        checkForm: function (self) {
            
            if ( self.plan_id && self.ssl_first_name && self.ssl_last_name && self.card && self.cvv  && self.exp    ) {
                self.errors = [];
                return true;
            }

            self.errors = [];

            if (!self.plan_id) {
                self.errors.push('Plan is required.');
            } 
            if (!self.ssl_first_name) {
                self.errors.push('First name is required.');
            } 
            if (!self.ssl_last_name) {
                self.errors.push('Last name is required.');
            }
            
            if (!self.card) {
                self.errors.push('Card is required.');
            }

            if (!self.exp) {
                self.errors.push('Expiration is required.');
            } 
            if (!self.cvv) {
                self.errors.push('CVV code is required.');
            }


        }

        , checkSignUpForm: function (self) {
            
            if (  self.form.first_name && self.form.last_name  && self.form.password ) {
                self.signupform_errors = [];
                return true;
            }

            self.errors = [];
 
            if (!self.form.first_name) {
                self.signupform_errors.push('First name is required.');
            } 
            if (!self.form.last_name) {
                self.signupform_errors.push('Last name is required.');
            }
             
            if (!self.form.password) {
                self.signupform_errors.push('Password is required.');
            }
 

        } 

         
    },
 
      
    mounted() {
 
        // this.changePlan();
    
        
    },

      
 
}
  
</script>