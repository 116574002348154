<template id="trends">
	<div class="container-fluid">
		<search-input placeholder="find trend" v-model="filters.name.value" />

		<div style="overflow-x: auto">
			<v-table :data="results" :filters="filters" class="table table-striped-white bg-light border">
				<thead slot="head" class="text-center bg-blue-5 text-light">
					<tr>
						<v-th scope="col" sortKey="trend_date" class="clickable w-100px text-left">Date</v-th>
						<v-th scope="col" sortKey="topic" class="clickable w-200px text-left">Topic</v-th>
						<v-th scope="col" sortKey="trend" class="clickable w-400px text-left">Trend</v-th>
						<v-th scope="col" sortKey="source" class="clickable w-200px text-left">Source</v-th>
					</tr>
				</thead>
				<tbody slot="body" slot-scope="{ displayData }">
					<tr v-for="item in displayData" :key="item.id">
						<td v-if="item.trend_date" class="text-left pl-10">{{ customDisplayDateFormat(item.trend_date) }}</td>
						<td v-else></td>

						<!-- <td v-if=" item.publication "  class=" text-center " v-html="item.publication" >  </td><td v-else></td>  -->
						<td v-if="item.topic" class="text-left">
							<a :href="url + 'directories/trends/' + item.id + ''" v-html="item.topic"></a>
						</td>
						<td v-else></td>
						<td v-if="item.trend" class="text-left">
							<a :href="url + 'directories/trends/' + item.id + ''" v-html="item.trend"></a>
						</td>
						<td v-else></td>
						<td v-if="item.source" class="text-left">
							<!-- <a :href="url + 'directories/trends/' + item.id + ''" v-html="item.source"></a> -->
							<a :href="item.link.startsWith('http') ? item.link : 'http://' + item.link" target="_blank" v-html="item.source"></a>
						</td>
						<td v-else></td>
					</tr>
				</tbody>
			</v-table>
		</div>
	</div>
</template>

<script>
	import Vue2Filters from 'vue2-filters';

	export default {
		mixins: [Vue2Filters.mixin, './stringmixin'],
		props: ['results'],
		data: function () {
			return {
				currentSort: 'trend_date',
				currentSortDir: 'desc',
				searchQuery: '',
				totalResults: '',
				filteredList: '',
				url: '//' + window.location.hostname + '/',
				filters: {
					name: {
						value: '',
						keys: ['trend_date', 'source', 'publication', 'topic', 'trend'],
					},
				},
			};
		},
		created() {
			this.filteredList = this.results;
		},
		mounted() {
			this.filteredList = this.results;
			this.totalResults = this.results.length;
		},
		methods: {
			morethan() {
				return;
			},

			matches(obj) {
				const term = this.searchQuery.toLowerCase();
				return filteredList.toLowerCase().includes(term);
			},

			filteredTrends: function () {
				if (this.searchQuery == '') return;

				const self = this;

				let formData = new FormData();

				formData.append('searchQuery', this.searchQuery);

				var $url = '/trends/filter';
				formData.append('_method', 'post');

				axios
					.post($url, formData)
					.then(function (response) {
						self.filteredList = response.data;
						return;
					})
					.catch(function (error) {});

				if (this.searchQuery == '') {
					return this.filteredList;
				}
			},
		},
		computed: {
			countList: function () {
				var n = this.filterBy(this.filteredList, this.searchQuery);
				this.totalResults = n.length;
				//  this.totalResults = Object.keys(this.filteredList).length; // this.filteredList.length;
			},
			// ,
			// filteredTrend() {
			//     if ( this.searchQuery =="" ) {
			//         return this.filteredList;
			//     }
			//     return this.filteredList.filter(function(builder) {
			//         return trend.includes(function(item) {
			//             return item.trend.includes(this.searchQuery);
			//         });
			//     });
			// }
		},
	};
</script>
