<template id="airlines">
	<div class="container-fluid">
		<div class="row guter-0">
			<div class="col p-0">
				<div class="p-2 mb-3 bg-gray-10 text-light text-center h5" style="line-height: 1.95rem">Fleet Summary ({{ fleet.length }})</div>

				<v-table :data="fleet" class="table table-striped border">
					<thead slot="head" class="table-head text-center">
						<!-- <v-th sortKey="airline.airlne" class="w-20">Airline</v-th> -->
						<!-- <v-th sortKey="airlne.iata" class="w-20">Codes</v-th> -->
						<v-th sortKey="aircraft.model" class="w-20">Aircraft</v-th>
						<v-th sortKey="aircraft.code" class="w-20">Code</v-th>
						<v-th sortKey="variants" class="w-20">Variant</v-th>
						<v-th sortKey="engines.model" class="w-20">Engines</v-th>
						<v-th sortKey="configuration" class="w-20">Cabin</v-th>
						<v-th sortKey="units" class="w-20">Units</v-th>
					</thead>
					<tbody slot="body" slot-scope="{ displayData }">
						<tr v-if="Object.keys(fleet).length === 0">
							<td colspan="7" class="text-center">No fleet</td>
						</tr>
						<tr v-for="row in displayData" :key="row.index" class="text-center">
							<!-- <td class="text-center">{{ row.airline.airline ?? '' }}</td> -->
							<!-- <td class="text-center">{{ row.airline.iata ?? '' }} {{ row.airline.icao ?? '' }}</td> -->
							<td class="text-center">
								{{ row.aircraft.model ?? '' }}
							</td>
							<td class="text-center">{{ row.aircraft.code ?? '' }}</td>
							<!-- <div @click="filterUnitsByCode(row.aircraft.code)" class="px-2 p-0 filter-item" :class="{ 'active-filter': row.aircraft.code === selectedCode }"></div> -->
							<td class="text-center">{{ row.variants ?? '' }}</td>
							<td class="text-center">{{ row.engines.model ?? '' }}</td>
							<td class="text-center">{{ fleetCabin(row.configuration) }}</td>
							<td class="text-center">{{ row.units ?? '' }}</td>
						</tr>
					</tbody>
				</v-table>
			</div>
		</div>
		<!-- <div id="fleet-units" class="anchor"></div> -->
		<!-- 
		<div class="row guter-0" v-if="this.airline.status != 'Dormant'">
			<div class="col p-0">
				<div class="p-2 mb-3 bg-gray-10 text-light text-center h5" style="line-height: 1.95rem">
					Fleet Units ({{ fleetsfilteredcount }})
					<br />
				</div>
				
				<v-table :data="fleetsfiltered" class="table table-striped border">
					<thead slot="head" class="table-head text-center">
						<v-th sortKey="aircraft_model" class="w-20">Aircraft</v-th>
						<v-th sortKey="ac_msn" class="w-20">Serial</v-th>
						<v-th sortKey="tail_number" class="w-20">Regn</v-th>
						<v-th sortKey="ac_type_icao" class="w-20">Code</v-th>
						<v-th sortKey="ac_role" class="w-20">Cabin</v-th>
					</thead>
					<tbody slot="body" slot-scope="{ displayData }">
						<tr v-if="fleetsfiltered.length === 0">
							<td colspan="5" class="text-center">No recent flights</td>
						</tr>
						<tr v-else v-for="row in displayData" :key="row.id" class="text-center">
							<td class="text-center">
								{{ row.aircraft_model || row.ac_type_icao }}
							</td>
							<td class="text-center">{{ row.ac_msn || 'N/A' }}</td> 
							<td class="text-center">{{ row.tail_number || 'N/A' }}</td> 
							<td class="text-center">{{ row.ac_type_icao || 'N/A' }}</td> 
							<td class="text-center">{{ fleetCabin(row.ac_role) || 'N/A' }}</td> 
						</tr>
					</tbody>
				</v-table>
			</div>
		</div>
		-->
		<!-- Serial --><!-- Regn --><!-- Code --><!-- CABIN -->
	</div>
</template>

<script>
	export default {
		props: ['airline', 'aircraft', 'fleet', 'fleetsUnits', 'fleetsCounts', 'fleetsfilteredcount', 'fleetsfiltered'],

		data: function () {
			return {
				counts: {},
				selectedCode: null,
			};
		},
		created() {
			this.counts = this.fleetsCounts;
		},
		mounted() {},
		methods: {
			fleetCabin(conf) {
				//== 'CAR' ? 'FTR' : row.ac_role
				switch (conf) {
					case 'CAR', 'FTR':
						return 'FTR';
						break;
					case 'freighter':
						return 'FTR';
						break;
					case 'passenger', 'PAX':
						return 'PAX';
						break;
					case 'executive', 'VIP':
						return 'VIP';
						break;
					default:
						return conf;
				}
			},
			filterUnitsByCode(code) {
				this.selectedCode = this.selectedCode === code ? null : code;

				document.getElementById('fleet-units').scrollIntoView({ behavior: 'smooth' });
			},
		},

		computed: {
			getAircraftModel() {
				return row => {
					const aircraft = this.aircraft.find(ac => ac.code === row.ac_type_icao);
					return aircraft && aircraft.model ? aircraft.model : row.ac_type_icao;
				};
			},

			fleetsUnitsFiltered() {
				if (!this.selectedCode) return this.fleetsUnits;

				if (this.selectedCode) return this.fleetsUnits.filter(unit => unit.ac_type_icao === this.selectedCode);
			},
		},
		watch: {},
	};
</script>
