<template id="frontiers">
	<div class="container-fluid">
		<FrontiersFilter :aam-count="aamCount" :saf-count="safCount" :tech-count="techCount" @filter-kind="applyKindFilter" />

		<search-input placeholder="find frontier" v-model="filters.name.value" />

		<div style="overflow-x: auto">
			<v-table :data="filteredResults" class="table table-striped border fleet-flight-table bg-lights">
				<thead slot="head" class="text-center bg-blue-5 text-light">
					<tr>
						<v-th scope="col" sortKey="company" class="clickable text-center w-300px">Company</v-th>
						<!-- <v-th scope="col" sortKey="function " class="clickable text-center w-280px">Function</v-th> -->
						<v-th scope="col" sortKey="product" class="clickable text-center w-220px">Product</v-th>
						<v-th scope="col" sortKey="kind" class="clickable text-center w-120px">Kind</v-th>
						<v-th scope="col" sortKey="years" class="clickable text-center w-200px">Years</v-th>
						<v-th scope="col" sortKey="country.domicile" class="clickable text-left w-200px">Domicile</v-th>
					</tr>
				</thead>
				<tbody slot="body" slot-scope="{ displayData }">
					<tr v-for="row in displayData" :key="row.id">
						<td v-if="row.company" class="text-center">
							<a :href="url + 'directories/frontiers/' + row.id + ''">{{ row.company }}</a>
						</td>
						<td v-else></td>
						<!-- <td class="text-center">{{ row.function ?? '' }}</td> -->

						<td v-if="row.product" class="text-center" v-html="row.product"></td>
						<td v-else></td>

						<td class="text-center text-capitalize">
							{{ row.kind ?? '' }}
						</td>

						<td class="text-center text-capitalize">
							{{ row.years ?? '' }}
						</td>

						<td v-if="row.country_id != '' && row.country" class="text-left">
							<img v-bind:src="url + 'flags/blank.gif'" :class="'flag flag-' + getCountryFlagCode(row.country.domicile)" :alt="row.country.domicile" :title="row.country.domicile" />
							{{ row.country.domicile }}
						</td>
						<td v-else></td>
					</tr>
				</tbody>
			</v-table>
		</div>
	</div>
</template>

<script>
	import Vue2Filters from 'vue2-filters';
	import FrontiersFilter from './FrontiersFilter';

	export default {
		mixins: [Vue2Filters.mixin],
		components: { FrontiersFilter },
		props: {
			results: {
				type: Array,
				default: () => [],
			},
			aamCount: {
				type: Number,
				default: 0,
			},
			safCount: {
				type: Number,
				default: 0,
			},
			techCount: {
				type: Number,
				default: 0,
			},
		},
		data: function () {
			return {
				section: 'frontiers',
				currentSort: 'frontier',
				currentSortDir: 'asc',
				searchQuery: '',
				filteredList: this.results,
				url: '//' + window.location.hostname + '/',
				filters: {
					name: {
						value: '',
						keys: ['company', 'function', 'product', 'years', 'country.domicile'],
					},
				},
				selectedKindFilter: null,
			};
		},
		created() {
			this.mylist = this.results;
		},
		methods: {
			applyKindFilter(kind) {
				this.selectedKindFilter = kind;
			},
		},

		computed: {
			filteredResults() {
				let filtered = this.results;

				if (this.selectedKindFilter) {
					filtered = filtered.filter(frontier => {
						return frontier.kind === this.selectedKindFilter;
					});
				}

				// Apply search filter
				if (this.filters.name.value) {
					const search = this.filters.name.value.toLowerCase();
					filtered = filtered.filter(frontier => (frontier.company && frontier.company.toLowerCase().includes(search)) || (frontier.function && frontier.function.toLowerCase().includes(search)) || (frontier.product && frontier.product.toLowerCase().includes(search)) || (frontier.kind && frontier.kind.toLowerCase().includes(search)) || (frontier.years && frontier.years.toLowerCase().includes(search)) || (frontier.country && frontier.country.domicile && frontier.country.domicile.toLowerCase().includes(search)));
				}

				return filtered;
			},
		},
	};
</script>
