<template>
	<div class="filters justify-content-center mb-15 text-center">
		<template v-for="(count, item, index) in countsFiltered">
			<a :href="item" class="p-1 text-blue-7 filter-item" style="text-decoration: none; margin: 2px" :class="{ 'active-filter': activeFilter === item }" @click.prevent="toggleFilter(item)">{{ item }} ({{ Number(count).toLocaleString() }})</a>

			<template v-if="(index + 1) % nItems === 0">
				<br />
			</template>
		</template>
	</div>
</template>

<script>
	export default {
		props: {
			counts: {
				type: Object,
				required: true,
			},
			nItems: {
				type: Number,
				default: 8,
			},
			nGreater: {
				type: Number,
				default: 4,
				// // Means that only list items that have at least 5 results / records / entries
			},
		},
		data() {
			return {
				activeFilter: null,
				displayedCount: 0,
			};
		},
		computed: {
			countsFiltered() {
				return Object.entries(this.counts)
					.filter(([key, value]) => value > this.nGreater)
					.reduce((obj, [key, value]) => {
						obj[key] = value;
						return obj;
					}, {});
			},
		},
		methods: {
			toggleFilter(filterType) {
				if (this.activeFilter === filterType) {
					this.activeFilter = null;
					this.$emit('filter-field', null);
				} else {
					this.activeFilter = filterType;
					this.$emit('filter-field', filterType);
				}
			},
			resetFilter() {
				this.activeFilter = null;
			},
		},
	};
</script>

<style scoped>
	.filters {
		line-height: 30px;
		/* display: flex; */
		/* flex-wrap: wrap; */
		/* gap: 0.15rem; */
		/* justify-content: center; */
	}
	.filter-item {
		cursor: pointer;
		border-radius: 5px;
		text-align: center;
	}
	.filter-item:hover,
	.active-filter {
		background-color: #007bff;
		color: white;
	}
</style>
