<template id="airlines">
  <div class="container-fluid">
    <div class="sticky">
      <!-- <search-profile section="airlines"></search-profile> -->
      <!-- <filter section="airlines"></filter> -->
      <div class="card">
        <div class="card-header">
          <input
            class="form-control"
            v-model="filters.name.value"
            placeholder="            Search"
          />
        </div>
      </div>

      <div class="container d-block d-md-none text-center text-gray-5 t-22"><i class="fa-solid fa-left-right"></i></div>
      
      <v-table
        :data="airlines"
        :filters="filters"
        class="table table-striped-white border"
      >
        <thead slot="head" class="text-center">
          <v-th sortKey="airline" class="clickable">Airline</v-th>
          <v-th sortKey="domicile" class="clickable text-left pl-35"
            >Domicile</v-th
          >
          <v-th sortKey="kind" class="clickable">Kind</v-th>
          <v-th sortKey="status" class="clickable">Status</v-th>
          <v-th sortKey="codes" class="clickable">Code</v-th>
          <v-th sortKey="airlinesfleet_sum_units" class="clickable">Fleet</v-th>
          <!-- <v-th sortKey="license" class="clickable">License</v-th> -->
        </thead>
        <tbody slot="body" slot-scope="{ displayData }">
          <tr v-for="row in displayData" :key="row.id" class="text-center">
            <td>
              <a :href="url + 'airlines/' + row.id + ''">{{ row.airline }}</a>
            </td>
            <td
              v-if="row.country_id != '' && row.domicile"
              class="text-left pl-35"
            >
              <img
                v-bind:src="url + 'flags/blank.gif'"
                :class="'flag flag-' + getCountryFlagCode(row.domicile)"
                :alt="row.domicile"
                :title="row.domicile"
              />
              {{ row.domicile }}
            </td>
            <td v-else class="col"></td>
            <td>{{ row.kind }}</td>
            <td>{{ row.status }}</td>
            <td>{{ row.codes }}</td>
            <td v-if="row.airlinesfleet_sum_units">
              {{ row.airlinesfleet_sum_units }}
            </td>
            <td v-else>-</td>
            <!-- <td>{{ row.license }}</td> -->
          </tr>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters';
//    import SmartTable from 'vuejs-smart-table'

// Vue.use(SmartTable)

export default {
  mixins: [Vue2Filters.mixin],
  props: ['airlines', 'domicile'],
  data: function () {
    return {
      section: 'airlines',
      currentSort: 'airline',
      currentSortDir: 'asc',
      searchQuery: '',
      totalResults: '',
      filteredList: '',
      url: '//' + window.location.hostname + '/',

      filters: {
        name: {
          value: '',
          keys: ['airline', 'identity', 'license', 'domicile', 'code'],
        },
      },

      sortKey: 'airlines',
      reverse: false,
      columns: ['airlines', 'codes', 'license', 'fleet', 'status', 'domicile'],
    };
  },
  created() {
    this.filteredList = this.airlines;

    if (this.domicile) {
      this.filters.name.value = this.domicile.domicile;
    }
    // this.totalResults = Object.keys(this.filteredList).length;
  },
  mounted() {
    this.filteredList = this.airlines;
    this.totalResults = this.airlines.length;
  },
  methods: {},
  computed: {
    countList: function () {
      var n = this.filterBy(this.filteredList, this.searchQuery);
      this.totalResults = n.length;
      return this.totalResults;
    },
  },
  watch: {},
};
</script>
