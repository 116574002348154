<template id="currency">
	<div class="container-fluid">
		<search-input placeholder="find amount" v-model="searchQuery" />

		<div style="overflow-x: auto">
			<v-table :data="transformedData" :filters="filters" :currentPage.sync="currentPage" :pageSize="50" @totalPagesChanged="totalPages = $event" class="table table-striped border fleet-flight-table bg-light" selectedClass="table-info" selectionMode="multiple" @selectionChanged="selectedRows = $event">
				<thead slot="head" class="text-center bg-blue-5 text-light">
					<v-th v-for="field in details_fields" :key="field.key" :class="'clickable bg-blue-5 w-200px ' + field.col" :sortKey="field.key">
						{{ field.label }}
					</v-th>
				</thead>
				<tbody slot="body" slot-scope="{ displayData }">
					<tr v-for="row in displayData" :key="row.currency_date" class="text-center">
						<td v-for="field in details_fields" :key="field.key" :class="field.col">
							<template v-if="field.type === 'number'">
								{{ formatField(row, field) || '—' }}
							</template>
							<template v-else>{{ formatField(row, field) || '—' }}</template>
						</td>
					</tr>
				</tbody>
			</v-table>

			<smart-pagination :currentPage.sync="currentPage" :totalPages="totalPages" :maxPageLinks="16" />
		</div>
	</div>
</template>

<script>
	import Vue2Filters from 'vue2-filters';

	export default {
		mixins: [Vue2Filters.mixin],
		props: ['results'],
		data: function () {
			return {
				section: 'currencies',
				currentSort: 'currency_date',
				currentSortDir: 'desc',
				currentPage: 1,
				totalPages: 0,
				searchQuery: '',
				filteredList: this.results,
				url: '//' + window.location.hostname + '/',
				filters: {
					name: {
						value: '',
						keys: ['currency_date', 'currency', 'currency_value', 'XAU', 'XAG', 'XBT', 'XDR'],
					},
				},

				details_fields: [
					{ label: 'Date', key: 'currency_date', col: 'col', type: 'date' },
					{ label: 'XAG Silver', key: 'XAG', col: 'col', type: 'number' },
					{ label: 'XAU Gold', key: 'XAU', col: 'col', type: 'number' },
					{ label: 'XBT Bitcoin', key: 'XBT', col: 'col', type: 'number' },
					{ label: 'XDR IMF', key: 'XDR', col: 'col', type: 'number' },
				],
			};
		},
		computed: {
			transformedData() {
				const grouped = {};
				this.results.forEach(record => {
					const { currency_date, currency, currency_value } = record;
					const date = currency_date.split(' ')[0]; // Keep only the date part

					if (!grouped[date]) {
						// Initialize a new object for each date with default values
						grouped[date] = {
							currency_date: date,
							XAG: '—',
							XAU: '—',
							XBT: '—',
							XDR: '—',
						};
					}
					// Assign currency values to their respective columns
					grouped[date][currency] = currency_value;
				});

				// Convert the grouped object into an array
				return Object.values(grouped);
			},
			cleanedFilter: {
				get() {
					return this.searchQuery;
				},
				set(value) {
					this.filters.name.value = value.replace(/[\$,]/g, '').trim();
					this.searchQuery = value;
				},
			},
		},
		created() {
			this.mylist = this.results;
		},
		methods: {
			formatField(row, field) {
				if (field.key === 'currency_date') {
					return row[field.key];
				}
				
				if (row[field.key] === '—') {
					return '—';
				}
				
				if (field.key === 'XAU' || field.key === 'XBT') {
					// Format XAU as dollar amount without cents
					return '$' + Math.floor(row[field.key]).toLocaleString();
				}
				
				// Format other currency fields with dollar sign and decimal places
				return '$' + parseFloat(row[field.key]).toLocaleString(undefined, {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2
				});
			}
		},
		watch: {
			searchQuery(newValue) {
				this.cleanedFilter = newValue;
			},
		},
	};
</script>
