<template>
	<div class="changes-filter1 col-12 text-center mb-20">
	<div class="row text-center">
		<div class="col p-0">
		<a href="#" class="p-5 filter-item t-16 text-blue-5" :class="{ 'active-filter': activeFilter === 'previousYear' }" @click.prevent="toggleFilter('previousYear')">Last Year ({{ lastYearEventsCount }})</a> |
		<a href="#" class="p-5 filter-item t-16 text-blue-5"  :class="{ 'active-filter': activeFilter === 'currentYear' || activeFilter === null }" @click.prevent="toggleFilter('currentYear')">This Year ({{ currentYearEventsCount }})</a> |
		<a href="#" class="p-5 filter-item t-16 text-blue-5"  :class="{ 'active-filter': activeFilter === 'nextYear' }" @click.prevent="toggleFilter('nextYear')">Next Year ({{ nextYearEventsCount }})</a>
		</div>
	</div>
	<!-- <div class="row">
		<div class="col pt-20">

			<h3 class="text-center text-gold-7">{{ eventType}}</h3>
		</div>
	</div> -->
	</div>
</template>

<script>
	export default {
		props: {
			lastYearEventsCount: {
				type: Number,
				default: 0,
			},
			currentYearEventsCount: {
				type: Number,
				default: 0,
			},
			nextYearEventsCount: {
				type: Number,
				default: 0,
			},
		},
		data() {
			return {
				activeFilter: null,
				eventType: "Events"
			};
		},
		methods: {
			toggleFilter(filterType) {
				switch(filterType) {
					case 'previousYear':  this.eventType = 'Previous Yr'; break; 
					case 'currentYear':  this.eventType = 'Current Yr'; break; 
					case 'nextYear':  this.eventType = 'Next Yr'; break; 

				}

				if (this.activeFilter === filterType) {
					this.activeFilter = null;
					this.$emit('filter-events', null); // Emit null to clear filter
					this.eventType = "Events";
				} else {
					this.activeFilter = filterType;
					this.$emit('filter-events', filterType); // Emit the selected filter
				}
			},
		},
	};
</script>

<style scoped>
 
	.filter-item {
		cursor: pointer;
		margin: 0.25rem;
		padding: 0.5rem;
		border-radius: 5px;
		text-decoration: none;
	}
	/* .filter-item:hover, */
	.active-filter {
		background-color: #007bff;
		color: white;
	}
</style>

</style>
