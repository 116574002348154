<template id="countries-componenet">
  <div>
    <div class="general-container">
      <template v-if="type == 'icao_region'">
        <domiciles-icao-regions :domiciles="domiciles">
        </domiciles-icao-regions>
      </template>

      <template v-if="type == 'location'">
        <domiciles-location :domiciles="domiciles"></domiciles-location>
      </template>
    </div>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters';

import DomicilesLocation from './DomicilesLocation.vue';
Vue.component('domiciles-location', DomicilesLocation);

import DomicilesICAORegions from './DomicilesICAORegions.vue';
Vue.component('domiciles-icao-regions', DomicilesICAORegions);

export default {
  mixins: [Vue2Filters.mixin],
  props: ['domiciles', 'type'],
  data: function () {
    return {
      currentSort: 'country',
      currentSortDir: 'asc',
      searchQuery: '',
      filteredList: this.countrieslist,
      url: '//' + window.location.hostname + '/',
      filters: {
        name: {
          value: '',
          keys: [
            'airspace',
            'authority',
            'codes',
            'aircraft_registration',
            'icao_locator',
          ],
        },
      },
    };
  },
  created() {
    this.mylist = this.countrieslist;
  },
  methods: {
    morethan() {
      return;
    },

    matches(obj) {
      const term = this.searchQuery.toLowerCase();
      return filteredList.toLowerCase().includes(term);
    },
    getDomicilesCount() {
      return filteredList;
    },
    getCountriesCount() {},
    getTerritoriesCount() {},
  },
  computed: {
    filteredcountry() {
      if (this.searchQuery == '') {
        // this.$emit('filterChange', this.filteredList)
        return this.filteredList;
      }
      return this.filteredList.filter(function (builder) {
        return country.includes(function (item) {
          return item.country.includes(this.searchQuery);
        });
        // val => String(val).toLowerCase().includes( this.searchQuery ) );  ///filters the word object
      });
    },
  },
};
</script>
