<template id="capacities">
  <div class=" ">
    <div class="card">
      <div class="card-header">
        <input
          class="form-control"
          v-model="filters.name.value"
          placeholder="Find Aircraft"
        />
      </div>
    </div>

    <v-table
      :data="capacities"
      :filters="filters"
      class="table table-striped-white border mt-3"
    >
      <thead slot="head" class="text-center">
        <tr>
          <v-th
            scope="col"
            sortKey="builder.builder"
            class="clickable text-center"
            >Builder</v-th
          >
          <v-th scope="col" sortKey="model" class="clickable text-center"
            >Aircraft
            <!-- {{ capacities[0].aircraft_model_configurations }} -->
          </v-th>
          <!-- <v-th scope="col" sortKey="domicile" class="clickable text-center"
            >Domicile</v-th
          > -->
          <v-th scope="col" sortKey="seats" class="clickable text-center"
            >Seats</v-th
          >
          <v-th scope="col" sortKey="pallets" class="clickable text-center"
            >Pallets</v-th
          >

          <v-th scope="col" sortKey="compartments" class="clickable text-center"
            >Compartments</v-th
          >
        </tr>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr v-for="row in displayData" :key="row.id" class="text-center">
          <td v-if="row.builder_id && row.builder" class="text-center">
            <a :href="url + 'builders/' + row.builder_id + ''">{{
              row.builder.builder
            }}</a>
          </td>
          <td v-else></td>

          <td v-if="row.model" class="text-center">
            <a :href="url + 'capacities/' + row.id + ''">{{ row.model }}</a>
          </td>
          <td v-else></td>
          <!-- <td  v-if=" row.country_id && row.country "><img v-bind:src="url+'flags/blank.gif'" :class="'flag flag-'+getCountryFlagCode( row.country.domicile)" :alt="row.country.domicile"  :title=" row.country.domicile " /> {{ row.country.domicile }} </td><td v-else></td> -->
          <td v-if="row.seats" class="text-center">{{ row.seats }}</td>
          <td v-else></td>
          <td v-if="row.pallets" class="text-center">{{ row.pallets }}</td>
          <td v-else></td>
          <td v-if="row.compartments" class="text-center">
            {{ row.compartments }}
          </td>
          <td v-else></td>
        </tr>
      </tbody>
    </v-table>
    <!-- 
        <table class="table table-striped   mt-3">
        <thead class="table-header-aux">
            <tr>
                    <th scope="col" @click="sorting('model')" class="clickable text-center">Aircraft</th>
                    <th scope="col" @click="sorting('builder.builder')" class="clickable text-center">Builder</th>
                    <th scope="col" @click="sorting('seats')" class="clickable text-center">Seats</th>
                    <th scope="col" @click="sorting('pallets')" class="clickable text-center">Pallets</th>
                    <th scope="col" @click="sorting('compartments')" class="clickable text-center">Compartments</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="row in filterBy(filteredList, searchQuery)" :key="row.id">
                <td v-if=" row.model " class="text-center"><a :href="url+'aircraft/' + row.id +''">{{ row.model }}</a></td><td v-else></td>
                <td v-if=" row.builder_id && row.builder " class="text-center"><a :href="url+'builders/' + row.builder_id +''">{{ row.builder.builder }}</a></td><td v-else></td>
                <td v-if=" row.seats" class="text-center">{{ row.seats }}</td><td v-else></td>
                <td v-if=" row.pallets" class="text-center">{{ row.pallets }}</td><td v-else></td>
                <td v-if=" row.compartments" class="text-center">{{ row.compartments }}</td><td v-else></td>
            </tr>
        </tbody>
        </table>
         -->
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters';

export default {
  mixins: [Vue2Filters.mixin],
  props: ['capacities'],
  data: function () {
    return {
      searchQuery: '',

      filters: {
        name: { value: '', keys: ['model', 'code', 'compartments'] },
      },

      currentSort: 'group',
      urrentSortDir: 'asc',

      url: '//' + window.location.hostname + '/',
    };
  },
  created() {},
  methods: {
    morethan() {
      return;
    },

    matches(obj) {
      const term = this.searchQuery.toLowerCase();
      return filteredList.toLowerCase().includes(term);
    },

    filteredAirports: function () {
      if (this.searchQuery == '') return;

      const self = this;
      // console.log('we are filtering: ' +  this.searchQuery );

      let formData = new FormData();

      formData.append('searchQuery', this.searchQuery);

      var $url = '/capacities/filter';
      formData.append('_method', 'post');

      axios
        .post($url, formData)
        .then(function (response) {
          self.filteredList = response.data;
          return;
        })
        .catch(function (error) {});

      if (this.searchQuery == '') {
        return this.filteredList;
      }
    },
    cleanStr: function (str, type) {
      return str.replace(type, '');
    },
  },
  computed: {
    // filteredAirport() {
    //     if ( this.searchQuery =="" ) {
    //         return this.filteredList;
    //     }
    //     return this.filteredList.filter(function(builder) {
    //         return airport.includes(function(row) {
    //             return row.airport.includes(this.searchQuery);
    //         });
    //     });
    // }
  },
};
</script>
