<template>
	<div class="container-fluid pl-15 pr-15 pt-15 pb-0">
		<form @submit.prevent="applyFilters">
			<div class="row justify-content-md-center text-center pt-15 pb-15">
				<div class="col-6 col-lg-3">
					<div class="row">
						<div class="col">Start</div>
					</div>
					<div class="row">
						<div class="col">
							<datepicker v-model="filter.start_date" format="yyyy-MMM-dd" input-class="form-control small bg-light" placeholder="YYYY MMM DD" :typeable="true" ref="startDateInput" @selected="applyFilters" :disabled-dates="disabledDates" ></datepicker>

							<button v-if="filter.start_date" type="button" @click="clearField('start_date')" class="text-center border-0 btn-clear-field">x</button>
						</div>
					</div>
				</div>

				<div class="col-6 col-lg-3">
					<div class="row">
						<div class="col">Finish</div>
					</div>
					<div class="row">
						<div class="col">
							<datepicker v-model="filter.finish_date" format="yyyy-MMM-dd" input-class="form-control small bg-light" placeholder="YYYY MMM DD" :typeable="true" @selected="applyFilters" :disabled-dates="disabledDates"></datepicker>

							<button v-if="filter.finish_date" type="button" @click="clearField('finish_date')" class="text-center border-0 btn-clear-field">x</button>
						</div>
					</div>
				</div>
				<div class="col-3 col-lg-2" v-show="false">
					Days
					<input type="hidden" v-model="days" class="form-control" />
				</div>
			</div>

			<div class="row mb-15 bg-gray-10 p-10">
				<div class="col search-webpage">
					<input type="text" v-model="filter.term" placeholder="Search" class="form-control" @keyup.enter="applyFilters" />

					<button v-if="filter.term" type="button" @click="clearField('term')" class="text-center border-0 btn-clear-field">x</button>
				</div>
			</div>

			<!-- <div class="row text-center mt-5 justify-content-center">
				<div class="col">
					<h3 class="mt-15 mb-15 text-center">Results ({{ totalCount.toLocaleString() }})</h3>
				</div>
			</div> -->
		</form>
	</div>
</template>

<script>
	import Datepicker from 'vuejs-datepicker';

	import moment from 'moment';

	export default {
		components: { Datepicker },
		props: {
			page: '',

			results: {
				type: Array,
				default: () => [],
			},

			dateLimitations: {
				type: Object,
				required: true,
			},

			totalCount: 0,
			filledfields: {
				type: Object,
				default: () => ({}), // Default to an empty object
			},
		},
		data() {
			return {
				lastupdate: '',

				filter: {
					start_date: '',
					finish_date: '',
					term: '',
				},
				error_message: '',
				searchPerformed: false,
				portal: '',
				url: '//' + window.location.hostname + '/',
			};
		},
		computed: {
			formattedFilter() {
				return {
					...this.filter,
					start_date: this.filter.start_date ? moment(this.filter.start_date).format('yyyy-MM-DD') : '',
					finish_date: this.filter.finish_date ? moment(this.filter.finish_date).format('yyyy-MM-DD') : '',
				};
			},
			days() {
				if (this.filter.start_date || this.filter.finish_date) {
					const start = moment(this.filter.start_date);
					const end = moment(this.filter.finish_date);

					if (start.isValid() && end.isValid()) {
						return end.diff(start, 'days');
					}
				}
				return 0;
			},

			disabledDates() {
				const { minDate, maxDate } = this.dateLimitations;

				const disabledDates = {
					to: moment(minDate).toDate(),
					from: moment(maxDate).toDate(),
				};

				return disabledDates;
			},
		},
		created() {
			// let today = new Date();
			// this.filter.start_date = new Date(new Date().setDate(today.getDate() - 90));
			// this.filter.finish_date = today;
		},

		mounted() {},
		watch: {},

		methods: {
			validateField(key) {
				// Dynamically initialize error state if it doesn't exist
				if (!this.errors.hasOwnProperty(key)) {
					this.$set(this.errors, key, false);
				}
				this.errors[key] = !this.filter[key];
			},
			clearField(key) {
				console.log(this.filter[key]);
				this.filter[key] = '';
				this.applyFilters();
			},
			applyFilters() {
				this.error_message = '';
				this.searchPerformed = true;
				this.$emit('apply-filters', this.formattedFilter); // formattedFilter instead of filter to prevent the datepicker from switching the date format.
			},
		},
	};
</script>

<style scoped>
	button {
		margin-top: 5px;
		padding: 5px 10px;
		/* background-color: #f9f9f9; */
		border: 1px solid #ccc;
		cursor: pointer;
	}
	button:hover {
		background-color: #e2e2e2;
	}

	.vdp-datepicker .vdp-datepicker__clear-button {
		border: 1px solid #ccc !important;
		padding: 5px 10px !important;
		color: #888888 !important;
		text-transform: none;
	}
</style>
