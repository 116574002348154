<template id="capacities">
  <div class=" ">
    <div class="card">
      <!-- </div>v-if=" portal== 'Aircraft' "> -->
      <div class="card-header">
        <input
          class="form-control"
          v-model="filters.name.value"
          placeholder="            Search"
        />
      </div>
    </div>
    <!-- <filter></filter> -->

    <v-table
      :data="operatorData"
      :filters="filters"
      class="table table-striped-white border mt-3"
    >
      <thead slot="head" class="text-center">
        <!-- <v-th scope="col" sortKey="airline" class="clickable text-center">Airline</v-th> -->
        <v-th scope="col" sortKey="row.model" class="clickable text-center">{{
          portal
        }}</v-th>
        <v-th scope="col" sortKey="builder" class="clickable text-center"
          >Builder</v-th
        >
        <v-th scope="col" sortKey="operators" class="clickable text-center"
          >Operators</v-th
        >
        <v-th scope="col" sortKey="units" class="clickable text-center"
          >Aircraft</v-th
        >
        <v-th scope="col" sortKey="domicile" class="clickable text-left"
          >Domicile</v-th
        >
        <!-- <v-th scope="col" sortKey="seats"  class="clickable text-center">Seats</v-th>
            <v-th scope="col" sortKey="pallets"   class="clickable text-center">Pallets</v-th>
            <v-th scope="col" sortKey="compartments"  class="clickable text-center">Compartments</v-th> -->
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr v-for="row in displayData" :key="row.id">
          <template v-if="portal == 'Engines'">
            <td v-if="row.model" class="text-center">
              <a :href="url + 'operators/engines/' + row.id + ''">{{
                row.model
              }}</a>
            </td>
            <td v-else></td>
          </template>
          <template v-else>
            <td v-if="row.model" class="text-center">
              <a :href="url + 'operators/' + row.id + ''">{{ row.model }}</a>
            </td>
            <td v-else></td>
          </template>
          <!-- <td v-if=" row.builder_id && row.builder " class="text-center"><a :href="url+'builders/' + row.builder_id +''">{{ row.builder.builder }}</a></td><td v-else></td> -->
          <td v-if="row.builder" class="text-center">
            <a :href="url + 'builders/' + row.builder_id + ''">{{
              row.builder
            }}</a>
          </td>
          <td v-else></td>
          <td v-if="row.operators" class="text-center">{{ row.operators }}</td>
          <td v-else></td>
          <td v-if="row.units" class="text-center">{{ row.units }}</td>
          <td v-else></td>
          <td v-if="row.country_id && row.country">
            <img
              v-bind:src="url + 'flags/blank.gif'"
              :class="'flag flag-' + getCountryFlagCode(row.country)"
              :alt="row.country"
              :title="row.country"
            />
            {{ row.country }}
          </td>
          <td v-else></td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters';

export default {
  mixins: [Vue2Filters.mixin],
  props: ['operators', 'aircraft', 'engines', 'portal'],
  data: function () {
    return {
      searchQuery: '',

      filters: {
        name: {
          value: '',
          keys: [
            'model',
            'builder',
            'code',
            'units',
            'operators',
            // 'domicile',
            // 'row.country.domicile',
            'country',
          ],
        },
      },
      currentSort: 'group',
      currentSortDir: 'asc',
      operatorData: '',
      url: '//' + window.location.hostname + '/',
    };
  },
  created() {
    if (this.portal == 'Engines') this.operatorData = this.engines;
    else this.operatorData = this.aircraft;
  },
  methods: {
    morethan() {
      return;
    },

    matches(obj) {
      const term = this.searchQuery.toLowerCase();
      return operatorData.toLowerCase().includes(term);
    },

    filteredAirports: function () {
      if (this.searchQuery == '') return;

      const self = this;

      let formData = new FormData();

      formData.append('searchQuery', this.searchQuery);

      var $url = '/capacities/filter';
      formData.append('_method', 'post');

      axios
        .post($url, formData)
        .then(function (response) {
          self.operatorData = response.data;
          return;
        })
        .catch(function (error) {});

      if (this.searchQuery == '') {
        return this.operatorData;
      }
    },
    cleanStr: function (str, type) {
      return str.replace(type, '');
    },
  },
  computed: {},
};
</script>
