<template id="publications">
	<div class="container-fluid">
		<search-input placeholder="find publication" v-model="filters.name.value" />

		<div style="overflow-x: auto">
			<v-table :data="results" :filters="filters" class="table table-striped-white bg-light border">
				<thead slot="head" class="text-center bg-blue-5 text-light">
					<tr>
						<v-th scope="col" sortKey="publication" class="clickable w-350px">Publication</v-th>
						<v-th scope="col" sortKey="publisher_name" class="clickable w-120px">Publisher</v-th>
						<v-th scope="col" sortKey="website" class="clickable w-120px">Website</v-th>
						<v-th scope="col" sortKey="frequency" class="clickable w-200px">Frequency</v-th>
						<v-th scope="col" sortKey="country" class="clickable w-200px text-left">Domicile</v-th>
					</tr>
				</thead>
				<tbody slot="body" slot-scope="{ displayData }">
					<tr v-for="publication in displayData" :key="publication.id" class="text-center">
						<td v-if="publication.publication">
							<a :href="url + 'directories/publications/' + publication.id + ''" :title="publication.publication">{{ publication.publication }}</a>
						</td>
						<td v-else></td>
						<td>{{ publication.publisher_name ?? '' }}</td>
						<td v-if="publication.website">
							<a :href="'http://' + publication.website"><i class="fas fa-link"></i></a>
						</td>
						<td v-else></td>
						<td>{{ publication.frequency ?? '' }}</td>
						<td v-if="publication.country" class="text-left">
							<img v-bind:src="url + 'flags/blank.gif'" :class="'flag flag-' + getCountryFlagCode(publication.country)" :alt="publication.country" :title="publication.country" />
							{{ publication.country }}
						</td>
						<td v-else></td>
					</tr>
				</tbody>
			</v-table>
		</div>
	</div>
</template>

<script>
	import Vue2Filters from 'vue2-filters';

	export default {
		mixins: [Vue2Filters.mixin],
		props: ['results'],
		data: function () {
			return {
				currentSort: 'market',
				currentSortDir: 'asc',
				searchQuery: '',
				filteredList: this.results,
				url: '//' + window.location.hostname + '/',
				filters: {
					name: {
						value: '',
						keys: ['publication', 'publisher_name', 'access', 'frequency', 'country', 'website'],
					},
				},
			};
		},
		created() {
			this.mylist = this.results;
		},
		methods: {
			morethan() {
				return;
			},

			matches(obj) {
				const term = this.searchQuery.toLowerCase();
				return filteredList.toLowerCase().includes(term);
			},
		},
		computed: {
			filteredpublication() {
				if (this.searchQuery == '') {
					return this.filteredList;
				}
				return this.filteredList.filter(function (builder) {
					return publication.includes(function (item) {
						return item.publication.includes(this.searchQuery);
					});
				});
			},
		},
	};
</script>
