<template>
	<div class="container-fluid bg-light p-10 text-blue-7">
		<FlightsFilters @changePage="changePage" @apply-filters="applyFilters" :filters_fields="filters_fields" :results="results" :totalCount="totalCount" filtersheading="Enter one item" :date-limitations="dateLimitations" :page="page" :showGroupBy="true" :filledfields="filled"></FlightsFilters>

		<template v-if="page === 'fleets'">
			<FleetsRoutesFleets :date-limitations="dateLimitations" :countResults="countResults" :totalCount="totalCount" :totalPages="totalPages" page="fleets" ref="fleetsRoutesFleetsRef" @handleResults="handleResults"></FleetsRoutesFleets>
		</template>
		<template v-else>
			<FleetsRoutesRoutes :date-limitations="dateLimitations" :countResults="countResults" :totalCount="totalCount" :totalPages="totalPages" page="fleets" ref="fleetsRoutesRouteRef" @handleResults="handleResults"></FleetsRoutesRoutes>
			<!-- <FlightsOnly :date-limitations="dateLimitations" page="flights" ref="fleetsRoutesFleetsRef"></FlightsOnly> -->
			<!-- Fleets routes Here -->
		</template>
	</div>
</template>

<script>
	import FlightsFilters from './FlightsFilters';
	import FleetsRoutesFleets from './FleetsRoutesFleets';
	import FleetsRoutesRoutes from './FleetsRoutesRoutes';
	// import FleetsFlights from './FleetsFlights';
	// import FlightsOnly from './FlightsOnly';
	// import AircraftFleet from './AircraftFleet';
	import moment from 'moment';

	export default {
		components: {
			FleetsRoutesFleets,
			FleetsRoutesRoutes,
			FlightsFilters,
			// AircraftFleet,
			// FleetsFlights,
			// FlightsOnly,
		},
		props: {
			plan: String,
			accesslimit: String,
			filled: {
				type: Object,
				default: () => ({}),
			},
		},
		data() {
			return {
				results: [],
				countResults: {},

				totalCount: 0,
				currentPage: 1,
				totalPages: 0,

				sortField: 'ac_msn',
				sortOrder: 'asc',

				page: 'fleets',
				dateLimitations: {
					minDate: '',
					maxDate: '',
				},
				filters_fields: [
					{
						label: 'Aircraft',
						placeholder: 'code-4',
						key: 'ac_type_icao',
						col: 'col',
						type: 'string',
						link: 'aircraft',
					},

					{
						label: 'Serial',
						placeholder: 'msn',
						key: 'ac_msn',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Registration',
						placeholder: 'Registration',
						key: 'tail_number',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Airline',
						placeholder: 'name',
						key: 'airline_name',
						secondkey: 'airline_name',
						thirdkey: 'airline_iata',
						col: 'col',
						type: 'string',
						link: 'airline',
					},

					{
						label: 'IATA',
						placeholder: 'airline-2',
						key: 'airline_iata',
						col: 'col',
						type: 'string',
						// hidden: 'false',
					},

					{
						label: 'ICAO',
						placeholder: 'airline-3',
						key: 'airline_icao',
						col: 'col',
						type: 'string',
						// hidden: 'false',
					},

					{
						label: 'Route',
						placeholder: 'iata-iata',
						key: 'route',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Airport',
						placeholder: 'iata',
						key: 'departure_airport_iata',
						secondkey: '',
						thirdkey: '',
						fourkey: '',
						col: 'col',
						type: 'string',
						link: 'airport',
					},

					{
						label: 'Airspace',
						placeholder: 'iso-2',
						key: 'departure_airport_country',
						col: 'col',
						type: 'string',
						link: 'airspace',
					},
				],
			};
		},
		async created() {
			this.page = this.filled.groupBy;

			const plan = this.plan;

			const now = moment();
			let minDate = moment();
			let maxDate = moment();

			minDate = this.accesslimit;

			this.dateLimitations = {
				minDate: minDate,
				maxDate: maxDate.format('YYYY-MM-DD'),
			};
		},

		computed: {},
		methods: {
			applyFilters(newFilters) {
				this.filters = newFilters; // Set the filters
				this.page = newFilters.groupBy || 'fleets'; // Update the page based on groupBy

				// Fetch data based on the updated page
				if (this.page === 'fleets') {
					this.$refs.fleetsRoutesFleetsRef.fetchData(this.filters, 1);
				} else {
					this.$refs.fleetsRoutesRouteRef.fetchData(this.filters, 1);
				}
			},

			changePage(page) {
				this.page = page;
				// Fetch data based on the new page
				// if (page === 'fleets') {
				// 	this.$refs.fleetsRoutesFleetsRef.applyFilters(this.filters, 1);
				// } else {
				// 	this.$refs.fleetsRoutesRouteRef.applyFilters(this.filters, 1);
				// }
			},

			handleResults(results) {
				this.results = results.results;
				this.totalPages = results.totalPages;
				this.totalCount = results.totalCount;
				this.currentPage = results.currentPage;
			},
		},
	};
</script>
