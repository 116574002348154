<template>
	<div class="filter-menu mb-30">
		<div class="row mb-0">
			<div class="col-12 d-flex flex-wrap justify-content-center">
				<div v-for="(count, focusValue) in row1" :key="focusValue" class="px-2 p-0 text-blue-7 filter-item" @click="filterByFocus(focusValue)" :class="{ 'active-filter': focusValue === selectedFocus }">{{ focusValue }} ({{ count }})</div>
			</div>
		</div>

		<div class="row mb-0">
			<div class="col-12 d-flex flex-wrap justify-content-center">
				<div v-for="(count, focusValue) in row2" :key="focusValue" class="px-2 p-0 text-blue-7 filter-item" @click="filterByFocus(focusValue)" :class="{ 'active-filter': focusValue === selectedFocus }">{{ focusValue }} ({{ count }})</div>
			</div>
		</div>

		<div class="row">
			<div class="col-12 d-flex flex-wrap justify-content-center">
				<div v-for="(count, focusValue) in row3" :key="focusValue" class="px-2 p-0 text-blue-7 filter-item" @click="filterByFocus(focusValue)" :class="{ 'active-filter': focusValue === selectedFocus }">{{ focusValue }} ({{ count }})</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			processedResults: {
				type: Array,
				required: true,
			},
		},
		data() {
			return {
				selectedFocus: null,
				row1Keys: ['Aircraft', 'Airlines', 'Airports', 'Airspaces', 'Aviation', 'Builders'],
				row2Keys: ['Cargo', 'Engines', 'Finance', 'Flight', 'Fuel', 'Ground'],
				row3Keys: ['Inflight', 'Insurance', 'Maintenance', 'Passengers', 'Traffic'],
			};
		},
		computed: {
			focusCounts() {
				const counts = {};
				this.processedResults.forEach(item => {
					const focus = item.focus;
					counts[focus] = counts[focus] ? counts[focus] + 1 : 1;
				});
				return counts;
			},
			row1() {
				return this.getRowData(this.row1Keys);
			},
			row2() {
				return this.getRowData(this.row2Keys);
			},
			row3() {
				return this.getRowData(this.row3Keys);
			},
		},
		methods: {
			getRowData(keys) {
				return keys.reduce((acc, key) => {
					if (this.focusCounts[key]) {
						acc[key] = this.focusCounts[key];
					}
					return acc;
				}, {});
			},
			filterByFocus(focus) {
				// Toggle filter if the focus is already selected, else apply new filter
				this.selectedFocus = this.selectedFocus === focus ? null : focus;
				this.$emit('filter-focus', this.selectedFocus);
			},
		},
	};
</script>

<style scoped>
	.filter-item {
		cursor: pointer;
		margin: 0.25rem;
		padding: 0.5rem;
		border-radius: 5px;
	}
	.filter-item:hover,
	.active-filter {
		background-color: #007bff;
		color: white;
	}
</style>
