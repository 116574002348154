<template id="engines">
  <div class="engineslist">
    <div class="card">
      <div class="card-header">
        <input
          class="form-control"
          v-model="filters.name.value"
          placeholder="            Search"
        />
      </div>
    </div>

    <v-table
      :data="engines"
      :filters="filters"
      class="table table-striped-white border"
    >
      <thead slot="head" class="text-center">
        <tr>
          <v-th scope="col-3" sortKey="engine" class="clickable text-center"
            >Engine</v-th
          >
          <v-th scope="col-3" sortKey="builder" class="clickable text-center"
            >Builder</v-th
          >
          <v-th scope="col-2" sortKey="domicile" class="clickable text-left"
            >Domicile</v-th
          >
          <v-th
            scope="col-4"
            sortKey="applications"
            class="clickable text-center"
            >Applications</v-th
          >
        </tr>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr v-for="row in displayData" :key="row.id" class="text-center">
          <td v-if="row.model" class="text-center">
            <a :href="url + 'engines/' + row.id + ''">{{ row.model }}</a>
          </td>
          <td v-else></td>
          <td v-if="row.builder_id" class="text-center">
            <a :href="url + 'builders/' + row.builder_id + ''">{{
              row.builder.builder
            }}</a>
          </td>
          <td v-else></td>
          <td v-if="row.country_id" class="text-left">
            <img
              v-bind:src="url + 'flags/blank.gif'"
              :class="'flag flag-' + getCountryFlagCode(row.country.domicile)"
              :alt="row.country.domicile"
              :title="row.country.domicile"
            />
            {{ row.country.domicile }}
          </td>
          <td v-else></td>
          <td v-if="row.applications" class="text-center">
            {{ row.applications }}
          </td>
          <td v-else></td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters';

export default {
  mixins: [Vue2Filters.mixin],
  props: ['engines'],
  data: function () {
    return {
      url: '//' + window.location.hostname + '/',
      filters: {
        name: {
          value: '',
          keys: [
            'model',
            'builder.builder',
            'country.domicile',
            'applications',
          ],
        },
      },
    };
  },
  created() {},
  methods: {},
  computed: {},
};
</script>
