<template id="bulletins-manager">
  <div class="bulletin-container">
    <table class="table">
      <tbody>
        <tr v-for="bulletin in bulletins" :key="bulletin.id">
          <td class="text-center">
            <a href="#" @click.prevent="modalRegister">
              <span class="text-darkest-blue"
                >Bulletin {{ customDateFormat(bulletin.bulletin_date, 'Y-WW') }}
              </span>
              &nbsp;
              {{
                subDate(bulletin.bulletin_date, bulletin.days, 'Y MMM DD ddd')
              }}
              -
              {{ customDateFormat(bulletin.bulletin_date, 'Y MMM DD ddd') }}
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="MRPForm">
      <transition name="modal">
        <div
          class="modal-mask"
          @click="closeModal('bulletin')"
          v-show="showModal"
        >
          <div
            class="modal-container text-left p-4"
            style="background: #dae1f2"
            @click.stop
          >
            <div
              class="modal-body text-center bulletin"
              style="background: #dae1f2; z-index: 999999"
            >
              <RegistrationLoginBox></RegistrationLoginBox>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';

import moment from 'moment';

export default {
  mixins: [Vue2Filters.mixin, './datemixin'],
  components: {
    Datepicker,
  },
  props: ['bulletins', 'countrieslist', 'selectedbulletin'],
  data: function () {
    return {
      errors: [],

      showPassword: false,
      showModal: false,
      modalTitle: '',

      url: '//' + window.location.hostname + '/',
    };
  },
  created() {},

  watch: {},
  computed: {
    buttonLabel() {
      return this.showPassword ? 'Hide' : 'Show';
    },
  },
  methods: {
    modalRegister: function () {
      this.showModal = true;
      this.modalTitle = 'Register';
    },

    subDate: function (bulletin_date, days, format) {
      var newdate = moment(bulletin_date).subtract(days, 'days').format(format);

      return newdate;
    },

    toTitleCase: function (str) {
      var newCapitalCaseWord = str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });

      return newCapitalCaseWord;
    },

    redirect: function (url) {
      history.pushState({}, null, '/' + url);
    },
  },

  mounted() {},
  beforeDestroy() {},
};
</script>
<style>
.bulletin input[type='email']::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: center;
  line-height: 0.9;
}
.bulletin input[type='text']::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: center;
}
.bulletin input[type='password']::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: center;
}
.bulletin input[type='tel']::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: center;
}
.bulletin input[type='email']:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  text-align: center;
}
.bulletin input[type='email']::-ms-input-placeholder {
  /* Microsoft Edge */
  text-align: center;
}

.form-group {
  margin-bottom: 0.5rem;
}
</style>
