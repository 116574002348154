import Vue from 'vue';
window.Vue = Vue;

import moment from 'moment';

var numberformatmixin = {
  data: function () {
    return {
      mylist: [],
    };
  },
  methods: {
    number_format(number) {
      return new Intl.NumberFormat('en-IN', {
        maximumSignificantDigits: 2,
      }).format(number);
    },
  },
  mounted() {
    const self = this;
    document.addEventListener('keydown', e => {
      if (self.showModal && e.keyCode == 27) {
        s;
        self.showModal = false;
      }
    });
  },
};

export default numberformatmixin;
