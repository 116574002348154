<template>
	<div class="changes-filter1 col-12 text-center mb-20">
		<div class="row text-center">
			<div class="col">
		<a href="#" class=" p-5 filter-item t-16 text-blue-5" :class="{ 'active-filter': activeFilter === 'AAM' }" @click.prevent="toggleFilter('AAM')">
			AAM ({{ aamCount }})
		</a>
		|
		<a href="#" class="p-5 filter-item t-16 text-blue-5"  :class="{ 'active-filter': activeFilter === 'SAF' }" @click.prevent="toggleFilter('SAF')">
			SAF ({{ safCount }})
		</a>
		|
		<a href="#" class="p-5 filter-item t-16 text-blue-5"  :class="{ 'active-filter': activeFilter === 'Tech' }" @click.prevent="toggleFilter('Tech')">
			Tech ({{ techCount }})
		</a>
	</div>
	</div>
	<!-- <div class="row">
		<div class="col pt-20">

			<h3 class="text-center text-gold-7">{{ selectedType }}</h3>
		</div>
	</div> -->
	</div>
</template>

<script>
	export default {
		props: {
			aamCount: {
				type: Number,
				default: 0,
			},
			safCount: {
				type: Number,
				default: 0,
			},
			techCount: {
				type: Number,
				default: 0,
			},
		},
		data() {
			return {
				activeFilter: null,
				selectedType: "Frontiers",
			};
		},
		methods: {
			toggleFilter(filterType) {

				switch(filterType) {
					case 'AAM':  this.selectedType = 'AAM'; break; 
					case 'SAF':  this.selectedType = 'SAF'; break; 
					case 'Tech':  this.selectedType = 'Tech'; break; 

				}
				

				if (this.activeFilter === filterType) {
					this.activeFilter = null;
					this.$emit('filter-kind', null); // Emit null to clear filter
					this.selectedType = 'Frontier';
				} else {
					this.activeFilter = filterType;
					this.$emit('filter-kind', filterType); // Emit the selected filter
				}
			},
		},
	};
</script>

<style scoped>
 
	.filter-item {
		cursor: pointer;
		margin: 0.25rem;
		padding: 0.25rem;
		border-radius: 5px;
		text-decoration: none;
	}
	/* .filter-item:hover, */
	.active-filter {
		background-color: #007bff;
		color: white;
	}
</style>

</style>
