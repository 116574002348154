<template id="search-global">
	<div class="searchglobal-container" @focusout="handleFocusOut" @focus="handleFocus" tabindex="0">
		<div class="modal-container-search text-left mx-auto" style="display: flex" @click.stop>
			<div class="card-header stickysearch pt-5 pb-5" style="flex: column">
				<!-- <button class="search-box-button" type="submit" @click="openSearch" @keyup.esc="clearAll()">  
					<i class="fa fa-search" aria-hidden="true"></i>
				</button> -->
				<span class="search-box-button">
					<i class="fa fa-search" aria-hidden="true"></i>
				</span>
				<input ref="searchBox" class="search-box-input t-14" :placeholder="placeholder" v-model="searchQuery" @keyup.esc="clearAll()" @focus="handleFocus" />
			</div>

			<template v-if="displayResults == 'active'">
				<div class="search-body bg-white text-dark search-body-global" style="flex: column">
					<div v-if="totalResults >= 0" class="p-2">
						<small class="text-extra-small">
							<em>Website search results: {{ totalResults }}</em>
							<span v-if="totalResults > 1000">+</span>
						</small>
					</div>
					<template v-for="portal in fetchedResults">
						<div v-if="portal.data.length > 0" :key="'portal-' + portal.section + '-' + portal.id" class="widen2">
							<div class="widen2">
								<div class="row p-2">
									<div class="col-12 col-md-2 col-lg-2 text-capitalize text-small p-2">
										{{ portal.label }}
									</div>
									<div class="col-12 col-md-10 col-lg-10">
										<div class="row border">
											<div class="col-4 p-2" v-for="(item, index) in portal.data" :key="'result-' + portal.id + '-' + index" :data-index="index">
												<a :href="url + '' + portal.section + '/' + item.id">{{ item.name }}</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import Vue2Filters from 'vue2-filters';

	export default {
		mixins: [Vue2Filters.mixin],
		props: ['section'],
		data: function () {
			return {
				showModal: false,

				form: {
					section: '',
					searchQuery: '',
					q: '',
				},
				currentSort: 'airline',
				displayResults: 'innactive',
				currentSortDir: 'asc',
				searchQuery: '',
				fetchedResults: [],
				section_singular: '',
				searchResults: '',
				totalResults: '',
				placeholder: 'Search aviation insights: Aircraft, Airlines, Fleets, Trends...',

				url: '//' + window.location.hostname + '/',
			};
		},
		created() {},
		mounted() {
			document.addEventListener('keypress', e => {
				const arrayOfStrings = ['edit', 'manager'];

				var urlpath = window.location.pathname;
				let result = urlpath.includes('manager');
			});
		},
		computed: {},
		methods: {
			openSearch: function () {
				const self = this;
			},
			close: function () {
				this.showModal = false;
			},

			clearAll: function () {
				this.fetchedResults = '';
				this.searchResults = '';
				this.searchQuery = '';
				this.displayResults = 'innactive';
			},

			getResults: function () {
				const self = this;
				if (self.searchQuery == '') {
					return;
				}
				if (this.hasSection(self.searchQuery)) {
					var section = self.searchQuery;
					section = section.match(/#[a-z]+/gi); // get the section/hashtag the user is searching
					if (section !== null) section = section[0];
				} else {
					var section = '';
				}

				var q = self.searchQuery;
				var regexp = /#\S+/g;
				q = q.replace(regexp, ''); // remove section/hashtag from the search query.

				self.form.searchQuery = self.searchQuery; // submit the search query
				self.form.section = section; // submit the section
				self.form.q = q; // add the query without the section

				var $url = '/search/1000';

				axios
					.post($url, self.form)
					.then(function (response) {
						self.fetchedResults = response.data.results;
						self.totalResults = response.data.total_result;

						self.displayResults = 'active';
						return response.data;
					})
					.catch(function (e) {
						console.log('Error' + e);
					});
			},

			handleFocus() {
				// if @input focus the totalResults >=1, display existing results.
				// This may be better than if searchQuery not empty because some searchQuery may be blank.
				if (this.totalResults >= 1) {
					this.displayResults = 'active';
				}
			},
			handleFocusOut(event) {
				// if the window clik is out of the focus area, hide sarch results.
				if (!event.currentTarget.contains(event.relatedTarget)) {
					this.displayResults = 'innactive';
				}
			},

			hasSection: function (inputElem) {
				return /[#\w*]/.test(inputElem.value);
			},
		},
		watch: {
			searchQuery: function () {
				if (this.searchQuery == '') {
					this.fetchedResults = [];
					this.displayResults = 'innactive';
					this.searchQuery = '';
					// this.showModal = false;
				} else {
					this.getResults();
				}
			},
		},
	};
</script>
