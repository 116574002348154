<template>
	<div class="container-fluid">
		<div class="sticky">
			<search-input placeholder="Find Conversions" v-model="filters.name.value" />

			<subcount>
				<slot></slot>
			</subcount>

			<div class="container d-block d-md-none text-center text-gray-5 t-22"><i class="fa-solid fa-left-right"></i></div>

			<div style="overflow-x: auto">
				<v-table :data="conversions" :filters="filters" class="table table-striped-white bg-light border">
					<thead slot="head" class="text-center bg-blue-5 text-light">
						<v-th sortKey="models" class="clickable w-220px">Models</v-th>
						<v-th sortKey="line" class="clickable w-300px">Line</v-th>
						<v-th sortKey="site" class="clickable w-200px">Site</v-th>
						<v-th sortKey="conversions" class="clickable w-200px">Conversions</v-th>
						<v-th sortKey="contractors" class="clickable w-200px">Contractors</v-th>
					</thead>
					<tbody slot="body" slot-scope="{ displayData }">
						<tr v-for="row in displayData" :key="row.id" class="text-center">
							<td>
								{{ row.models }}
							</td>

							<td><a :href="url + 'directories/conversions/' + row.id + ''">{{ row.line }}</a></td>
							<td>{{ row.site }}</td>

							<td>{{ row.conversions }}</td>
							<td>{{ row.contractors }}</td>
						</tr>
					</tbody>
				</v-table>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import DateFilters from './DateFilters';
	import AirTable from './AirTable';
	// import ChangesFilter from './ChangesFilter';
	import DevelopmentsFilter from './DevelopmentsFilter';
	import GeneralFilter from './GeneralFilter';

	export default {
		components: {
			DateFilters,
			AirTable,
			DevelopmentsFilter,
			GeneralFilter,
		},
		props: ['conversions', 'portal', 'tableFields'],
		data() {
			return {
				results: {},

				// totalCount: 0,
				// currentPage: 1,
				// totalPages: 1,
				// sortField: 'date',
				// sortOrder: 'desc',
				// countData: {},
				// pageWindowSize: 10,
				// filters: {},
				// dateLimitations: {},
				// isLoading: false,
				// eventFilter: null, // To store active event filter (Intent/Action)
				// firstLoad: true,
				filters: {
					name: {
						value: '',
						keys: ['models', 'conversions', 'site', 'line', 'contractors'],
					},
				},
			};
		},
		mounted() {
			this.results = this.aircraft;
		},
		created() {
			const minDate = moment()
				.subtract(365 * 4, 'days')
				.format('YYYY-MM-DD');
			const maxDate = moment().format('YYYY-MM-DD');

			this.dateLimitations = {
				minDate,
				maxDate,
			};

			this.fetchData(this.filters, 1);
		},
		methods: {
			handleSortChange({ sortField, sortOrder }) {
				this.sortField = sortField;
				this.sortOrder = sortOrder;
				this.fetchData(this.filters, this.currentPage);
			},

			paginatedPages() {
				let startPage = Math.max(1, this.currentPage - Math.floor(this.pageWindowSize / 2));
				startPage = Math.min(startPage, Math.max(1, this.totalPages - this.pageWindowSize + 1));
				return Array.from({ length: Math.min(this.pageWindowSize, this.totalPages) }, (v, i) => i + startPage);
			},

			applyFilters(newFilters) {
				if (!window.Airvita.isLoggedIn) return;

				this.filters = newFilters;
				this.fetchData(this.filters, 1);
			},

			applyEventsFilter(eventType) {
				this.eventFilter = eventType;
				this.fetchData(this.filters, 1);
			},
			fetchData(filters = {}, page = 1) {
				if (!window.Airvita.isLoggedIn) return;

				this.isLoading = true;

				// Prepare query parameters
				const queryParams = new URLSearchParams({
					...filters,
					page,
					sortField: this.sortField,
					sortOrder: this.sortOrder,
				});

				// Only add the event filter if it is either "Intent" or "Action"
				if (this.eventFilter) {
					queryParams.append('event', this.eventFilter);
				}

				axios
					.get(`/api/${this.portal}/get?${queryParams.toString()}`)
					.then(response => {
						this.results = response.data.results;
						this.totalPages = response.data.totalPages;
						this.totalCount = response.data.totalCount;
						this.currentPage = page;
						this.isLoading = false;

						if (this.firstLoad) {
							// this.intentCount = response.data.intentCount;
							// this.fleetCount = response.data.fleetCount;
							// this.statusCount = response.data.statusCount;
							this.firstLoad = false; // Update flag after the first load
						}
					})
					.catch(error => {
						console.error('Failed to fetch data:', error);
						this.results = [];
						this.totalCount = 0;
						this.totalPages = 0;
						this.currentPage = 1;
						this.isLoading = false;
					});
			},
			viewRecordDetails() {
				// Implement as needed
			},
		},
	};
</script>
