<template>
  <div class="subscription-box">
    <h3 class="panel-title">
      Your Subscription Plan: 
      <strong>
        <span v-model="plan_name" v-text="plan_name" >{{ plan_name }}</span><br>
      </strong>
    </h3>
    <h5 v-if=" subscription_expiration != '00-00-00' ">
        Renewal date: <span v-model="subscription_expiration" v-text="subscription_expiration" v-show="subscription_expiration">{{  subscription_expiration  }}</span>
    </h5>
    <form  action="/payments" method="POST" class="form-inline">
      <input type="hidden" name="stripeToken" v-model="stripeToken" >
      <input type="hidden" name="stripeEmail" v-model="stripeEmail" >
        <div class="form-group">
          <select class="" name="plan"  v-model="plan" class="form-control">
              <option v-for="plan in plans" :value="plan.id" >
                {{ plan.name }}  &mdash; ${{ plan.price/100 }}
              </option>
          </select>
        </div>
        <div class="form-group">
          <button type="submit" @click.prevent="subscribe" class="btn btn-primary">Update Subscription</button>
        </div>

      <p class="alert is-danger" v-show="status" v-text="status">{{ status }}</p>
    </form>
  </div>
</template>

<script>
 
    export default {
      props: ['plans', 'subscription'],

      data() {
          return {
            // activePlan: Subscription.activePlan,
            selectedPlan: Subscription.selectedPlan,
            subscription_expiration: this.subscription.subscription_end_at,
            stripeEmail: '',
            stripeToken: '',
            plan: Subscription.selectedPlan,
            plan_name: Subscription.plan_name,
            status: false
          }
      },

      created() {
        this.stripe = StripeCheckout.configure({
          key: Airvita.stripeKey,
          // image: "https://s3.amazonaws.com/stripe-uploads/acct_1L7u46JNl9d1DiXEYImgmerchant-icon-873222-luso-icon.png",
          locale: "auto",
          panelLabel: "Subscribe For",
          email: Airvita.user.email,
          token: (token) => {
              this.stripeToken = token.id;
              this.stripeEmail = token.email;

              axios.post('/payments', this.$data)
                .then(response => {
                  this.status =  'Complete! Thanks for subscribing.';
                  this.plan_name = response.data.plan_name; 
                })
                .catch(error =>{ 
                    this.status = error.response.data.status;  
                })
          }
        });
      }, 
      methods:{
        subscribe(){
            let plan = this.findPlanById(this.plan);
            this.stripe.open({
              name: plan.name,
              description: plan.description,
              zipCode: true,
              amount: plan.price
            });
        },
        convertDate() { 
            var originalDate = this.subscription_expiration;
//  let [month, date, year] =originalDate;
                var dateParts = originalDate.split("-");
                // console.log( );
                // var jsDate = new Date(dateParts[0], dateParts[1], dateParts[2]- 1, dateParts[2].substr(0,2));    
                var jsDate = new Date(  );    
     
              this.subscription_expiration = dateParts[1]+"-"+dateParts[2].substr(0,2)+"-"+dateParts[0];   
        },

        findPlanById(id) { 
          return this.plans.find(plan => plan.id == id );
        }
      },
      mounted() { 
        this.plan = this.selectedPlan;
        if ( this.activePlan == 0 && this.selectedPlan == "") {
          this.subscribe();
        }
        this.convertDate();
      }

    }
</script>
