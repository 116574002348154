<template>
	<div class="container-fluid pl-15 pr-15 pt-15 pb-0">
		<form @submit.prevent="applyFilters">
			<!-- Date Filters -->
			<div class="row justify-content-md-center text-center pt-15 pb-15">
				<!-- Start Date -->
				<div class="col-4 col-lg-2">
					<div class="row">
						<div class="col">Start</div>
					</div>
					<div class="row">
						<div class="col">
							<datepicker v-model="filter.takeoff_start" format="yyyy-MMM-dd" input-class="form-control small bg-light" placeholder="YYYY MMM DD" :typeable="true" :class="{ error: errors.takeoff_start }" ref="takeoffTimeInput" @selected="validateField('takeoff_start')" :disabled-dates="disabledDates"></datepicker>

							<button v-if="filter.takeoff_start" type="button" @click="clearField('takeoff_start')" class="text-center border-0 btn-clear-field">x</button>
						</div>
					</div>
				</div>

				<!-- Finish Date -->
				<div class="col-4 col-lg-2">
					<div class="row">
						<div class="col">Finish</div>
					</div>
					<div class="row">
						<div class="col">
							<datepicker v-model="filter.takeoff_finish" format="yyyy-MMM-dd" input-class="form-control small bg-light" placeholder="YYYY MMM DD" :typeable="true" :class="{ error: errors.takeoff_finish }" @selected="validateField('takeoff_finish')" :disabled-dates="disabledDates"></datepicker>

							<button v-if="filter.takeoff_finish" type="button" @click="clearField('takeoff_finish')" class="text-center border-0 btn-clear-field">x</button>
						</div>
					</div>
				</div>

				<!-- Days -->
				<div class="col-3 col-lg-2">
					Days
					<input type="text" v-model="days" class="form-control" />
				</div>
			</div>

			<!-- Last Update Display -->
			<!-- <div v-if="lastupdate" class="row text-center justify-content-center">
				<div class="col">
					<h3 class="m-5 text-center text-gray-4 t-14">Updated {{ lastupdate }}</h3>
				</div>
			</div> -->

			<!-- Search Count -->
			<div class="row text-center mt-5 justify-content-center">
				<div class="col">
					<h3 class="mt-15 mb-15 text-center">Search ({{ totalFields }})</h3>
				</div>
			</div>

			<!-- Dynamic Filter Fields -->
			<div class="row text-center mb-5">
				<div v-for="field in filters_fields" :key="field.key" :class="'p-5 ' + field.col + (field.hidden === true ? ' hidden' : '')">
					<div v-show="!field.hidden">
						<!-- Field Label -->
						<div class="row text-center">
							<div class="col" v-if="field.link">
								<a :href="url + field.link">{{ field.label }}</a>
							</div>
							<div v-else class="col">
								{{ field.label }}
							</div>
						</div>
						<!-- Field Input -->
						<div class="row">
							<div v-if="field.type === 'datepicker'" class="col">
								<datepicker v-model="filter[field.key]" format="yyyy-MMM-dd" input-class="form-control small bg-light" placeholder="YYYY MMM DD" :typeable="true" :class="{ error: errors[field.key] }" @selected="validateField(field.key)" :disabled-dates="disabledDates"></datepicker>
								<button v-if="filter[field.key]" type="button" @click="clearField(field.key)" class="text-center border-0 btn-clear-field">x</button>
							</div>
							<div v-else class="col">
								<div style="position: relative">
									<input type="text" v-model="filter[field.key]" :placeholder="field.placeholder" class="form-control" @input="validateField(field.key)" @keyup.enter="applyFilters" />
								</div>
								<button v-if="filter[field.key]" type="button" @click="clearField(field.key)" class="border-0 text-12 text-gray-6 text-center btn-clear-field">x</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Group By Options -->
			<div class="row mt-10 justify-content-md-center text-center" v-if="showGroupBy">
				<div class="col text-center">
					<div class="container">
						<div class="row text-center justify-content-center">
							<div class="col-6 col-lg-1 text-center">
								<label class="form-label">Fleets</label>
								<input type="radio" value="fleets" v-model="filter.groupBy" class="mx-auto" @click="emitGroupBy" />
							</div>
							<div class="col-6 col-lg-1 text-center">
								<label class="form-label">Routes</label>
								<input type="radio" value="routes" v-model="filter.groupBy" class="mx-auto" @click="emitGroupBy" />
							</div>
							<div class="col-1 text-center" v-if="NoGroupBy === 'true'">
								<label class="form-label">None</label>
								<input type="radio" value="no" v-model="filter.groupBy" class="mx-auto" @click="emitGroupBy" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Error Message -->
			<div class="text-center">
				{{ error_message }}
			</div>

			<!-- Divider -->
			<div class="row mt-15" style="border-top: 2px solid #2f74b5"></div>

			<!-- Results Count -->
			<div class="row text-center mt-5 justify-content-center">
				<div class="col">
					<h3 class="mt-15 mb-15 text-center">Results ({{ totalCount.toLocaleString() }})</h3>
				</div>
			</div>

			<!-- Additional Filters Indicator (if any) -->
			<div class="row text-center mt-5 justify-content-center mb-10" v-if="filter.takeoff_start || filter.takeoff_finish || filter.airline_name || filter.ac_type_icao || filter.airline_icao || filter.ac_role"></div>
		</form>
	</div>
</template>

<script>
	import Datepicker from 'vuejs-datepicker';
	import moment from 'moment';
	import axios from 'axios'; // Ensure axios is imported

	export default {
		components: { Datepicker },
		props: {
			page: '',
			dateLimitations: {
				type: Object,
				required: true,
			},
			filters_fields: {
				type: Array,
				default: () => [],
			},
			filtersheading: String,
			results: {
				type: Array,
				default: () => [],
			},
			NoGroupBy: '',
			showGroupBy: true,
			totalCount: 0,
			filledfields: {
				type: Object,
				default: () => ({}), // Default to an empty object
			},
		},
		data() {
			return {
				lastupdate: '',
				total_cycles: 0,
				total_hours: 0,
				total_ratio: 0,

				filter: {
					takeoff_start: '',
					takeoff_finish: '',
					groupBy: this.page,
				},
				errors: {
					takeoff_start: false,
					takeoff_finish: false,
					// Dynamic fields will be handled in methods
				},
				error_message: '',
				searchPerformed: false,
				portal: '',
				url: '//' + window.location.hostname + '/',
			};
		},
		computed: {
			formattedFilter() {
				return {
					...this.filter,
					takeoff_start: this.filter.takeoff_start ? moment(this.filter.takeoff_start).format('YYYY-MM-DD') : '',
					takeoff_finish: this.filter.takeoff_finish ? moment(this.filter.takeoff_finish).format('YYYY-MM-DD') : '',
				};
			},
			totalFields() {
				// Count how many fields have values, excluding dates and groupBy
				return Object.keys(this.filter).filter(key => this.filter[key] && key !== 'takeoff_start' && key !== 'takeoff_finish' && key !== 'groupBy').length;
			},
			days() {
				if (this.filter.takeoff_start || this.filter.takeoff_finish) {
					const start = moment(this.filter.takeoff_start);
					const end = moment(this.filter.takeoff_finish);

					if (start.isValid() && end.isValid()) {
						return end.diff(start, 'days');
					}
				}
				return 0;
			},
			filteredFilter() {
				return Object.keys(this.filter)
					.filter(key => key !== 'takeoff_start' && key !== 'takeoff_finish' && key !== 'groupBy')
					.reduce((obj, key) => {
						if (this.filter[key] !== '') {
							obj[key] = this.filter[key];
						}
						return obj;
					}, {});
			},
			disabledDates() {
				const { minDate, maxDate } = this.dateLimitations;

				const disabledDates = {
					to: moment(minDate).toDate(),
					from: moment(maxDate).toDate(),
				};

				return disabledDates;
			},
		},
		created() {
			this.filter.groupBy = this.page;

			const { minDate, maxDate } = this.dateLimitations;

			let today = new Date();
			this.filter.takeoff_start = new Date(new Date().setDate(today.getDate() - 90));
			this.filter.takeoff_finish = maxDate;

			// this.filter.tail_number = this.filledfields.regn ?? '';
			// this.filter.ac_msn = this.filledfields.serial ?? '';

			// if (this.filter.tail_number || this.filter.ac_msn) this.applyFilters();
		},

		mounted() {
			this.fetchLastUpdate(); // Fetch last update when component loads
		},
		watch: {
			results: {
				handler(newResults) {
					this.calculatePerformance(newResults);
				},
				deep: true,
			},
		},

		methods: {
			async fetchLastUpdate() {
				try {
					this.loadingCounts = true;
					const response = await axios.get(`/api/flights/lastupdate`);
					this.lastupdate = moment(response.data.results.created_at).utc().format('YYYY MMM DD - HH:mm [UT]');
				} catch (error) {
					console.error('Error fetching last update:', error);
				} finally {
					this.loadingCounts = false;
				}
			},

			clearField(key) {
				// Clear the specified filter field
				this.filter[key] = '';

				// Reset validation for the cleared field
				this.validateField(key);

				// Recalculate the number of active filter fields
				const activeFields = this.totalFields;

				if (activeFields > 0) {
					// If there are still active fields, apply the filters
					this.applyFilters();
				} else {
					// If no active fields, do not apply filters
					// Optionally, reset error messages or other states
					this.error_message = ''; // Clear any existing error messages
					this.searchPerformed = false; // Reset search status

					// Optionally, emit an event to inform the parent component
					// that filters have been cleared completely
					this.$emit('filters-cleared');
				}
			},

			applyFilters() {
				this.error_message = '';

				// Check if there are active filter fields
				if (this.totalFields === 0) {
					// No active fields; do not proceed with filtering
					console.warn('No active filter fields. Skipping applyFilters.');
					return;
				}

				if (!this.allFieldsValid()) {
					this.error_message = 'Please input Start, Finish, and at least one other field.';
					return;
				}

				this.searchPerformed = true;
				this.$emit('apply-filters', this.formattedFilter); // formattedFilter instead of filter to prevent the datepicker from switching the date format.
			},

			validateField(key) {
				// Dynamically initialize error state if it doesn't exist
				if (!this.errors.hasOwnProperty(key)) {
					this.$set(this.errors, key, false);
				}
				this.errors[key] = !this.filter[key];
			},

			allFieldsValid() {
				const datesValid = this.filter.takeoff_start && this.filter.takeoff_finish;
				const atLeastOneOtherField = this.totalFields > 0;
				const groupSelected = this.filter.groupBy ? this.filter.groupBy : 'no';

				return datesValid && atLeastOneOtherField && groupSelected;
			},

			focusFirstInvalidField() {
				const firstInvalidKey = Object.keys(this.errors).find(key => this.errors[key]);
				if (firstInvalidKey && this.$refs[firstInvalidKey]) {
					this.$refs[firstInvalidKey].focus();
				}
			},

			calculatePerformance(results) {
				this.total_cycles_calc = 0;

				results.forEach(flight => {
					if (flight.freq) {
						this.total_cycles_calc += parseInt(flight.freq, 10);
					}
				});

				this.total_cycles = this.total_cycles_calc.toLocaleString();

				this.total_hours_calc = results.reduce((sum, result) => sum + parseFloat(result.hours || 0), 0);

				this.total_hours = this.total_hours_calc.toLocaleString(undefined, {
					minimumFractionDigits: 1,
					maximumFractionDigits: 1,
				});

				this.total_ratio = this.total_cycles ? (this.total_hours_calc / this.total_cycles_calc).toFixed(1) : 0;
			},

			emitGroupBy() {
				this.$emit('changePage', this.filter.groupBy);
			},
		},
	};
</script>

<style scoped>
	.error {
		border: 2px solid red;
		box-shadow: 2px 2px 2px solid red;
	}

	button {
		margin-top: 5px;
		padding: 5px 10px;
		/* background-color: #f9f9f9; */
		border: 1px solid #ccc;
		cursor: pointer;
	}
	button:hover {
		background-color: #e2e2e2;
	}
	.error {
		border: 2px solid red;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	}

	.vdp-datepicker .vdp-datepicker__clear-button {
		border: 1px solid #ccc !important;
		padding: 5px 10px !important;
		color: #888888 !important;
		text-transform: none;
	}

	.hidden {
		display: none;
	}
</style>
