<template id="airlines">
	<div class="test">
		<search-input placeholder="Find Airline" v-model="filters.name.value" />

		<v-table :data="airlines" :filters="filters" class="table bg-light border">
			<thead slot="head" class="text-center">
				<tr>
					<v-th scope="col" sortKey="airline" class="clickable text-center">Airline</v-th>
					<v-th scope="col" sortKey="icao" class="clickable text-center">Codes</v-th>
					<v-th scope="col" sortKey="status" lass="clickable text-center">Status</v-th>
					<v-th scope="col" sortKey="service" class="clickable text-center">Service</v-th>
					<v-th scope="col" sortKey="country" class="clickable text-left">Domicile</v-th>
				</tr>
			</thead>
			<tbody slot="body" slot-scope="{ displayData }">
				<tr v-for="item in displayData" :key="item.id" class="text-center">
					<!-- <tr v-for="item in filterBy(filteredList, searchQuery)" :key="item.id"> -->
					<td v-if="item.airline">
						<a :href="url + 'fleets/' + item.id + ''">{{ item.airline }}</a>
					</td>
					<td v-else></td>
					<td v-if="item.icao != '' || item.iata != ''">{{ item.icao }} {{ item.iata }}</td>
					<td v-else></td>
					<td v-if="item.status">{{ item.status }}</td>
					<td v-else></td>
					<td v-if="item.service">{{ item.service }}</td>
					<td v-else></td>
					<!-- <td   v-if=" item.airlinesfleet ">
                <span v-for="aircraft in item.airlinesfleet " :key="aircraft.id" >
                    <small>{{ aircraft.aircraft.model }}</small> &nbsp;
                </span>
            </td><td v-else></td>    -->
					<td v-if="item.country_id != '' && item.country" class="text-left">
						<img
							v-bind:src="url + 'flags/blank.gif'"
							:class="'flag flag-' + getCountryFlagCode(item.country)"
							:alt="item.country"
							:title="item.country"
						/>
						{{ item.country }}
					</td>
					<td v-else class="col"></td>
				</tr>
			</tbody>
		</v-table>
	</div>
</template>

<script>
	import Vue2Filters from 'vue2-filters';

	export default {
		mixins: [Vue2Filters.mixin],
		props: ['airlines'],
		data: function () {
			return {
				currentSort: 'airline',
				currentSortDir: 'asc',
				searchQuery: '',
				filteredList: this.airlines,
				url: '//' + window.location.hostname + '/',
				filters: {
					name: {
						value: '',
						keys: ['code', 'variants', 'engines', 'units'],
					},
				},
			};
		},
		created() {
			this.mylist = this.airlines;
		},
		methods: {
			morethan() {
				return;
			},
			filterMyList(value) {
				this.filteredList = value;
			},
		},
	};
</script>
