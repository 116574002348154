<template id="events">
	<div class="events-container">
		<EventsFilter :last-year-events-count="lastYearEventsCount" :current-year-events-count="currentYearEventsCount" :next-year-events-count="nextYearEventsCount" @filter-events="applyEventsFilter" />

		<search-input placeholder="find event" v-model="searchQuery" />

		<div style="overflow-x: auto">
			<v-table :data="filteredResults" :currentPage.sync="currentPage" :pageSize="80" @totalPagesChanged="totalPages = $event" class="table table-striped border fleet-flight-table bg-lights" :defaultSortKey="defaultSortKey">
				<thead slot="head" class="text-center bg-blue-5 text-light">
					<v-th sortKey="event" class="w-350px">Event</v-th>
					<v-th sortKey="city" class="w-150px">City</v-th>
					<v-th sortKey="venue" class="w-300px">Venue</v-th>
					<v-th sortKey="country.domicile" class="w-200px text-center clickable">Domicile</v-th>
					<v-th sortKey="start_date" class="w-150px">Start</v-th>
					<v-th sortKey="finish_date" class="w-150px">Finish</v-th>
				</thead>
				<tbody slot="body" slot-scope="{ displayData }">
					<tr v-for="row in displayData" :key="row.id" class="text-center">
						<td>
							<a :href="url + 'directories/events/' + row.id">{{ row.event }}</a>
						</td>
						<td>{{ row.city ?? '' }}</td>
						<td>{{ row.venue ?? '' }}</td>
						<td v-if="row.country_id && row.country" class="text-center">
							<img :src="url + 'flags/blank.gif'" :class="'flag flag-' + getCountryFlagCode(row.country.domicile)" :alt="row.country.domicile" :title="row.country.domicile" />
							{{ row.country.domicile }}
						</td>
						<td v-else></td>
						<td>{{ customDisplayDate(row.start_date) }}</td>
						<td>{{ customDisplayDate(row.finish_date) }}</td>
					</tr>
				</tbody>
			</v-table>

			<smart-pagination :currentPage.sync="currentPage" :totalPages="totalPages" :maxPageLinks="16" />
		</div>
	</div>
</template>

<script>
	import EventsFilter from './EventsFilter.vue';

	export default {
		components: {
			EventsFilter,
		},
		props: {
			results: {
				type: Array,
				default: () => [],
			},
			lastYearEventsCount: {
				type: Number,
				default: 0,
			},
			currentYearEventsCount: {
				type: Number,
				default: 0,
			},
			nextYearEventsCount: {
				type: Number,
				default: 0,
			},
		},
		data() {
			return {
				currentPage: 1,
				totalPages: 0,
				defaultSortKey: 'start_date',
				defaultSortDirection: 'asc',
				searchQuery: '',
				url: '//' + window.location.hostname + '/',
				selectedYearFilter: null,
			};
		},
		methods: {
			applyEventsFilter(eventType) {
				this.selectedYearFilter = eventType;
			},
			customDisplayDateFormat(date) {
				// Implement your date formatting logic here
				return date;
			},
			getCountryFlagCode(countryName) {
				// Implement your logic to get country flag code here
				return countryName.toLowerCase();
			},
		},
		computed: {
			filteredResults() {
				let filtered = this.results;

				// Filter by selectedYearFilter
				if (this.selectedYearFilter) {
					const now = new Date();
					let startDate, endDate;

					if (this.selectedYearFilter === 'previousYear') {
						// From January 1st to December 31st of last year
						const lastYear = now.getFullYear() - 1;
						startDate = new Date(lastYear, 0, 1, 0, 0, 0, 0); // Jan 1st of last year
						endDate = new Date(lastYear, 11, 31, 23, 59, 59, 999); // Dec 31st of last year
					} else if (this.selectedYearFilter === 'currentYear') {
						// From January 1st to December 31st of current year
						const currentYear = now.getFullYear();
						startDate = new Date(currentYear, 0, 1, 0, 0, 0, 0); // Jan 1st of current year
						endDate = new Date(currentYear, 11, 31, 23, 59, 59, 999); // Dec 31st of current year
					} else if (this.selectedYearFilter === 'nextYear') {
						// From January 1st to December 31st of next year
						const nextYear = now.getFullYear() + 1;
						startDate = new Date(nextYear, 0, 1, 0, 0, 0, 0); // Jan 1st of next year
						endDate = new Date(nextYear, 11, 31, 23, 59, 59, 999); // Dec 31st of next year
					}

					filtered = filtered.filter(event => {
						const eventDate = new Date(event.start_date);
						return eventDate >= startDate && eventDate <= endDate;
					});
				} else {
					const now = new Date();
					let startDate, endDate;
					// From January 1st to December 31st of current year
					const currentYear = now.getFullYear();
					startDate = new Date(now); // Today's date
					endDate = new Date(currentYear, 11, 31, 23, 59, 59, 999); // Dec 31st of current year

					filtered = filtered.filter(event => {
						const eventDate = new Date(event.start_date);
						return eventDate >= startDate && eventDate <= endDate;
					});
				}
				// Apply search filter
				if (this.searchQuery) {
					const search = this.searchQuery.toLowerCase();
					filtered = filtered.filter(event => {
						return (event.event && event.event.toLowerCase().includes(search)) || (event.city && event.city.toLowerCase().includes(search)) || (event.venue && event.venue.toLowerCase().includes(search)) || (event.country && event.country.domicile && event.country.domicile.toLowerCase().includes(search)) || (event.start_date && event.start_date.toLowerCase().includes(search)) || (event.finish_date && event.finish_date.toLowerCase().includes(search));
					});
				}

				return filtered;
			},
		},
	};
</script>
