<template id="media-center">
	<!-- <li class="nav-row dropdown "> -->
	<div class="container-fluid">
		<search-input placeholder="Find Placements" v-model="filters.name.value" />

		<div style="width: 100%; overflow-x: scroll">
			<v-table :data="filteredList" :filters="filters" class="table table-light border">
				<thead slot="head" class="text-center">
					<v-th sortKey="airline.airline" class="w-220px clickable">Lessor</v-th>
					<v-th sortKey="lessee.airline" class="w-220px clickable">Lessee</v-th>
					<v-th sortKey="aircraft.model" class="w-100px clickable">Aircraft</v-th>
					<v-th sortKey="configuration" class="w-100px clickable">Cabin</v-th>
					<!-- <v-th sortKey="units" class="w-100px clickable">Units</v-th> -->
					<v-th sortKey="start_date" class="w-10 clickable">Start</v-th>
					<v-th sortKey="finish_date" class="w-10 clickable">Finish</v-th>
					<v-th sortKey="serial" class="w-10 clickable">Serial</v-th>
					<v-th sortKey="registration" class="w-10 clickable">Registration</v-th>
				</thead>
				<tbody slot="body" slot-scope="{ displayData }">
					<tr v-for="row in displayData" :key="row.id" class="text-center">
						<td v-if="row.airline_id">
							<a :href="'airlines/' + row.airline.id">{{ row.airline.airline }}</a>
						</td>
						<td v-else></td>
						<td v-if="row.lessee_id">
							<a :href="'airlines/' + row.lessee.id">{{ row.lessee.airline }}</a>
						</td>
						<td v-else></td>
						<td v-if="row.aircraft_id">
							<a :href="'aircraft/' + row.aircraft_id">{{ row.aircraft.model }}</a>
						</td>
						<td v-else></td>
						<td>{{ row.configuration ?? '' }}</td>
						<!-- <td>{{ row.units ?? '' }}</td> -->
						<td v-if="row.start_date">
							{{ customDisplayDate(row.start_date, 'YYYY MMM') }}
						</td>
						<td v-else></td>

						<td v-if="row.finish_date">
							{{ customDisplayDate(row.finish_date, 'YYYY MMM') }}
						</td>
						<td v-else>Present</td>
						<td>
							<a :href="'flights?serial=' + row.serial">{{ row.serial ?? '' }}</a>
						</td>
						<td>
							<a :href="'flights?regn=' + row.registration">{{ row.registration ?? '' }}</a>
						</td>
					</tr>
				</tbody>
			</v-table>

			<smart-pagination :currentPage.sync="currentPage" :totalPages="totalPages" :maxPageLinks="16" />
		</div>
	</div>
</template>

<script>
	import _ from 'lodash';
	import Vue2Filters from 'vue2-filters';

	export default {
		mixins: [Vue2Filters.mixin],

		components: {},
		props: ['charters', 'aircraft', 'airlines'],
		data() {
			return {
				image: '',
				errors: [],

				currentPage: 1,
				totalPages: 0,

				filters: {
					name: {
						value: '',
						keys: ['airline.airline', 'lessee.airline', 'aircraft.model', 'airline.airline', 'configuration', 'units', 'serial', 'registration'],
					},
				},
				section: 'charters',
				searchQuery: '',
				filteredList: this.charters,
				url: '//' + window.location.hostname + '/',
			};
		},
		created() {},

		watch: {},

		methods: {},

		mounted() {},
	};
</script>
