<template>
	<div class="p-0 m-0">
		<div class="row p-0 mb-10">
			<div class="col bg-light text-center text-blue-5 pl-25 pr-25">
				<FlightsFiltersNoDates @apply-filters="applyFilters" :filters_fields="filters_fields" :results="results" :totalCount="totalCount" filtersheading="Enter one item" :date-limitations="dateLimitations" :page="groupBy" :showGroupBy="false" :filledfields="filled"></FlightsFiltersNoDates>
			</div>
		</div>
		<div class="row p-0">
			<div class="col bg-light text-center text-blue-5 pl-25 pr-25 pt-25">
				<div v-if="fleetList">
					<AirTable :fields="tableFields" :data="results" :totalPages="totalPages" :totalCount="totalCount" :currentPage="currentPage" :sortField="sortField" :sortOrder="sortOrder" :enableCounts="true" :countRow="countData" @rowSelected="viewRecordDetails" @page-changed="fetchData(filters, $event)" @sort-changed="handleSortChange" ref="airTableRef" />
				</div>

				<div class="sep"></div>

				<div v-if="flightsProfile">
					<flight-details :flight="selectedFlight" @close="close"></flight-details>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import AirTable from './AirTable';
	import FleetsFilters from './FleetsFilters';
	import FlightsFilters from './FlightsFilters';
	import FlightsFiltersNoDates from './FlightsFiltersNoDates';
	import FleetsFlightsTable from './FleetsFlightsTable';
	import FlightDetails from './FlightDetails';

	export default {
		components: {
			AirTable,
			FleetsFilters,
			FlightsFilters,
			FleetsFlightsTable,
			FlightDetails,
			FlightsFiltersNoDates,
		},
		props: {
			plan: String,
			accesslimit: String,
			filled: {
				type: Object,
				default: () => ({}), // Default to an empty object
			},
			aircraft: {
				tyoe: Object,
				default: () => ({}),
			},
		},
		data() {
			return {
				// icaoCode: '',
				// filter: {},
				filters: {},
				results: [],
				totalCount: 0,
				currentPage: 1,
				totalPages: 0,
				sortField: 'ac_msn',
				sortOrder: 'asc',
				countData: {},
				pageWindowSize: 10,
				abortController: null,
				loadingCounts: false,

				// below handles the click to display or hide list or profile
				fleetList: true,
				groupBy: 'fleets',
				selectedFlight: {},
				flightsProfile: false,

				dateLimitations: {
					minDate: '',
					maxDate: '',
				},

				filters_fields: [
					{
						label: 'Aircraft',
						placeholder: 'code-4',
						key: 'ac_type_icao',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Regn',
						placeholder: 'registration',
						key: 'tail_number',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Serial',
						placeholder: 'msn',
						key: 'ac_msn',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Model',
						placeholder: 'model',
						key: 'ac_type_icao',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Airline',
						placeholder: 'name',
						key: 'airline_name',
						secondkey: 'airline_name',
						thirdkey: 'airline_iata',
						col: 'col',
						type: 'string',
					},

					{
						label: 'Codes',
						placeholder: 'ICAO IATA',
						key: 'airline_icao',
						col: 'col',
						type: 'string',
					},
				],
			};
		},
		async created() {
			const plan = this.plan;

			const now = moment();
			let minDate = moment();
			let maxDate = moment();

			minDate = this.accesslimit;

			this.dateLimitations = {
				minDate: minDate,
				maxDate: maxDate.format('YYYY-MM-DD'),
			};
		},
		computed: {
			tableFields() {
				return [
					{ key: 'aircraft_model', label: 'Aircraft' }, // replaces ac_type_icao
					{ key: 'tail_number', label: 'Registration' },
					{ key: 'ac_msn', label: 'Serial' },
					{ key: 'ac_type_icao', label: 'Model' },
					{ key: 'airline_name', label: 'Airline', type: 'no-wrap' },
					{ key: 'airline_icao', label: 'Codes' },
				];
			},
		},
		methods: {
			applyFilters(newFilters) {
				// console.log(newFilters);
				this.filters = newFilters;
				this.fetchData(this.filters, 1);
			},
			fetchData(filters, page) {
				// page = page || this.currentPage;

				this.$refs.airTableRef.startLoading();

				if (this.abortController) {
					this.abortController.abort();
				}
				this.abortController = new AbortController();
				let signal = this.abortController.signal;

				const queryParams = new URLSearchParams({
					...filters,
					page: page,
					sortField: this.sortField,
					sortOrder: this.sortOrder,
				}).toString();

				axios
					.get(`/api/aircraftfleets/data?${queryParams}`, { signal })
					.then(response => {
						this.results = response.data.results;
						this.totalPages = response.data.totalPages;
						this.totalCount = response.data.totalCount;
						this.currentPage = page;

						if (page === 1) {
							this.fetchCounts(queryParams);
						}
						this.$refs.airTableRef.stopLoading();
					})
					.catch(error => {
						if (axios.isCancel(error)) {
							console.log('Request canceled', error.message);
						} else {
							console.error('Error:', error);
						}
						this.$refs.airTableRef.stopLoading();
					});
			},

			fetchCounts(queryParams) {
				const self = this;
				self.loadingCounts = true;
				axios
					.get(`/api/aircraftfleets/counts?${queryParams}`)
					// .get(`/api/aircraftfleets/counts?icaoCode=${icaoCode}`)
					.then(response => {
						self.countData = response.data;
						self.loadingCounts = false;
					})
					.catch(error => {
						console.error('Error fetching counts:', error);
						self.loadingCounts = false;
					});
			},

			handleSortChange({ sortField, sortOrder }) {
				this.sortField = sortField;
				this.sortOrder = sortOrder;
				this.fetchData(this.filters, this.currentPage);
			},
			navigate(page) {
				this.$emit('pageChanged', page); // Emit only the page number
			},
			viewRecordDetails(result) {},
			close() {
				this.selectedFlight = [];
				this.fleetList = true;
				this.flightsProfile = false;
			},
		},
	};
</script>
