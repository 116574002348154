<template id="bulletins-manager">
    <div class="bulletin-container bulletin text-center px-2" style=" background: #DAE1F2; z-index: 999999;"> 
          
        
        <form method="POST" @submit.prevent="registerNewUser"  >

        <h5>To view AirLibrary Weekly Bulletin, please register</h5>

        <p v-if=" errors.length" class="text-center">
        <b>Please correct the following error(s):</b>
        <ul class="text-danger">
            <li v-for="error in errors" :key="error">{{ error }}</li>
        </ul>
        </p> 

        <div class="form-group row">  
            <input type="text" v-model="form.full_name"  name="full_name"  class="form-control text-center "  placeholder="First Name">
        </div>  
        <div class="form-group row">  
            <input type="email" v-model="form.email"  name="email"  class="form-control  text-center"  placeholder="Email">
        </div> 
        <div class="form-group row text-center">  
            <input type="text" v-model="form.company"  name="company"  class="form-control  text-center"  placeholder="Company">
        </div> 
        <div class="form-group row">  
            <input v-if="showPassword" type="text" v-model="form.password"  name="password"  class="form-control  text-center"  placeholder="Password" style=" width: 88%">
            <input v-else type="password" v-model="form.password"  name="password"  class="form-control  text-center"  placeholder="Password" style=" width: 88%">
 
            <div class="control text-center">
                <a class="form-control btn-sm pt-2 icon is-small is-right"  @click="toggleShow">
                    <span v-show="showPassword"><i class="fa fa-eye-slash"></i></span>
                    <span v-show=" !showPassword "><i class="fa fa-eye"></i></span>
                </a> 
            </div>
        
        </div>  
        <div class="form-group row text-center ">  
            <div class="col">
                Check here &nbsp; <input type="checkbox" v-model="form.bulletincheck" name="form.bulletincheck"  checked  /> &nbsp; if you wish to receive a weekly issue.
            </div>
        </div> 

        <div class="col text-center">
            <input type="hidden" v-model="form.bulletinform"  name="bulletinform"  value="1">
            <button type="submit" class="modal-default-button btn btn-primary btn-purple">Register</button> 
            <br>
            <a class="text-sm" href="/login">Already registered?</a>
        </div>
        </form>
    </div>  
</template>
 
<script> 

 
export default{

  props: [  ],
    data: function () {
        return { 
            errors: []
             
            , form: { 
                full_name: ''  
                , email: ''  
                , company: ''  
                , password: ''  
                , password_confirmation: ''  
                , bulletincheck: true
                , bulletinform: true 
                , _method: '' 
            } 
            , showPassword: false
            , showModal: false
            , modalTitle: ''
            
            , url: '//'+window.location.hostname+'/'
              
        };
    }, 
    created () {   
        
    },
    
    watch: {
         
       
    }, 
    computed: {
        
           
    }, 
    methods: {  

        modalRegister: function() 
        {
           this.showModal = true ;
           this.modalTitle = "Register";
        },

        toggleShow() {
            this.showPassword = !this.showPassword;
        }, 
 
        registerNewUser: function(event) {
 
            const self = this;
 
            if (!self.checkForm(self)){
                return false;
            }
         
            self.form._method = "post";
            var url = "/access";
            
            axios.post( url ,  self.form )
            .then(function(response){
                    
                    self.showModal = false;
                 
                    self.resetForm(self.form);
 
                    window.location.href =  "/bulletin";
                    
                    return;
        
            })
            .catch( function(error, response){  
                 
                self.errors.push(error.response.data.message);
                

            }); 
                
            

        },
         
        
        checkForm: function (self) {

            if ( self.form.full_name && self.form.email  && self.form.password  ) {
                self.errors = [];
                return true;
            }
 
            self.errors = [];
 
            if (!self.form.full_name) {
                self.errors.push('First Name is required.');
            }  
            if (!self.form.email) {
                self.errors.push('Email is required.');
            } 
            if (!self.form.password) {
                self.errors.push('Password is required.');
            }
             
        }, 
        toTitleCase: function(str) {
            
            var newCapitalCaseWord = str.replace(
            /\w\S*/g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
            
        return newCapitalCaseWord;
        
        }
         
        , redirect: function(url) {
            history.pushState( {}, null, '/' + url );
        }, 
    },

    mounted() {

    },
    beforeDestroy() {
        this.editor.destroy()
    },
}
</script>
 