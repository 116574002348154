<template>
	<div class="container-fluid pl-15 pr-15 pt-15 pb-20">
		<form @submit.prevent="applyFilters">
			<!-- Dynamic Filter Fields -->
			<div class="row text-center mb-5">
				<div v-for="field in filters_fields" :key="field.key" :class="'p-5 ' + field.col + (field.hidden === true ? ' hidden' : '')">
					<div v-show="!field.hidden">
						<!-- Field Label -->
						<div class="row text-center">
							<div class="col" v-if="field.link">
								<a :href="url + field.link">{{ field.label }}</a>
							</div>
							<div v-else class="col">
								{{ field.label }}
							</div>
						</div>
						<!-- Field Input -->
						<div class="row">
							<div class="col">
								<div style="position: relative">
									<input type="text" v-model="filter[field.key]" :placeholder="field.placeholder" class="form-control" @input="validateField(field.key)" @keyup.enter="applyFilters" />
								</div>
								<button v-if="filter[field.key]" type="button" @click="clearField(field.key)" class="border-0 text-12 text-gray-6 text-center btn-clear-field">x</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Group By Options -->
			<div class="row mt-10 justify-content-md-center text-center" v-if="showGroupBy">
				<div class="col text-center">
					<div class="container">
						<div class="row text-center justify-content-center">
							<div class="col-6 col-lg-1 text-center">
								<label class="form-label">Fleets</label>
								<input type="radio" value="fleets" v-model="filter.groupBy" class="mx-auto" @click="emitGroupBy" />
							</div>
							<div class="col-6 col-lg-1 text-center">
								<label class="form-label">Routes</label>
								<input type="radio" value="routes" v-model="filter.groupBy" class="mx-auto" @click="emitGroupBy" />
							</div>
							<div class="col-1 text-center" v-if="NoGroupBy === 'true'">
								<label class="form-label">None</label>
								<input type="radio" value="no" v-model="filter.groupBy" class="mx-auto" @click="emitGroupBy" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Error Message -->
			<div class="text-center">
				{{ error_message }}
			</div>

			<!-- Results Count -->
			<!-- <div class="row text-center mt-5 justify-content-center">
				<div class="col">
					<h3 class="mt-15 mb-15 text-center">Results ({{ totalCount.toLocaleString() }})</h3>
				</div>
			</div> -->
		</form>
	</div>
</template>

<script>
	export default {
		props: {
			filters_fields: {
				type: Array,
				default: () => [],
			},
			NoGroupBy: '',
			showGroupBy: true,
			totalCount: 0,
		},
		data() {
			return {
				filter: {
					groupBy: '',
				},
				errors: {},
				error_message: '',
				url: '//' + window.location.hostname + '/',
			};
		},
		computed: {
			totalFields() {
				return Object.keys(this.filter).filter(key => this.filter[key] && key !== 'groupBy').length;
			},
		},
		methods: {
			clearField(key) {
				this.filter[key] = '';
				this.validateField(key);
				this.applyFilters();
			},
			applyFilters() {
				if (this.totalFields === 0) {
					console.warn('No active filter fields. Skipping applyFilters.');
					return;
				}
				this.$emit('apply-filters', this.filter);
			},
			validateField(key) {
				this.errors[key] = !this.filter[key];
			},
			emitGroupBy() {
				this.$emit('changePage', this.filter.groupBy);
			},
		},
	};
</script>
