<template>
	<div v-if="showBanner" id="cookieConsentBanner" class="cookies-popup bg-gray-3">
		<button @click="closeBanner" class="cookies-close-x">X</button>

		<div class="p-30">
			<p>
				This website uses cookies to ensure you get the best experience on our website.
				<a href="/terms" style="color: #aaa; text-decoration: underline">Learn more</a>
			</p>
		</div>
		<button @click="declineCookies" class="bookies-button-decline">Decline</button>
		<button @click="acceptCookies" class="cookies-button-accept">Allow cookies</button>
	</div>
</template>
<script>
	export default {
		name: 'CookieConsent',
		data() {
			return {
				showBanner: false,
				isAuthenticated: false,
				cookiesAcceptedAt: null,
				localStorageKey: 'cookieConsent',
			};
		},
		mounted() {
			this.isAuthenticated = this.$root.isAuthenticated || false;
			this.cookiesAcceptedAt = this.$root.cookiesAcceptedAt || null;

			this.checkCookieConsent();
		},
		watch: {
			cookiesAcceptedAt(newVal) {
				if (newVal) {
					this.showBanner = false;
					this.loadGoogleAnalytics();
				}
			},
		},
		methods: {
			checkCookieConsent() {
				if (this.isAuthenticated) {
					this.showBanner = !this.cookiesAcceptedAt;
				} else {
					const localConsent = localStorage.getItem(this.localStorageKey) === 'true';
					this.showBanner = !localConsent;
				}

				if (!this.showBanner) {
					this.loadGoogleAnalytics();
				}
			},
			acceptCookies() {
				if (this.isAuthenticated) {
					axios
						.post('/accept-cookies')
						.then(response => {
							this.cookiesAcceptedAt = response.data.cookiesAcceptedAt;
							this.$root.cookiesAcceptedAt = this.cookiesAcceptedAt;
						})
						.catch(error => {
							console.error('Error recording cookie acceptance:', error);
						});
				} else {
					localStorage.setItem(this.localStorageKey, 'true');
					this.showBanner = false;
					this.loadGoogleAnalytics();
				}
			},
			declineCookies() {
				if (this.isAuthenticated) {
					axios
						.post('/decline-cookies')
						.then(() => {
							this.cookiesAcceptedAt = null;
							this.$root.cookiesAcceptedAt = null;
						})
						.catch(error => {
							console.error('Error recording cookie decline:', error);
						});
				} else {
					localStorage.setItem(this.localStorageKey, 'false');
					this.showBanner = false;
					// Optional: Eventually, I could remove analytics cookies if already set
					// this.clearAnalyticsCookies();
				}
			},
			clearAnalyticsCookies() {
				// here I will add clear Google analytics.
			},
			closeBanner() {
				this.showBanner = false;
			},
			loadGoogleAnalytics() {
				if (!window.gaLoaded) {
					window.gaLoaded = true;
					window.dataLayer = window.dataLayer || [];
					function gtag() {
						dataLayer.push(arguments);
					}
					gtag('js', new Date());
					gtag('config', 'G-E3F225ZR7X');
				}
			},
		},
	};
</script>
