<template>
	<div class="p-0 m-0">
		<div class="row p-0">
			<div class="col text-center text-blue-5 p-15">
				<h1 class="t-32 text-blue-6 text-capitalize p-0 m-0" style="line-height: 30px">
					<a href="#" @click.prevent="resetFilter" class="text-blue-6">Developments ({{ totalResultsCount.toLocaleString() }}) </a>
				</h1>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<GeneralFilter :counts="counts" @filter-field="applyEventsFilter" :nItems="0" :nGreater="3" ref="devFilter" />
				<!-- <DevelopmentsFilter :intentCount="intentCount" :fleetCount="fleetCount" :statusCount="statusCount" @filter-events="applyEventsFilter" /> -->
			</div>
		</div>
		
		<div class="row p-0">
			<div class="col bg-light text-center text-blue-5 pl-25 pr-25">
				<DateFilters @apply-filters="applyFilters" :results="results" :totalCount="totalCount" filtersheading="Enter one item" :date-limitations="dateLimitations"></DateFilters>

				<div class="container d-block d-md-none text-center text-gray-5 t-22"><i class="fa-solid fa-left-right"></i></div>

				<AirTable :fields="tableFields" :data="results" :totalPages="totalPages" :totalCount="totalCount" :currentPage="currentPage" :sortFieldInput="sortField" :sortOrder="sortOrder" :enableCounts="false" :countRow="countData" @rowSelected="viewRecordDetails" @page-changed="fetchData(filters, $event)" @sort-changed="handleSortChange" ref="airTableRef" />
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import DateFilters from './DateFilters';
	import AirTable from './AirTable';
	// import ChangesFilter from './ChangesFilter';
	import DevelopmentsFilter from './DevelopmentsFilter';
	import GeneralFilter from './GeneralFilter';

	export default {
		components: {
			DateFilters,
			AirTable,
			DevelopmentsFilter,
			GeneralFilter,
		},
		props: {
			portal: {
				type: String,
				required: true,
			},
			tableFields: {
				type: Array,
				default: () => [],
			},
			counts: {
				type: Object,
				// default: () => [],
			},
			totalResultsCount: {
				type: Number,
				default: 0,
			},
		},
		data() {
			return {
				results: [],

				totalCount: 0,
				currentPage: 1,
				totalPages: 1,
				sortField: 'date',
				sortOrder: 'desc',
				countData: {},
				pageWindowSize: 8,
				filters: {},
				dateLimitations: {},
				isLoading: false,
				eventFilter: null, // To store active event filter (Intent/Action)
				firstLoad: true,
			};
		},
		mounted() {},
		created() {
			console.log(this.totalResultsCount);

			const minDate = moment()
				.subtract(365 * 2, 'days')
				.format('YYYY-MM-DD');
			const maxDate = moment().format('YYYY-MM-DD');

			this.dateLimitations = {
				minDate,
				maxDate,
			};

			this.fetchData(this.filters, 1);
		},
		methods: {
			handleSortChange({ sortField, sortOrder }) {
				this.sortField = sortField;
				this.sortOrder = sortOrder;
				this.fetchData(this.filters, this.currentPage);
			},

			paginatedPages() {
				let startPage = Math.max(1, this.currentPage - Math.floor(this.pageWindowSize / 2));
				startPage = Math.min(startPage, Math.max(1, this.totalPages - this.pageWindowSize + 1));
				return Array.from({ length: Math.min(this.pageWindowSize, this.totalPages) }, (v, i) => i + startPage);
			},

			applyFilters(newFilters) {
				if (!window.Airvita.isLoggedIn) return;

				this.filters = newFilters;
				this.fetchData(this.filters, 1);
			},

			applyEventsFilter(eventType) {
				this.eventFilter = eventType;

				this.fetchData(this.filters, 1);
			},
			resetFilter() {
				this.$refs.devFilter.resetFilter();
				this.applyEventsFilter(null);
			},
			fetchData(filters = {}, page = 1) {
				if (!window.Airvita.isLoggedIn) return;

				this.isLoading = true;

				// Prepare query parameters
				const queryParams = new URLSearchParams({
					...filters,
					page,
					sortField: this.sortField,
					sortOrder: this.sortOrder,
				});

				// Only add the event filter if it is either "Intent" or "Action"
				if (this.eventFilter) {
					queryParams.append('event', this.eventFilter);
				}

				console.log(queryParams.toString());

				axios
					.get(`/api/${this.portal}/get?${queryParams.toString()}`)
					.then(response => {
						this.results = response.data.results;
						this.totalPages = response.data.totalPages;
						this.totalCount = response.data.totalCount;
						this.currentPage = page;
						this.isLoading = false;

						if (this.firstLoad) {
							// this.intentCount = response.data.intentCount;
							// this.fleetCount = response.data.fleetCount;
							// this.statusCount = response.data.statusCount;
							this.firstLoad = false; // Update flag after the first load
						}
					})
					.catch(error => {
						console.error('Failed to fetch data:', error);
						this.results = [];
						this.totalCount = 0;
						this.totalPages = 0;
						this.currentPage = 1;
						this.isLoading = false;
					});
			},
			viewRecordDetails() {
				// Implement as needed
			},
		},
	};
</script>
