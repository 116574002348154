<template>
  <div>
    <div class="text-center t-18 mt-25 mb-15">
      <div>
        <h2 class="p-0 m-0 t-18 text-blue-5">AIRLINE</h2>
      </div>
      <div>
        {{ flight.airline_name }}
      </div>
    </div>

    <table class="table text-blue-5">
      <thead class="table-header text-uppercase text-center">
        <th>Aircraft</th>
        <th>Routes</th>
        <th>Airlines</th>
        <th>Flights</th>
        <th>Hours</th>
        <th>Cycles</th>
        <th>Ratio</th>
      </thead>
      <tbody>
        <tr
          v-for="row in airlinecounts"
          :key="row.index"
          class="bg-gray-1 text-center"
        >
          <td>{{ row.aircraft }}</td>
          <td>{{ row.routes }}</td>
          <td>{{ row.airline_name }}</td>
          <td>{{ row.flights }}</td>
          <td>{{ row.hours }}</td>
          <td>{{ row.cycles }}</td>
          <td>{{ row.ratio }}</td>
        </tr>
      </tbody>
    </table>

    <h3 class="text-center t-18">FLIGHTS</h3>

    <table class="table text-blue-5">
      <thead class="table-header text-uppercase text-center">
        <th>Aircraft</th>
        <th>Routes</th>
        <th>Airlines</th>
        <th>Flight</th>
        <th>Hours</th>
        <th>Cycles</th>
        <th>Ratio</th>
      </thead>
      <tbody>
        <tr
          v-for="row2 in airlinelist"
          :key="row2.index"
          class="bg-gray-1 text-center"
        >
          <td>{{ row2.ac_type_icao }}</td>
          <td>{{ row2.route }}</td>
          <td>{{ row2.airline_name }}</td>
          <td>{{ row2.flight_number }}</td>
          <td>{{ row2.hours }}</td>
          <td>{{ row2.cycles }}</td>
          <td>{{ row2.ratio }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ['flight'],
  data() {
    return {
      airlinecounts: [],
      airlinelist: [],
    };
  },
  mounted() {
    // this.fetchFlightDetails('airline');
    this.fetchData();
  },
  methods: {
    fetchData() {
      const self = this;
      const flightId = this.flight.id;

      // List of requests
      const requests = [
        axios.get(`/api/flight/${flightId}/airlinecounts`),
        axios.get(`/api/flight/${flightId}/airlinelist`),
        // axios.get(`/api/otherEndpoint/${flightId}`), // Example of another API call
        // Add more API calls as needed
      ];

      axios
        .all(requests)
        .then(
          axios.spread((airlinecounts, airlinelist) => {
            self.airlinecounts = [airlinecounts.data];
            // console.log(self.airline_counts);
            self.airlinelist = airlinelist.data;
            // Handle additional API responses accordingly
          })
        )
        .catch(error => {
          console.error('There was an error fetching the data: ', error);
        });
    },
  },
};
</script>
