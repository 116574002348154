 
import Vue from 'vue'
window.Vue = Vue


var modalmixin = { 
    data: function() {
        return {
            mylist: []
        }
    },
    methods: {

        closeModal: function( url) {
            if( this.form )
                this.resetForm(this.form);

            this.showModal = false;
            if ( url != "" ) {
                history.pushState( {}, null, '/' + url )
            }
        },  
 
    },
    mounted() {
        const self = this;
        document.addEventListener("keydown", (e) => {
            if ( self.showModal && e.keyCode == 27) {
                self.showModal = false;
            } 
        });  
    }
}


export default modalmixin