<template id="events">
	<div class="events-container">
		<ForecastsFilter :processedResults="processedResults" @filter-focus="applyFocusFilter" />

		<search-input placeholder="find forecast" v-model="filters.name.value" />

		<div style="overflow-x: auto">
			<v-table :data="filteredResults" :filters="filters" class="table table-striped-white bg-light border mt-15">
				<thead slot="head" class="text-center bg-blue-5 text-light">
					<v-th sortKey="forecast" class="clickable w-350px">Forecast</v-th>
					<v-th sortKey="source" class="clickable w-200px">Source</v-th>
					<v-th sortKey="focus" class="clickable w-120px">Focus</v-th>
					<v-th sortKey="website" class="clickable text-center w-120px">Website</v-th>
					<v-th sortKey="country.domicile" class="clickable text-center w-150px">Domicile</v-th>
					<!-- <v-th sortKey="access" class="text-center">Access</v-th>
				<v-th sortKey="frequency" class="text-center">Frequency</v-th> -->
				</thead>
				<tbody slot="body" slot-scope="{ displayData }">
					<tr v-for="row in displayData" :key="row.id" class="text-center">
						<td v-for="field in tableFields" :key="field.key">
							<span v-if="['url', 'domicile'].includes(field.type)" v-html="formatField(row, field)"></span>
							<span v-else-if="field.key == 'forecast'">
								<a :href="url + 'directories/forecasts/' + row.id" rel="noopener noreferrer">{{ row.forecast }}</a>
							</span>
							<span v-else>{{ formatField(row, field) }}</span>
						</td>
					</tr>
				</tbody>
			</v-table>
		</div>
	</div>
</template>

<script>
	import AirTable from './AirTable';
	import ForecastsFilter from './ForecastsFilter';

	export default {
		components: {
			AirTable,
			ForecastsFilter,
		},
		props: ['results'],
		data: function () {
			return {
				totalCount: 0,
				currentPage: 1,
				totalPages: 0,
				countData: {},

				sortField: 'start_date',
				sortOrder: 'asc',

				url: '//' + window.location.hostname + '/',
				filters: {
					name: {
						value: '',
						keys: ['forecast', 'focus', 'source', 'website', 'access', 'frequency', 'country.domicile'],
					},
				},
				selectedFocusFilter: null,
			};
		},
		created() {
			this.mylist = this.results.map(row => ({
				...row,
				country: row.country || { domicile: '' }, // Ensure country is always an object
			}));
		},
		methods: {
			handleSortChange({ sortField, sortOrder }) {
				this.sortField = sortField;
				this.sortOrder = sortOrder;
				this.fetchData(this.filters, this.currentPage);
			},
			viewRecordDetails() {},

			applyFocusFilter(focus) {
				this.selectedFocusFilter = focus;
			},
		},
		computed: {
			filteredResults() {
				if (!this.selectedFocusFilter) return this.processedResults;
				return this.processedResults.filter(item => item.focus === this.selectedFocusFilter);
			},

			processedResults() {
				return this.results.map(row => ({
					...row,
					country: row.country || { domicile: '' },
				}));
			},
			tableFields() {
				return [
					{ key: 'forecast', label: 'Forecast' },
					{ key: 'source', label: 'Source' },
					{ key: 'focus', label: 'Focus' },
					{ key: 'website', label: 'Website', type: 'url' },
					// { key: 'access', label: 'Access' },
					// { key: 'frequency', label: 'Frequency' },
					{ key: 'country.domicile', label: 'Domicile', type: 'domicile' },
				];
			},
		},
	};
</script>
