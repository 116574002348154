/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from 'vue';

window.Vue = Vue;

window._ = require('lodash');
window.Popper = require('popper.js').default;

// window.Popper = require('popper.js');

window.$ = window.jQuery = require('jquery');

import SmartTable from 'vuejs-smart-table';

Vue.use(SmartTable);

require('bootstrap');

// import Vue from 'vue'
// window.Vue = require('vue');
window.axios = require('axios');

// Vue.prototype.$http = window.axios;
Vue.$http = window.axios;
require('vue-resource');

Vue.use(require('vue-moment'));

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css';

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// Vue.http.interceptors.push((request, next) => {
//     request.headers.set('X-CSRF-TOKEN', Airvita.csrfToken);
//     next();
// });

import countrymixin from './countrymixin.js';
import sortingmixin from './sortingmixin.js';
import formmixin from './formmixin.js';
import modalmixin from './modalmixin.js';
import stringmixin from './stringmixin.js';
import datemixin from './datemixin.js';
import numberformatmixin from './numberformatmixin.js';
import formatfieldmixin from './formatfieldmixin.js';

Vue.mixin(countrymixin);
Vue.mixin(sortingmixin);
Vue.mixin(formmixin);
Vue.mixin(modalmixin);
Vue.mixin(stringmixin);
Vue.mixin(datemixin);
Vue.mixin(numberformatmixin);
Vue.mixin(formatfieldmixin);

import Subcount from './components/Subcount.vue';
Vue.component('Subcount', Subcount);

import FleetsRoutes from './components/FleetsRoutes.vue';
Vue.component('FleetsRoutes', FleetsRoutes);

import Flights from './components/Flights.vue';
Vue.component('Flights', Flights);

import AircraftFleet from './components/AircraftFleet.vue';
Vue.component('AircraftFleet', AircraftFleet);

import FlightsOnly from './components/FlightsOnly.vue';
Vue.component('FlightsOnly', FlightsOnly);

import Aircraft from './components/Aircraft.vue';
Vue.component('Aircraft', Aircraft);

import Airlines from './components/Airlines.vue';
Vue.component('Airlines', Airlines);

import AirspacesAirlines from './components/AirspacesAirlines.vue';
Vue.component('AirspacesAirlines', AirspacesAirlines);

import Builders from './components/Builders.vue';
Vue.component('Builders', Builders);

import BuildersProducts from './components/BuildersProducts.vue';
Vue.component('BuildersProducts', BuildersProducts);

import CheckoutForm from './components/CheckoutForm.vue';
Vue.component('CheckoutForm', CheckoutForm);

import Airports from './components/Airports.vue';
Vue.component('Airports', Airports);

import Countries from './components/Countries.vue';
Vue.component('Countries', Countries);

import Domiciles from './components/Domiciles.vue';
Vue.component('Domiciles', Domiciles);

import MyFilter from './components/MyFilter.vue';
Vue.component('MyFilter', MyFilter);

import Engines from './components/Engines.vue';
Vue.component('Engines', Engines);

import EnginesTurbo from './components/EnginesTurbo.vue';
Vue.component('EnginesTurbo', EnginesTurbo);

import EnginesApplications from './components/EnginesApplications.vue';
Vue.component('EnginesApplications', EnginesApplications);

import Glossary from './components/Glossary.vue';
Vue.component('Glossary', Glossary);

// import Flash from './components/Flash.vue';
// Vue.component('Flash', Flash);

import CompareAll from './components/CompareAll.vue';
Vue.component('CompareAll', CompareAll);

import CompareSelect from './components/CompareSelect.vue';
Vue.component('CompareSelect', CompareSelect);

import CompareAirlines from './components/CompareAirlines.vue';
Vue.component('CompareAirlines', CompareAirlines);

import Payloads from './components/Payloads.vue';
Vue.component('Payloads', Payloads);

import Publications from './components/Publications.vue';
Vue.component('Publications', Publications);

import Measures from './components/Measures.vue';
Vue.component('Measures', Measures);

// import ConverterForm from './components/ConverterForm.vue';
// Vue.component('ConverterForm', ConverterForm);

import ConverterForm2 from './components/ConverterForm2.vue';
Vue.component('ConverterForm2', ConverterForm2);

import Events from './components/Events.vue';
Vue.component('Events', Events);

import Organizations from './components/Organizations.vue';
Vue.component('Organizations', Organizations);

import Guide from './components/Guide.vue';
Vue.component('Guide', Guide);

import Utilities from './components/Utilities.vue';
Vue.component('Utilities', Utilities);

import UtilitiesLinks from './components/UtilitiesLinks.vue';
Vue.component('UtilitiesLinks', UtilitiesLinks);

import UtilitiesPopUp from './components/UtilitiesPopUp.vue';
Vue.component('UtilitiesPopUp', UtilitiesPopUp);

import UtilitiesMenu from './components/UtilitiesMenu.vue';
Vue.component('UtilitiesMenu', UtilitiesMenu);

import Operators from './components/Operators.vue';
Vue.component('Operators', Operators);

import Capacities from './components/Capacities.vue';
Vue.component('Capacities', Capacities);

import CapacitiesList from './components/CapacitiesList.vue';
Vue.component('CapacitiesList', CapacitiesList);

// import Similar from './components/Similar.vue';
//  Vue.component('Similar', Similar);

import AirlineFleets from './components/AirlineFleets.vue';
Vue.component('AirlineFleets', AirlineFleets);

import AirlineFleetShow from './components/AirlineFleetShow.vue';
Vue.component('AirlineFleetShow', AirlineFleetShow);

import SearchProfile from './components/SearchProfile.vue';
Vue.component('SearchProfile', SearchProfile);

import Trends from './components/Trends.vue';
Vue.component('Trends', Trends);

import Subscribe from './components/Subscribe.vue';
Vue.component('Subscribe', Subscribe);

import Notes from './components/Notes.vue';
Vue.component('Notes', Notes);

import Bulletin from './components/Bulletin.vue';
Vue.component('Bulletin', Bulletin);

import SearchGlobal from './components/SearchGlobal.vue';
Vue.component('SearchGlobal', SearchGlobal);

import RegistrationLoginBox from './components/RegistrationLoginBox.vue';
Vue.component('RegistrationLoginBox', RegistrationLoginBox);

// import BulletinSubscribeForm from './components/BulletinSubscribeForm.vue';
// Vue.component('BulletinSubscribeForm', BulletinSubscribeForm);

import NavUserDropdown from './components/NavUserDropdown.vue';
Vue.component('NavUserDropdown', NavUserDropdown);

import AuthPasswordChange from './components/AuthPasswordChange.vue';
Vue.component('AuthPasswordChange', AuthPasswordChange);

import SubscribedCancellation from './components/SubscribedCancellation.vue';
Vue.component('SubscribedCancellation', SubscribedCancellation);

import Charters from './components/Charters.vue';
Vue.component('Charters', Charters);

import SearchGlobalSearchBox from './components/SearchGlobalSearchBox.vue';
Vue.component('SearchGlobalSearchBox', SearchGlobalSearchBox);

import Messenger from './components/Messenger.vue';
Vue.component('Messenger', Messenger);

import MessengerPopup from './components/MessengerPopup.vue';
Vue.component('MessengerPopup', MessengerPopup);

import BulletinButton from './components/BulletinButton.vue';
Vue.component('BulletinButton', BulletinButton);

import BulletinProfile from './components/BulletinProfile.vue';
Vue.component('BulletinProfile', BulletinProfile);

import UpdatesArchive from './components/UpdatesArchive.vue';
Vue.component('UpdatesArchive', UpdatesArchive);

import Frontiers from './components/Frontiers.vue';
Vue.component('Frontiers', Frontiers);

import FleetAlignments from './components/FleetAlignments.vue';
Vue.component('FleetAlignments', FleetAlignments);

import FleetModels from './components/FleetModels.vue';
Vue.component('FleetModels', FleetModels);

import FleetTypes from './components/FleetTypes.vue';
Vue.component('FleetTypes', FleetTypes);

import EnginesModels from './components/EnginesModels.vue';
Vue.component('EnginesModels', EnginesModels);

import EnginesPower from './components/EnginesPower.vue';
Vue.component('EnginesPower', EnginesPower);

import GlossaryLink from './components/GlossaryLink.vue';
Vue.component('GlossaryLink', GlossaryLink);

import MeasuresLink from './components/MeasuresLink.vue';
Vue.component('MeasuresLink', MeasuresLink);

import AirlinesCarriers from './components/AirlinesCarriers.vue';
Vue.component('AirlinesCarriers', AirlinesCarriers);

import UtilitiesWelcome from './components/UtilitiesWelcome.vue';
Vue.component('UtilitiesWelcome', UtilitiesWelcome);

import Forecasts from './components/Forecasts.vue';
Vue.component('Forecasts', Forecasts);

import Placements from './components/Placements.vue';
Vue.component('Placements', Placements);

import Changes from './components/Changes.vue';
Vue.component('Changes', Changes);

import Developments from './components/Developments.vue';
Vue.component('Developments', Developments);

import AirlinesDevelopmentsShow from './components/AirlinesDevelopmentsShow';
Vue.component('AirlinesDevelopmentsShow', AirlinesDevelopmentsShow);

import Cargo from './components/Cargo.vue';
Vue.component('Cargo', Cargo);

import Fuel from './components/Fuel.vue';
Vue.component('Fuel', Fuel);

import Currency from './components/Currency.vue';
Vue.component('Currency', Currency);

import PopUp from './components/PopUp.vue';
Vue.component('PopUp', PopUp);

import CookieConsent from './components/CookieConsent.vue';
Vue.component('CookieConsent', CookieConsent);

import AirlinesFleetUnits from './components/AirlinesFleetUnits.vue';
Vue.component('AirlinesFleetUnits', AirlinesFleetUnits);

import AircraftCodes from './components/AircraftCodes';
Vue.component('AircraftCodes', AircraftCodes);

import Conversions from './components/Conversions';
Vue.component('Conversions', Conversions);
// export default {
//     countries: {
//         "UNITED STATES": "us",
//         "FRANCE": "fr",
//         "UKRAINE": "ua",
//         "UNITED KINGDOM": "gb",
//         "CANADA": "ca",
//         "CHINA": "cn",
//         // "BRASIL": "br",
//         "BRAZIL": "br",
//         // "NETHERLANDS": "nl",
//         "RUSSIA": "ru",
//         "CZECH": "ru",
//         "JAPAN": "jp",
//         "SWEDEN": "se",
//         "NORTHERN IRELAND": "gb",
//         "INDONESIA": "id"
//     }
// }

const app = new Vue({
	el: '#app',
	data: {
		showModal: false,
		isAuthenticated: window.Airvita.isAuthenticated === 'true', // same as isloggedIn
		isLoggedIn: window.Airvita.isAuthenticated === 'true',
		cookiesAcceptedAt: window.Airvita.cookiesAcceptedAt,
	},
});

window.events = new Vue();
window.flash = function (message) {
	window.events.$emit('flash', message);
}; // flash('my new flash message')

window.promptAction = function () {
	if (!confirm('Are you sure?')) {
		event.preventDefault();
	}
	return false;
};

jQuery('document').ready(function () {
	window.filterFields = function (e) {
		var rex = new RegExp(e.value, 'i');
		jQuery('.searchable tr').hide();
		jQuery('.searchable tr')
			.filter(function () {
				return rex.test($(this).text());
			})
			.show();
	};
});
