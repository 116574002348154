 
import Vue from 'vue'
window.Vue = Vue


var stringmixin = { 
    data: function() {
        return {
            mylist: []
        }
    },
    methods: {

        trimStringBr: function(str) {
            
            return str.replace(/<[^>]*>?/gm, '');
        },  
        toTitleCase: function(str) {
                
            var newCapitalCaseWord = str.replace(
            /\w\S*/g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
            
        return newCapitalCaseWord;
        
        } 
 
    },
    mounted() {
         
    }
}


export default stringmixin