<template id="nav-user-dopdown">
	<div class="dropdown">
		<button class="btn text-light" type="button" id="userDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			{{ username() }}
		</button>

		<div class="dropdown-menu dropdown-menu-right text-center" aria-labelledby="userDropdown">
			<a class="dropdown-item text-gray-light" href="#">
				<i class="fas fa-sign-out-alt"></i>
				Login
			</a>
			<div class="dropdown-divider"></div>
			<!-- <a class="dropdown-item user-menu-dropdown" href="/">Welcome</a> -->
			<a class="dropdown-item user-menu-dropdown" href="/home">Home</a>
			<a class="dropdown-item user-menu-dropdown" href="/account">Account</a>
			<a class="dropdown-item user-menu-dropdown" href="#" @click.prevent="openPopup('query', 1)">Contact</a>
			<div class="dropdown-divider"></div>

			<a class="dropdown-item user-menu-dropdown" href="#" @click.prevent="openPopup('glossary', 1)" title="Open Glossary">Glossary</a>
			<a class="dropdown-item user-menu-dropdown" href="#" @click.prevent="openPopup('measures', 0)" title="Open Glossary">Measures</a>
			<a class="dropdown-item user-menu-dropdown" href="#" @click.prevent="openPopup('notes', 1)" title="Open Glossary">Notes</a>

			<div class="dropdown-divider"></div>
			<a class="dropdown-item user-menu-dropdown" href="/about">About</a>
			<a class="dropdown-item user-menu-dropdown" href="/terms" title="Terms of Service">Terms</a>
			<a class="dropdown-item user-menu-dropdown" href="/guide" title="User Guide">Guide</a>

			<div class="dropdown-divider"></div>

			<a class="dropdown-item user-menu-dropdown" href="/logout">
				<i class="fas fa-sign-out-alt"></i>
				Log Out
			</a>
		</div>

		<modal v-if="showModal" @close="closeModal" :hideheader="hideheader">
			<template v-slot:header>
				<slot name="header">
					{{ modalTitle }}
				</slot>
			</template>

			<template v-slot:body>
				<slot name="body">
					<div v-if="option == 'notes'">
						<notes>
							<span slot="header">Notes</span>
							<span slot="text">Notes</span>
						</notes>
					</div>
					<div v-if="option == 'query'">
						<messenger></messenger>
					</div>
					<div v-if="option == 'bulletin'">
						<bulletin-profile :user="user" :subscription="subscription" />
					</div>
					<div v-if="option == 'glossary'">
						<glossary></glossary>
					</div>
					<div v-if="option == 'measures'">
						<measures></measures>
					</div>
				</slot>
			</template>
			<template v-slot:footer>
				<slot name="footer"></slot>
			</template>
		</modal>

		<!-- <div class="bulletinModal">
			<transition name="modal">
				<div class="modal-mask" @click="closeModal('bulletin')" v-show="showModal" style="z-index: 9999999999">
					<div class="modal-container text-left p-0 bg-gray-light" @click.stop>
						<div class="modal-body text-center bg-gray-light">
							<div v-if="option == 'notes'">
								<notes></notes>
							</div>
							<div v-if="option == 'query'">
								<messenger></messenger>
							</div>
							<div v-if="option == 'bulletin'">
								<bulletin-profile :user="user" :subscription="subscription" />
							</div>
							<div v-if="option == 'glossary'">
								<glossary></glossary>
							</div>
						</div>
					</div>
				</div>
			</transition>
		</div> -->
	</div>
</template>

<script>
	export default {
		props: ['user', 'subscription'],
		data: function () {
			return {
				errors: [],

				form: {
					full_name: '',
					last_name: '',
					email: '',
					company: '',
				},
				option: '',

				showModal: false,
				showModalGlossary: false,
				modalTitle: '',
				hideheader: '',

				url: '//' + window.location.hostname + '/',
			};
		},
		created() {},

		watch: {},
		computed: {},
		methods: {
			openPopup(option, hideHeader) {
				this.option = option;
				this.hideheader = hideHeader;
				this.showModal = !this.showModal;
				this.modalTitle = this.option;
			},

			logOut: function () {
				axios
					.post('/logout')
					.then(function (response) {
						window.location.href = '/welcome';
						return;
					})
					.catch(function (error, response) {
						self.errors.push(error.response.data.message);
					});
			},
			username() {
				var first = this.user.first_name;

				return this.user && this.user.first_name ? first.replace(/ .*/, '') : 'USER';
			},
		},

		mounted() {},
		beforeDestroy() {},
	};
</script>
