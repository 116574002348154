<template id="payloads">
    <div class="container-fluid"> 

        <div class="card" >
                <div class="card-header "> 
                    <input type="text" placeholder="Find Aircraft" v-model="searchQuery" class="form-control ">
                </div>
        </div>

        <table class="table table-striped-white border">
            <thead class="bg-white">
            <tr> 
            <th scope="col" @click="sorting('model')" class="clickable" >Aircraft</th>
            <th scope="col" @click="sorting('builder')" class="clickable" >Builder</th>
            <th scope="col" @click="sorting('country')" class="clickable" >Country</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="payload in filterBy(filteredList, searchQuery)" :key="payload.id">
            <td v-if=" payload.model "><a :href="url+'payloads/' + payload.id +''">{{ payload.model }}</a></td><td v-else></td>
            <td v-if=" payload.builder ">{{ payload.builder.builder }}</a></td><td v-else></td>
            <td  v-if=" payload.build_model_assembly_country "><img v-bind:src="url+'flags/blank.gif'" :class="'flag flag-'+getCountryFlagCode( payload.build_model_assembly_country)" :alt="payload.build_model_assembly_country"  :title="payload.build_model_assembly_country" /> {{ payload.build_model_assembly_country }} </td><td v-else></td>
 
            </tr>
        </tbody>
        
        </table>
    </div> 
</template>
 
 
<script>  
  import Vue2Filters from 'vue2-filters'

    export default{
        mixins: [ Vue2Filters.mixin],
        props: [ 'payloads' ],
        data: function () {
            return {  
                searchQuery: ''
                
                ,currentSort:'model'
                ,currentSortDir:'asc'

                , filteredList: this.payloads
                , url: '//'+window.location.hostname+'/'
            };
        },
        created () {  
            this.mylist = this.payloads; 
        },
        methods: { 
        
            morethan () {
                return 
            } , 
            
            matches(obj) {
                const term = this.searchQuery.toLowerCase(); 
                return filteredList.toLowerCase().includes(term);
            } 
            
        }
        , computed: {
             
            filteredpayload() {
                if ( this.searchQuery =="" ) {
                    // this.$emit('filterChange', this.filteredList)
                    return this.filteredList;
                }
                return this.filteredList.filter(function(builder) {
                    return payload.includes(function(item) {
                        return item.payload.includes(this.searchQuery);
                    });
                        // val => String(val).toLowerCase().includes( this.searchQuery ) );  ///filters the word object
                });
            }
            
            
        } 
    }
    
</script>
 