<template id="engines">
  <div class="engineslist">
    <div class="card">
      <div class="card-header">
        <input
          class="form-control"
          v-model="filters.name.value"
          placeholder="            Search"
        />
      </div>
    </div>

    <v-table
      :data="engines"
      :filters="filters"
      class="table table-striped-white border"
    >
      <thead slot="head">
        <v-th sortKey="builder.country.domicile" class="clickable text-center"
          >Builder</v-th
        >
        <v-th sortKey="model" class="clickable text-center">Engine</v-th>
        <v-th sortKey="kind" class="clickable text-center">Kind</v-th>
        <v-th sortKey="thrust" class="clickable text-center">Thrust</v-th>
        <v-th sortKey="applications" class="clickable text-center"
          >Applications</v-th
        >
      </thead>
      <tbody slot="body" slot-scope="{ displayData }" class="text-center">
        <tr v-for="row in displayData" :key="row.id">
          <td>
            <a :href="url + 'builders/' + row.builder.id">{{
              row.builder.builder
            }}</a>
          </td>

          <td>
            <a :href="'/engines/' + row.id">{{ row.model }}</a>
          </td>
          <td>{{ row.kind }}</td>
          <td>{{ row.thrust }}</td>
          <td>
            {{ row.applications }}
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters';

export default {
  mixins: [Vue2Filters.mixin],
  props: ['builders', 'engines', 'group'],
  data: function () {
    return {
      // buildersList: this.builders
      // thelist: this.builders,
      searchQuery: '',
      filteredList: this.builders,
      url: '//' + window.location.hostname + '/',
      filters: {
        name: {
          value: '',
          keys: ['builder.builder', 'model', 'group', 'thrust', 'applications'],
        },
      },
    };
  },
  created() {},
  methods: {
    morethan() {
      return;
    },

    matches(obj) {
      const term = this.searchQuery.toLowerCase();
      return filteredList.toLowerCase().includes(term);
    },
  },
  computed: {
    filteredEngines() {
      if (this.searchQuery == '') {
        // this.$emit('filterChange', this.filteredList)
        return this.filteredList;
      }
      return this.filteredList.filter(function (builder) {
        return builder.engines_list.includes(function (item) {
          return item.model.includes(this.searchQuery);
        });
        // val => String(val).toLowerCase().includes( this.searchQuery ) );  ///filters the word object
      });
    },
  },
};
</script>
