<template id="airlines">
	<div class="container-fluid">
		<div class="sticky">
			<search-input placeholder="Find Airline" v-model="filters.name.value" />

			<subcount>
				<slot></slot>
			</subcount>

			<div style="overflow-x: auto">
				<v-table :data="airlines" :filters="filters" class="table bg-light border" style="table-layout: auto">
					<thead slot="head" class="text-center no-wrap">
						<v-th sortKey="airline" class="clickable text-blue-7 w-200px">Airline</v-th>
						<v-th sortKey="domicile" class="clickable text-center text-blue-7 w-225px">Domicile</v-th>
						<v-th sortKey="codes" class="clickable text-blue-7 w-100px">Code</v-th>
						<v-th sortKey="airlinesfleet_sum_units" class="clickable text-blue-7 w-15">Fleet</v-th>
						<v-th sortKey="status" class="clickable text-blue-7 w-15">Status</v-th>
					</thead>
					<tbody slot="body" slot-scope="{ displayData }">
						<tr v-for="row in displayData" :key="row.id" class="text-center">
							<td>
								<a :href="url + 'airlines/' + row.id + ''">{{ row.airline }}</a>
							</td>
							<td v-if="row.country_id != '' && row.domicile" class="text-center">
								<img v-bind:src="url + 'flags/blank.gif'" :class="'flag flag-' + getCountryFlagCode(row.domicile)" :alt="row.domicile" :title="row.domicile" />
								{{ row.domicile }}
							</td>
							<td v-else class="col"></td>
							<td>{{ row.codes }}</td>
							<td v-if="row.airlinesfleet_sum_units">
								{{ row.airlinesfleet_sum_units }}
							</td>
							<td v-else>-</td>
							<td>{{ row.status ?? '' }}</td>
						</tr>
					</tbody>
				</v-table>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue2Filters from 'vue2-filters';
	//    import SmartTable from 'vuejs-smart-table'

	// Vue.use(SmartTable)

	export default {
		mixins: [Vue2Filters.mixin, './numberformatmixin'],
		props: ['airlines', 'domicile'],
		data: function () {
			return {
				section: 'airlines',
				currentSort: 'airline',
				currentSortDir: 'asc',
				searchQuery: '',
				totalResults: '',
				filteredList: '',
				url: '//' + window.location.hostname + '/',

				filters: {
					name: {
						value: '',
						keys: ['airline', 'codes', 'identity', 'domicile', 'code', 'license'],
					},
				},

				sortKey: 'airlines',
				reverse: false,
				columns: ['airlines', 'domicile', 'codes', 'fleet', 'kind', 'status'],

				licenseCounts: {
					flying: 0,
					dormant: 0,
				},
			};
		},
		created() {
			this.filteredList = this.airlines;

			if (this.domicile) {
				this.filters.name.value = this.domicile.domicile;
			}

			this.calculateLicenseCounts();
		},
		mounted() {
			this.filteredList = this.airlines;
			this.totalResults = this.airlines.length;
		},
		methods: {
			calculateLicenseCounts() {
				const flyingAirlines = this.airlines.filter(airline => airline.status === 'Flying');
				const dormantAirlines = this.airlines.filter(airline => airline.status === 'Dormant');

				this.licenseCounts.flying = flyingAirlines.length;
				this.licenseCounts.dormant = dormantAirlines.length;
			},
		},
		computed: {},
		watch: {
			airlines: {
				handler() {
					this.calculateLicenseCounts();
				},
				deep: true,
			},
		},
	};
</script>
