<template id="guide">
    <div class="container-fluid"> 

        <div class="container  ">
            <div class="row">
                <div class="col-12 col-sm-12  col-md-12  col-lg-12 order-sm-12  order-md-12 order-lg-12 p-4  card ">

                    <div class="card mb-2">
                        <div class="card-body">
                            AirLibrary tracks active commercial entities and provides information from directly from producers, operators, governors, and related associations and organizations.
                        </div>
                    </div>

                    <h4>Aircraft</h4>
                    <p>Aircraft changes daily. Counts monthly. Values quarterly.</p>

                    <h4>Airlines</h4>
                    <p>Fleets changes daily. Counts monthly. Status daily.</p>

                    <h4>Airports</h4>
                    <p>Profiles quarterly.</p>

                    <h4>Builders</h4>
                    <p>Profiles quarterly. Activities daily.</p>

                    <h4>Countries</h4>
                    <p>Profiles quarterly.</p>

                    <h4>Engines</h4>
                    <p>Counts monthly. Fleets changes daily. Values quarterly (tba).</p>

                    <h4>Events</h4>
                    <p>Listings ad hoc.</p>

                    <h4>Lessors</h4>
                    <p>Profiles quarterly. Activities daily.</p>

                    <h4>MROs</h4>
                    <p>Profiles quarterly. Activities daily.</p>

                    <h4>Payloads</h4>
                    <p>As adjusted by Builder, STC holder or Government.</p>

                    <h4>Publications</h4>
                    <p>Listings ad hoc.</p>

                    <h4>Suppliers</h4>
                    <p>Listings ad hoc.</p>
 
                    <h4>Measures</h4>
                    <p>Static</p>

                    <h4>Glossary</h4>
                    <p>Listings ad hoc.</p>

                    <h4>Updates</h4>
                    <p>Issue daily, after entity record update. Display for 90 days.</p>

                    <h4>Guide</h4>
                    <p>Current use information</p>
   
                </div> 
            </div> 
        </div> 
    </div> 

</template>
 
 
<script> 

  import Vue2Filters from 'vue2-filters'

    export default{
         data: function () {
            return {  
             
            };
        },
        created () {   
        },
        methods: { 
        

        }
        , computed: {
            
        } 
    }
    
</script>
 