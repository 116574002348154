<template>
	<div class="bg-light p-10">
		<div v-if="page == 'flights'">
			<FlightsOnly :date-limitations="dateLimitations" page="flights"></FlightsOnly>
		</div>
		<div v-else>
			<FleetsFlights :date-limitations="dateLimitations" page="fleets"></FleetsFlights>
		</div>
	</div>
</template>

<script>
	import FleetsFlights from './FleetsFlights';
	import FlightsOnly from './FlightsOnly';
	import moment from 'moment';

	export default {
		components: {
			FleetsFlights,
			FlightsOnly,
		},
		props: ['plan', 'accesslimit', 'page'],
		data() {
			return {
				dateLimitations: {
					minDate: '',
					maxDate: '',
				},
			};
		},
		async created() {
			const plan = this.plan;

			const now = moment();
			let minDate = moment();
			let maxDate = moment();

			minDate = this.accesslimit;

			this.dateLimitations = {
				minDate: minDate,
				maxDate: maxDate.format('YYYY-MM-DD'),
			};
		},
	};
</script>
