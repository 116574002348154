<template id="subcount">
  <div class="text-center p-10">
    <!-- <div class="bg-gray-3 mt-20 mb-20 p-10 text-center text-gold-7"> -->
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: [],
  data: function () {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>
