<template id="messenger-popup">
    <div class="p-1 text-center" >
 
        <a class=" rounded t-22" href="#" @click.prevent="openPopup('query')">Messenger</a> 
      
        <div class="bulletinModal">  
            <transition name="modal" > 
                <div class="modal-mask" @click="closeModal('')" v-show="showModal" >
                    <div class="modal-container text-left p-0 bg-gray-light"   @click.stop>
  
                        <div class="modal-body text-center " style="   z-index: 999999;">
                         
                            <messenger></messenger>
                                    
                        </div>
                    </div>
                </div>
            </transition>  
        </div>
        


    </div> 
  
</template>
 
<script> 
 
export default{
 
  props: [ 'user' , 'subscription'  ],
    data: function () {
        return { 
            errors: []
              
            , form: {
                full_name: '',
                email: '',
                company: ''
            }
            ,option : ''
 
            , showModal: false
            , modalTitle: ''
            
            , url: '//'+window.location.hostname+'/'
              
        };
    }, 
    created () {   
        
    },
    
    watch: {
         
       
    }, 
    computed: {
 
           
    }, 
    methods: {  

        openPopup( option ) {
            this.option = option;
            this.showModal = !this.showModal;
            this.modalTitle = this.option;
        },

        // logOut: function() {
 
        //     axios.post( "/logout")
        //     .then(function(response){
        //             window.location.href =  "/welcome";
        //             return;
        //     })
        //     .catch( function(error, response){  
        //         self.errors.push(error.response.data.message);
        //     }); 
        // },
 
   

    },

    mounted() {

    },
    beforeDestroy() {
         
    },
}
</script>